import { useState,useEffect } from 'react';
import {ConfirmDelete, NoDataFound} from '../ui'
import ListSalesmanItem from './ListSalesmanItem';
import { useDelete } from '../../hooks';
import { useNavigate, useParams } from 'react-router-dom';


const ListSalesmans = ({data ,setData,currentCompanyId,currentBranchId,currentLangId}) => {
    const navigate = useNavigate();
    const params = useParams();
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const [itemSelectedId,selectedItemId] = useState();

    const {data:resultDelete,deleteItem} = useDelete()

    const onChangeSearch = (newValue) => {
        setData(newValue);
    }

    const editHandeler = (id) =>{
        navigate(`/${params.tenant}/salesmans/edit/${id}`);
    } 

    const deleteHandeler = (id) =>{
        selectedItemId(id);
        setShowConfirmDelete(true);
    }
    
    const confirmDeleteHandler = () => {
        if(itemSelectedId){
            deleteItem(`${process.env.REACT_APP_Auth_API_BASE_URL}/salesmans/delete/${itemSelectedId}`);
            const filter = data.filter(el => el._id !== itemSelectedId);
            setData(filter)
        }
    }

    useEffect(() =>{
        setShowConfirmDelete(false);
    },[resultDelete]);

    return (
        <>
            <ConfirmDelete
                msg={`هل انت متاكد من حذف المندوب`}
                onShow={showConfirmDelete}
                setOnShow={setShowConfirmDelete}
                onDeleteHandler={confirmDeleteHandler}
            />
            <div className='text-slate-500 bg-slate-50 rounded-lg text-xs p-3'>
                <div className='flex items-center'>
                    <div  className='flex-1 font-bold text-slate-400'>قائمة المناديب</div>
                </div>
                { data?.length > 0 ? <div className='pt-3'>{
                    data.map((item) =>(<ListSalesmanItem
                        key={item._id}
                        item={item}
                        currentLangId = {currentLangId}
                        currentCompanyId = {currentCompanyId}
                        currentBranchId = {currentBranchId}
                        editHandeler = {editHandeler}
                        deleteHandeler = {deleteHandeler}
                        />))
                }</div> : <NoDataFound msg={`لا يوجد مناديب`} /> }
            </div>
        </>
    )
}

export default ListSalesmans
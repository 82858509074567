import React from 'react'
import InvFormListItem from '../../invoice/InvFormListItem'

const InvFormBody = ({
    initalProductItem,
    reloadProductList,
    showModalProductHandle,
    productList,
    setProductList,
    formErrors
}) => {
    return (
        <>
        <hr />
        <div id='inv-body' className='  h-96 overflow-y-auto' >
                    { initalProductItem.map((e,index)=>{
                        return <InvFormListItem
                                    key = {index}
                                    index = {index}
                                    reloadProductList = {reloadProductList}
                                    showModalProductHandle = {showModalProductHandle}
                                    productList = {productList}
                                    setProductList = {setProductList}
                                    formErrors = {index ===0 && formErrors}
                                    />
                        })
                    }
        </div>
        <hr />
        </>
    )
}

export default InvFormBody
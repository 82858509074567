import React, { useEffect, useState } from 'react'
import { MwButton, MwInputText } from '../../ui'
import FormLangBar from '../../FormLangBar';
import {langObjType} from '../../../utils/global'
import {AiOutlineSave,AiOutlineEdit} from 'react-icons/ai'
import {RiDeleteBin5Line} from 'react-icons/ri'

const ResponsiblesForm = ({
    currentLangList,
    currentLangId,
    errors,
    responsibles,
    setResponsibles
}) => {
    
    const [formLang,setFormLang] = useState(currentLangId);
    const [responsible,setResponsible] = useState({
        name:langObjType(),
        mobileNo: '',
        email:''
    });
    const [responsibleEditable,setResponsibleEditable] = useState({
        name:langObjType(),
        mobileNo: '',
        email:''
    });
    const [editableMode,setEditableMode] = useState({state:false,index:0});

    const AddToList = () => {
        if(!responsible.name?.[currentLangId]) return
        setResponsibles([...responsibles,responsible]);
        setResponsible({
            name:langObjType(),
            mobileNo: '',
            email:''
        });
        // console.log(responsibles);
    }

    const editItem = (index,item) => {
        setEditableMode({state:true,index:index});
        setResponsibleEditable(item)
        
    }
    const updateItem = (index) => {
        responsibles[index] = responsibleEditable;
        setResponsibles([...responsibles]);
        setEditableMode({state:false,index:index});
    } 
    const deleteItem = (index) => {
        const filter = responsibles.filter((el,i) => i !== index)
        setResponsibles(filter)
    }

    const responsiblesList = responsibles && responsibles?.map((item,index) => (
        <div key={index} className='flex justify-between items-center gap-3 border-b py-1 my-2'>
            <div className='flex-1'> 
            { editableMode.state && editableMode.index === index ? 
            <MwInputText 
                value={responsibleEditable.name?.[currentLangId]} 
                onChange={(e) => setResponsibleEditable({...responsibleEditable, name:{[currentLangId]:e.target.value} })} /> 
                : item.name?.[currentLangId]  }
            </div>
            <div className='flex-1 text-center'>{ editableMode.state && editableMode.index === index ? 
            <MwInputText 
                value={responsibleEditable.mobileNo} 
                onChange={(e) => setResponsibleEditable({...responsibleEditable, mobileNo:e.target.value })} /> 
                : item.mobileNo }</div>
            <div className='flex-1'>
            { editableMode.state && editableMode.index === index ? 
            <MwInputText 
                value={responsibleEditable.email} 
                onChange={(e) => setResponsibleEditable({...responsibleEditable, email:e.target.value })} /> 
                : item.email }</div>
            <div className='flex-1 flex gap-2 cursor-default'>
                { editableMode.state && editableMode.index === index ? <MwButton  actionType='button'  type='secondary' size='sm'  onClick={()=>updateItem(index)}><AiOutlineSave size={18}/></MwButton> : <MwButton  actionType='button'   type='secondary' size='sm'   onClick={()=>editItem(index,item)}><AiOutlineEdit size={18}/></MwButton> }
                <MwButton actionType='button' type='secondary' size='sm' onClick={()=>deleteItem(index)}><RiDeleteBin5Line size={18}/></MwButton>
            </div>
        </div>
    ));
    useEffect(() => {
        console.log('Responsibles changed');
        console.log(responsible);
    },[responsible]);
    return (
        <>
            <div>
                <div className='py-2'>
                    <FormLangBar currentLangList = {currentLangList} formLang = {formLang} setFormLang = {setFormLang} />
                    { currentLangList.map((lang) => (
                    <div key={lang.langCode} className=''>
                        <div className={` ${ formLang === lang?.langCode ? 'block' : 'hidden' } `}>
                            <MwInputText 
                                label={`اسم المسئول (${lang?.langName})`}
                                id={`nameResponsible${lang?.langCode}`} 
                                value={responsible.name?.[lang?.langCode]}
                                invalid={!!errors[`nameResponsible.${lang?.langCode}`] }
                                invalidMsg = {errors[`nameResponsible.${lang?.langCode}`]}
                                onChange={ (e) => { 
                                    setResponsible( { ...responsible , name:{[lang?.langCode]:e.target.value} })
                                }
                                }
                                />
                        </div>
                    </div>
                    )) }
                </div>
                <div className='py-2'>
                    <MwInputText 
                        label='رقم جوال المسئول'
                        id='mobileNo' 
                        inputType='Number'
                        value={responsible.mobileNo} 
                        invalid={!!errors.mobileNo}
                        invalidMsg={errors.mobileNo}
                        onChange={(e)=>{
                            setResponsible( { ...responsible , mobileNo:e.target.value })

                        }} />
                </div>
                <div className='py-2'>
                    <MwInputText 
                        label='البريد الالكتروني'
                        id='emailresponse' 
                        inputType='email'
                        value={responsible.email} 
                        onChange={(e)=>{
                            setResponsible( { ...responsible , email:e.target.value })
                        }} />
                </div>
                <div className='mt-2'>
                    <MwButton onClick={AddToList} actionType={`button`} type='saveBtn'  size='sm'>حفظ المسئول</MwButton>
                </div>
                { 
                    responsibles.length > 0 && 
                        <div className='p-3 bg-slate-100 mt-5 rounded-lg'>
                            <div id='headerResponse' className='flex justify-between items-center border-b-2 my-2 py-1' >
                                <div className='flex-1'>اسم المسئول</div>
                                <div  className='flex-1 text-center'>رقم الجوال</div>
                                <div className='flex-1'>البريد الالكتروني</div>
                                <div className='flex-1'> </div>
                            </div>
                            {responsiblesList}
                        </div>
                }
            </div>
        </>
    )
}

export default ResponsiblesForm
import  { useEffect, useState } from 'react'
import usePost from './usePost';

const useQrInvoice = (_data) => {
    const {data:result,loading,error,postData} = usePost();
    const urlGennerateQR = `${process.env.REACT_APP_QR_API_BASE_URL}/generate`;

    useEffect(() => {
        if(_data.sellerName && _data.vatRegistration && _data.timestamp && _data.totalAmount && _data.vatTotalAmount ){
            postData(urlGennerateQR,_data)
            console.log('QR data changed')
        }
    },[_data.sellerName,_data.vatRegistration,_data.timestamp,_data.totalAmount,_data.vatTotalAmount])


    return {result,loading,error}
}

export default useQrInvoice
import React, {  useEffect, useState } from 'react'

const SideModal = ({title,children,onShow,setOnShow,saveHandler=()=>{},cancelHandle=()=>{}}) => {
    const [isHidden,setIsHidden] = useState(true);

    const onHide = () => {
        setIsHidden(true);
        setOnShow(false);
        
    }
    
    useEffect(()=>{
        if(onShow && isHidden){
            setIsHidden(false);
        }
    },[onShow,isHidden]);
    
    const ChildrenComponent = (props) => {
        return React.cloneElement(children, props);
    }
    return (
        // <div className={`flex flex-col items-end h-screen w-full bg-gray-700/75 absolute bottom-0 top-0 left-0 right-0 z-50 ${ !isHidden ? 'block' : 'hidden'  } `} >
            <div className={` absolute z-50  h-screen end-0 top-0   bg-gray-300  border-s-2 border-slate-200 overflow-y-auto   transition-all duration-500  ease-out ${ !isHidden ? ' w-11/12 md:w-4/6' : ' w-0'  } `}>
                {/* <div
                    className=' flex items-center justify-between  bg-slate-200  rounded-t  border-b border-slate-400 p-3 text-sm bg-slate-200   sticky top-0'
                    >
                    <div>{title}</div>
                    <AiFillCloseCircle size={24} onClick={onHide} className='hover:text-sky-800' />
                </div> */}
                <div className=' h-full bg-slate-100 ' >
                    <ChildrenComponent onHide={onHide} /> 
                </div>
            </div>

        // </div>
    )
}

export default SideModal
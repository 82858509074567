import { useSelector } from "react-redux"; 

const useLang = () => {

    const langSelected = useSelector((state)=>state.lang)

    return {
        langSelected
    }
}

export default useLang
import React,{useEffect, useState} from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import {FiSettings} from 'react-icons/fi'
import MeunSideSetting from '../components/settings/MeunSideSetting'
import PaymentMethod from './settings/PaymentMethod'
import InvoiceSettings from './settings/InvoiceSettings'
import GeneralSettings from './settings/GeneralSettings'
import { MwSpinner,NoPermission } from '../components/ui'
import MyCompany from './settings/MyCompany'
import { useCurrent, useFetch } from '../hooks'

const Settiings = () => {
    const [activeMenu,setActiveMenu] = useState('GeneralSettings')
    const params = useParams();
    const usernameTenant = params.tenant
    const [loading,setLoading] = useState(false);
    const {user} = useCurrent();
    const {data:defineSettingData,loading:defineSettingLoading} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/defineSettings`); 

    const filterDefineSetting = (settingName) => {
        return defineSettingData?.data.filter(item => item.settingName === settingName)[0]
    }

    const refreshTenant = async() => {
        setLoading(true);
        try{
            const res = await axios.get(`${process.env.REACT_APP_Auth_API_BASE_URL}/tenants/username/${usernameTenant}`);
            const _data = res?.data;
            if(_data){
                localStorage.setItem('tenant',JSON.stringify( _data?.data));
                console.log('refreshTenant', _data?.data);
            }
            setLoading(false);
        }catch(err){
            console.log(err);
        }
    }

    useEffect(() => {
        console.log(user);
    },[]);



    return (
        <>
            {/* <h3 className='px-1 pb-1 text-slate-500'>settings</h3>
            <hr className='mb-3'/> */}
            <div className='flex justify-between items-center pb-5 pt-2'>
                <div className=' flex gap-1  text-sm text-gray-400 '>
                    <FiSettings size={18} /> 
                    إعدادات النظام 
                </div>
            </div>
            <div className='flex gap-2 mb-5'>
                <MeunSideSetting activeMenu = {activeMenu} setActiveMenu = {setActiveMenu}/>
                {
                    !loading &&
                    <div className='flex-1 bg-slate-50 rounded-lg p-3 '>
                        {   activeMenu === 'GeneralSettings' && defineSettingData && !defineSettingLoading && <GeneralSettings filterDefineSetting={filterDefineSetting} refreshTenant={refreshTenant}/>}
                        {   activeMenu === 'MyCompany' && <MyCompany refreshTenant={refreshTenant}/>   }
                        {   activeMenu === 'InvoiceSettings' && <InvoiceSettings filterDefineSetting={filterDefineSetting} refreshTenant={refreshTenant}/>}
                        {   activeMenu === 'PaymentMethod' && <PaymentMethod  filterDefineSetting={filterDefineSetting} refreshTenant={refreshTenant} />}
                        {
                            (
                                activeMenu !== 'MyCompany' && 
                                activeMenu !== 'GeneralSettings' &&
                                activeMenu !== 'InvoiceSettings' &&
                                activeMenu !== 'PaymentMethod' 
                            ) && 
                            <NoPermission msg={`ليس لديك صلاحية للدخول لهذة الصفحة`} />    
                        }
                    </div> 
                }
                {/* {
                    !loading && (user.user_class_id  )? 
                        <div className='flex-1 bg-slate-50 rounded-lg p-3 '>
                            {activeMenu === 'PaymentMethod' && <PaymentMethod />}
                            {activeMenu === 'InvoiceSettings' && <InvoiceSettings refreshTenant={refreshTenant}/>}
                            {activeMenu === 'GeneralSettings' && <GeneralSettings refreshTenant={refreshTenant}/>}
                            {activeMenu === 'MyCompany' && <MyCompany refreshTenant={refreshTenant}/>}
                        </div>: !user.user_class_id ? <NoPermission msg={`ليس لديك صلاحية للدخول لهذة الصفحة`} /> : <MwSpinner/>
                } */}
            </div>
        </>
    )

}

export default Settiings
import React, { useEffect, useState } from 'react'
import {MwButton} from '../../components/ui'
import {updateSalesman} from '../../services/salesmanService'
import { useCurrent, useFetch } from '../../hooks'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {AiOutlineSave} from 'react-icons/ai'
import CardHeadeWithActions from '../ui/CardHeadeWithActions'
import SalesmanForm from './SalesmanForm'
import {langObjType,objLangTextHandler,LangArrayToObjKeyValue} from '../../utils/global'

const EditSalesman = ({onHide,onSalesmanCreated = ()=>{} }) => {
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const {data:dataSalesman,loading:loadingSalesman} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/salesmans/show/${id}`);
    const {currentTenantId,currentCompany,currentBranchId,currentLangList,currentLangId} = useCurrent()
    const [errors,setErrors] = useState({});

    // FORM STATE
    const [salesmanNo,setSalesmanNo] = useState('')
    const [fullname,setFullname] = useState(langObjType())
    const [mobileNo,setMobileNo] = useState('')
    const [email,setEmail] = useState('')
    const [salesmanNotes,setSalesmanNote] = useState('')
    const [targetMonthly,setTargetMonthly] = useState();
    const [targetYearly,setTargetYearly] = useState();
    const [isActive,setIsActive] = useState(true);

    const saveHandler = async(e) => { 
        e.preventDefault();
        let errorObj = {};
        const data={
            fullname : objLangTextHandler(fullname),
            salesmanNo,
            mobileNo,
            email,
            salesmanNotes,
            targetMonthly,
            targetYearly,
            isActive,
            tenantId:currentTenantId,
            companyId:currentCompany,
            branchId:currentBranchId
        }
        if(!fullname){
            errorObj.fullname = 'Fullname is required';
        }
        if(isNaN(mobileNo)){
            errorObj.mobileNo = 'Mobile number is not a number';
        }
        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            console.log('errors',errors)
            return
        }
        const updated = await updateSalesman(id,data)
        if(updated){
            console.log('updated',updated)
            onHide ? cancelHandle() : navigate(`/${params.tenant}/salesmans`);
            onSalesmanCreated();
        }
    };
    const cancelHandle = () => {
        onHide();
    }
    useEffect(() => {
        if(dataSalesman){
            console.log('dataSalesman', dataSalesman);
            setSalesmanNo(dataSalesman.data?.salesmanNo)
            setFullname(LangArrayToObjKeyValue(dataSalesman.data?.fullname))
            setMobileNo(dataSalesman.data?.mobileNo)
            setEmail(dataSalesman.data?.email)
            setTargetMonthly(dataSalesman.data?.targetMonthly)
            setTargetYearly(dataSalesman.data?.targetYearly)
            setSalesmanNote(dataSalesman.data?.salesmanNotes)
            setIsActive(dataSalesman.data?.isActive)
        }
    },[dataSalesman]);
    return (
        <div className={`${onHide && 'p-5'} text-sm flex flex-col gap-5`}>
            <CardHeadeWithActions title={`تعديل مندوب `}>
                        { onHide && <MwButton inGroup={true}  type='cancelBtn' onClick={cancelHandle}>الغاء</MwButton> }
                            <Link to={`/${params.tenant}/salesmans`} >
                                <MwButton inGroup={true}  type='cancelBtn' >ادارة المناديب</MwButton> 
                            </Link>
                            <MwButton inGroup={true}  type='saveBtn' actionType={`button`} onClick={saveHandler}>
                            حفظ المندوب
                            <AiOutlineSave size={18}/>
                            </MwButton>
            </CardHeadeWithActions>

            <SalesmanForm
                currentLangList = {currentLangList}
                currentLangId = {currentLangId}
                errors = {errors}
                salesmanNo = {salesmanNo}
                setSalesmanNo = {setSalesmanNo}
                fullname = {fullname}
                setFullname = {setFullname}
                mobileNo   = {mobileNo}
                setMobileNo = {setMobileNo}
                email = {email}
                setEmail = {setEmail}
                salesmanNotes = {salesmanNotes}
                setSalesmanNote = {setSalesmanNote}
                targetMonthly = {targetMonthly}
                setTargetMonthly = {setTargetMonthly}
                targetYearly = {targetYearly}
                setTargetYearly = {setTargetYearly}
                isActive = {isActive}
                setIsActive = {setIsActive}
                />
        </div>
    )
}

export default EditSalesman
import {RiDeleteBinLine} from 'react-icons/ri'
import {  MwSwitch } from '../ui';
import {BiEdit} from 'react-icons/bi'
import { usePut } from '../../hooks';

const PaymentMethodList = ({data,loading,currentLangId ,confirmDeleteHandler,editHandler}) => {
    const {data:dataUpdate,loading:loadingUpdate,putData:putData} = usePut();


    const onChangeSwitch = (item) => {
        console.log(item);
        const _data = {
            "isActive" : !item.value,
        }
        putData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/payment-methods/update/${item.key}`,_data)

    }    

    const list = data.map((item,index) => {
        return item?.description?.map((description,i) => (
            <div key={i} className={`w-full`}>
                {description?.lang === currentLangId && 
                <div key={i} className={`flex flex-col  text-xs mt-3  ${ data?.length -1 !== index  ? `border-b` : ``}`}>
                    <div className='flex justify-between items-center'>
                        <div>{description?.name}</div>
                        <div className='flex flex-col items-center gap-2'>
                            <MwSwitch onChangeSwitch = {onChangeSwitch} initailValue={item?.is_active} custKey={item?.id} />
                            
                        </div>
                    </div>
                    <div className='flex justify-between  text-slate-500' >
                        <div>{description?.desc}</div>
                        <div className='flex   pt-2'>
                            <BiEdit onClick={ () => editHandler(item)} size={14} className='my-2' />
                            <RiDeleteBinLine onClick={ () => confirmDeleteHandler(item)} size={14} className='m-2'/>
                        </div>
                        </div>
                    
                </div>}
            </div>
        ))
    });

    return (
        <div>
            
            <div className='text-sm text-slate-500 my-3 px-1'>Payment Method List</div>
            <div className='p-3  rounded-lg border border-slate-200'>{list}</div>
        </div>
    );
}

export default PaymentMethodList
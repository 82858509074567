import React, { useEffect, useState } from 'react'
import { useCurrent, useFetch } from '../../../hooks'
import { MwSelectorMulti,MwSelector, MwSpinner,TagList } from '../../ui';
import {LangArrayToObjKeyValue} from '../../../utils/global'

const ProductLinks = ({
    productCategorySelected,
    setProductCategorySelected,
    productBrandSelected,
    setProductBrandSelected,
    productTags,
    setProductTags,
}) => {
    const {currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const {data:categories,loading:categoriesLoading} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/categories/${currentTenantId}/${currentCompanyId}`)
    const {data:brands,loading:brandsLoading} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/brands/${currentTenantId}/${currentCompanyId}`)
    const {data:tags,loading:tagsLoading} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/tags/${currentTenantId}/${currentCompanyId}`)
    
    
    const [categoriesList,setCategoriesList] = useState([]);
    const [brandsList,setBrandsList] = useState([]);
    const [tagsList,setTagsList] = useState([]);
    
    useEffect(() => {
        if(categories && !categoriesLoading ){
            let itemList = [];
            categories.data.map((el)=>itemList?.push({label:LangArrayToObjKeyValue(el.categoryName)?.[currentLangId],value:el._id,lang:currentLangId}));
            setCategoriesList(itemList)
        }
    },[categories]);


    useEffect(() => {
        if(brands && !brandsLoading ){
            let itemList = [];
            brands.data.map((el)=>itemList?.push({label:LangArrayToObjKeyValue(el.brandName)?.[currentLangId],value:el._id,lang:currentLangId}));
            setBrandsList(itemList)
        }
    },[brands]);
    
    
    return (
        <>
            {
                (categories && brands && tags && !categoriesLoading && !brandsLoading && !tagsLoading ) ? 
                <div className='relative flex flex-col gap-3 py-3 '>
                    <MwSelectorMulti
                        label={`التصنيف`}
                        initalValue='أختار التصنيف'
                        dataType = 'labelValue'
                        _data={categoriesList}
                        withAddNew = {false}
                        selectedItem={productCategorySelected}
                        setSelectedItem={setProductCategorySelected}
                        className={`z-20`}
                    />
                    <MwSelector
                        label={`الماركة`}
                        initalValue='أختار الماركة'
                        dataType = 'labelValue'
                        _data={brandsList}
                        withAddNew = {false}
                        selectedItem={productBrandSelected}
                        setSelectedItem={setProductBrandSelected}
                        className={`z-10`}
                    />
                    <TagList
                        tagList={productTags}
                        setTagList={setProductTags}
                        withLabel={true}
                        label={`اضف علامات للمنتج (Tags)`}
                    />
                </div> : <MwSpinner/>
            } 
        </>
    )
}

export default ProductLinks
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLogging: false,
    user:null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        setisLogging: (state,action) => {
            state.isLogging = action.payload.isLogging
            state.user = action.payload.user
        }
    }
})

export const {setisLogging} = authSlice.actions
export default authSlice.reducer
import React, { useEffect, useState } from 'react'
import {usePut,useCurrent} from '../../hooks/'
import {  MwButton, MwSpinnerButton } from '../../components/ui'
import { useDispatch } from 'react-redux'
import {updateSettings} from '../../store/settingSlice'
import SettingListItem from '../../components/settings/SettingListItem'


const GeneralSettings = ({refreshTenant,filterDefineSetting}) => {
    const dispatch = useDispatch();
    const {data,loading,putData} = usePut()
    const {currentCompanyId,currentTenantId,currentSettings,currentLangId} = useCurrent();
    const SETTING_GENERAL = currentSettings?.generalSettings;
    const [generalSetting,setGeneralSetting] = useState(SETTING_GENERAL)



    const generalSettingArray = Object.entries(generalSetting);

    const generalSettingList =  generalSettingArray.map(([settingName,settingValue]) =>(
        <SettingListItem
            key={settingName}
            settingName = {settingName}
            settingValue = {settingValue}
            filterDefineSetting = {filterDefineSetting}
            setGeneralSetting = {setGeneralSetting}
            generalSetting = {generalSetting}
            currentLangId = {currentLangId}
            />
    ));

    useEffect(()=>{
        if(data){
            refreshTenant();
        }
    },[data]);


    const saveHandler = async() => {
        const generalSettingArray = Object.entries(generalSetting).map(([key, value]) => ({ name:key , value: value }));
        const _data = {
            companyId:currentCompanyId,
            settingsGeneral:generalSettingArray
        }
        putData(`${process.env.REACT_APP_Auth_API_BASE_URL}/tenants/general-setting/${currentTenantId}`,_data)
        dispatch(updateSettings({
            typeSetting : 'general',
            value : generalSetting
        }));
        await refreshTenant();
    }

    return (
        <>
        <div>
            <div className='flex  justify-between items-center gap-2 text-xs text-slate-500 font-bold px-1 '>
                    <div>
                        الإعدادت العامة: 
                    </div>
                    { loading ? <MwSpinnerButton/> : <MwButton 
                                    inGroup = {true}
                                    size='sm'
                                    onClick={saveHandler}
                                    type = 'saveBtn'  > حفظ التغيرات
                                    </MwButton> }
            </div>
            <div className='p-3 my-3  rounded-lg border border-slate-200' >{generalSettingList}</div>
        </div>
        </>
    )
}

export default GeneralSettings
import {useState} from 'react'
import ListTags from '../../components/Products/tag/ListTags';
import CreateTag from '../../components/Products/tag/CreateTag';
import EditTag from '../../components/Products/tag/EditTag';

const ProductsBrands = () => {

    const [mode,setMode] = useState('List');
    const [idSelected,setIdSelected] = useState();

    return (
        <>
            <div className="p-3">
                { mode === 'List' && <ListTags idSelected = {idSelected} setIdSelected = {setIdSelected} setMode={setMode} /> }
                { mode === 'Add' && <CreateTag setMode={setMode} /> }
                { (mode === 'Edit' && idSelected) && <EditTag id={idSelected} setMode={setMode} /> }
            </div>
        </>
    )
}

export default ProductsBrands
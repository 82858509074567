import {LangArrayToObjKeyValue} from '../../../utils/global'
import {AiOutlineEdit} from 'react-icons/ai'
import {RiDeleteBin6Line} from 'react-icons/ri'
import { useCurrent } from '../../../hooks';

const ListTagsItem = ({el,editHandeler,deleteHandeler}) => {
  const {currentLangId} = useCurrent();
  const tagName = LangArrayToObjKeyValue(el.tagName)?.[currentLangId] ;

  return (
    <div key={el._id} className='flex justify-between items-center my-1 gap-2 border-b py-2 hover:font-bold hover:bg-indigo-50'>
        <div className='flex-1 px-2'> <span>{tagName}</span> </div>
        <div className={` w-16 text-center px-2 pb-1 rounded-lg   ${el.isActive ? ' bg-green-200 ' : 'bg-slate-200' }`}>{el.isActive ? 'مفعل' : 'غير مفعل'}</div>
        <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>editHandeler(el._id)}><AiOutlineEdit size={18}/></div>
        <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>deleteHandeler(el._id)}><RiDeleteBin6Line size={18} /></div>
      </div>
  )
}

export default ListTagsItem
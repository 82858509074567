import React from 'react'
import { useCurrent } from '../../../hooks';
import {LangArrayToObjKeyValue} from '../../../utils/global'
import {AiOutlineEdit} from 'react-icons/ai'
import {RiDeleteBin6Line} from 'react-icons/ri'

const ListGroupsItem = ({item,editHandeler,deleteHandeler}) => {
    const {currentLangId} = useCurrent();
    const groupName = LangArrayToObjKeyValue(item.groupName)?.[currentLangId] ;

    return (
        <div  className='flex justify-between items-center my-1 gap-4 border-b py-2 hover:font-bold hover:bg-indigo-50'>
            <div className='flex-1 px-2'> <span>{groupName}</span> </div>
            <div className={` w-16 text-center px-2 pb-1 rounded-lg   ${item.isActive ? ' bg-green-200 ' : 'bg-slate-200' }`}>{item.isActive ? 'مفعل' : 'غير مفعل'}</div>
            <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>editHandeler(item._id)}><AiOutlineEdit size={18}/></div>
            <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>deleteHandeler(item._id)}><RiDeleteBin6Line size={18} /></div>
        </div>
    );
}

export default ListGroupsItem
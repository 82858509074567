import React from 'react'
import InvFormListItem from './InvFormListItem'

const InvFormBody = ({
    initalProductItem,
    reloadProductList,
    showModalProductHandle,
    productList,
    setProductList,
    formErrors,
    SETTING_PRICE_INCLUDE_VAT
}) => {
    console.log('initalProductItem',initalProductItem);
    return (
        <>
        <hr />
        <div id='inv-body' className='  h-96 overflow-y-auto bg-slate-100 p-3 ' >
                    { initalProductItem.map((e,index)=>{
                        if(index < productList.length  ){
                            return <InvFormListItem
                                        key = {index}
                                        index = {index}
                                        reloadProductList = {reloadProductList}
                                        showModalProductHandle = {showModalProductHandle}
                                        productList = {productList}
                                        setProductList = {setProductList}
                                        formErrors = {index ===0 && formErrors}
                                        SETTING_PRICE_INCLUDE_VAT = {SETTING_PRICE_INCLUDE_VAT}
                                        />
                        }
                        })
                    }
        </div>
        <hr />
        </>
    )
}

export default InvFormBody
import React from 'react'
import { MwSpinnerButton, NoDataFound } from '../../components/ui'
import TransactionsListItem from './TransactionsListItem'
import { useInvoice } from '../../hooks'
import parse from 'html-react-parser';

const TransactionsList = ({data,loading,lang,paginateHandler}) => {
    const {formatter} = useInvoice()
    return (
        <div className='flex-1  flex flex-col justify-center items-center'>
            {loading && <MwSpinnerButton withLabel={false}/>}
            {
                data?.data.length > 0 ?
                <div className='w-full text-xs rounded-xl bg-slate-50'>
                    <div className='flex gap-2 px-3 py-2 rounded-t-xl justify-between items-center bg-slate-100'>
                        <div className='w-8 text-center'>#</div>
                        <div className='w-32 text-center'>رقم العملية</div>
                        <div className='w-20 text-center'>رقم الفاتورة</div>
                        <div className='flex-1 text-center'>طريقة الدفع</div>
                        <div className='w-24 text-center'> المبلغ</div>
                        <div className='w-24 text-center'>نوع العملية</div>
                        <div className='w-32 text-center'> التاريخ</div>
                        
                    </div>
                    {
                        data?.data.map((item,index) => <TransactionsListItem key={item.id} formatter={formatter} lang={lang} index={index} item={item} />)
                    }
                    { data?.data?.length > 0 &&
                        <div id="dataLinks" className="list-none flex gap-3 border-t py-3 px-5 cursor-default">
                        {
                            data?.data?.length > 3 && 
                            data?.links.map((item,i) => <li key={i} className='hover:font-bold' onClick={()=>item?.url && paginateHandler(item?.url.replace('http','https'))} >{ parse(item?.label)}</li>)
                        }
                        </div> 
                    }
                </div>: <NoDataFound  msg={`لا يوجد عمليات دفع او قبض`}/>
            }
        </div>
    )
}

export default TransactionsList
import React from 'react'
import { useTranslation } from 'react-i18next'

const InvoiceFooter = ({
    totalAmount,
    vatAmount,
    discountAmount,
    netAmount,
    invTerms,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_INV_VAT_PERCENT,
    SETTING_SHOW_TERMS_ON_PRINT,
    isDelivery,
    currentLang,
    SETTING_INV_CURRENCY
}) => {

    const [t] = useTranslation('global')
    const invTermsHandler = invTerms

    return (
        <>

        { !isDelivery &&
        <div> 
            <div className='my-3 border rounded text-xs'>
                
                {
                    discountAmount ? 
                    <div className='flex-col'>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div>{ t('invoice.TOTAL_BEFORE_DISCOUNT') }</div>
                            <div className=''>{ parseFloat(totalAmount) + parseFloat(discountAmount) }  {SETTING_INV_CURRENCY}</div>
                        </div>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div>{t('invoice.discount')}</div>
                            <div className=''>{discountAmount}  {SETTING_INV_CURRENCY}</div>
                        </div>
                        <div className='flex justify-between px-3 py-2 border-b'>
                            <div>{t('invoice.TOTAL_AFTER_DISCOUNT')}</div>
                            <div className=''>{totalAmount}  {SETTING_INV_CURRENCY}</div>
                        </div>
                    </div> : 
                    <div className='flex justify-between px-3 py-2 border-b'>
                        <div>{t('invoice.total')}</div>
                        <div className=''>{totalAmount}  {SETTING_INV_CURRENCY}</div>
                    </div>
                }
                <div className='flex justify-between px-3 py-2 border-b'>
                    <div>{t('invoice.VAT')}({SETTING_INV_VAT_PERCENT}%)</div>
                    <div className=''>{vatAmount} {SETTING_INV_CURRENCY}</div>
                </div>
                <div className='flex justify-between px-3 py-2 border-b'>
                    <div>{t('invoice.netTotal')}</div>
                    <div className='font-bold'>{netAmount}  {SETTING_INV_CURRENCY}</div>
                </div>
            </div>
            <div className='pt-2 flex flex-col justify-center items-center'>
                            { SETTING_PRICE_INCLUDE_VAT === 'true' ?
                                <div className='flex justify-center items-center text-xs text-slate-400 '>{t('invoice.priceIncludesVat')}</div> : 
                                <div className='text-xs text-slate-400 text-center'>{t('invoice.priceNotIncludesVat')}</div> 
                                
                            }
                        </div>
        </div>}
        { SETTING_SHOW_TERMS_ON_PRINT === 'true' && invTermsHandler?.text && !isDelivery && <div className='text-xs py-5'>
            <div className='font-bold '>- {t('invoice.ConditionAndTerms')}</div>
            <div className='pt-1 px-2 '>{invTermsHandler?.text}</div>
        </div>}
        </>
    )
}

export default InvoiceFooter
import React from 'react'

const TransactionsListItem = ({formatter,item,index,lang}) => {
    const _desc = item?.payment_method?.description?.filter((desc) => desc.lang.trim() === lang)[0];
    return (
        <div className='flex gap-2 px-3 py-1 border-t justify-between items-center text-slate-700 hover:font-bold hover:bg-slate-100'>
            <div className='w-8 text-center'>{index+1}</div>
            <div className='w-32 text-center'>{item.transaction_no}</div>
            <div className='w-20 text-center'>{item.inv_no}</div>
            <div className='flex-1 text-center'>{_desc?.name}</div>
            <div className='w-24 text-center'> { item.type.trim() === 'in' ? <span>{formatter.format(item?.amount)}</span>:<span>{`(${formatter.format(item?.amount)})`}</span> }   </div>
            <div className='w-24 text-center'>{ item.type.trim() === 'in' ? <span>قبض</span>:<span>دفع</span> } </div>
            <div className='w-32 text-center text-slate-500' dir='ltr'> {item.created_at}</div>
        </div>
    )
}

export default TransactionsListItem
import React from 'react'

const MwButton = (props) => {
    const {
            actionType,
            onClick,
            children,
            type,
            size,
            classNameCustom,
            inGroup = false,
            disabled = false,
        } = props
        let classItem = `flex items-center justify-center gap-1  drop-shadow-md  text-sm rounded ${size ==='sm' ? ' h-6 px-2' : 'h-8 px-4' } ${classNameCustom} `;
        switch(type) {
            case 'secondary':
                classItem += ` border border-slate-500 text-xs font-medium text-slate-500  ${ disabled || 'hover:text-slate-900 hover:border-slate-900'}   ${inGroup && 'rounded-none rounded-s-lg '}`
                break;
            case 'secondary-inline-end':
                classItem += ` border-t border-b border-e border-slate-500 text-xs font-medium text-slate-500  ${ disabled || 'hover:text-slate-900 hover:border-slate-900'}   ${inGroup && 'rounded-none  '}`
                break;
            case 'saveBtn':
                classItem +=  `border border-indigo-500 text-xs font-medium text-indigo-500  ${ disabled && 'text-slate-400 border-slate-400 hover:text-slate-400 hover:border-slate-400'}  ${inGroup && 'rounded-none rounded-e-lg '}`   
                break; 
            case 'deleteBtn':
                classItem +=  `border border-red-500 text-xs font-medium text-red-500 hover:text-red-900 hover:border-red-900  ${inGroup && 'rounded-none rounded-e-lg '}`   
                break; 
            case 'deleteBtn-inline':
                classItem +=  ` border-red-500 text-xs font-medium text-red-500 hover:text-red-900 hover:border-red-900  ${inGroup ? ' rounded-none  border-y border-slate-500' : 'border'}`   
                break; 
            case 'cancelBtn':
                classItem +=  `border border-slate-500 text-xs font-medium text-slate-500 hover:text-slate-900 hover:border-slate-900  ${inGroup && 'border-0 border-s border-t border-b rounded-none  rounded-s-lg '}`   
                break; 
            case 'bottomBtn':
                classItem +=  ` text-sm font-medium  rounded-none ${disabled ? 'bg-gray-400 text-slate-200' : 'bg-gray-700 text-slate-100 hover:bg-gray-900'}`   
                break; 
            default :
                classItem += ' bg-sky-500 text-white hover:bg-sky-700'
        }
    return (
        <button
            type={actionType}
            onClick={onClick} 
            disabled = {disabled}
            className={classItem}>
            {children}
        </button>
    )
}

export default MwButton
import usePost from './usePost'
import useCurrent from './useCurrent';
import useFetch from './useFetch';

const useLogs = () => {
    const _url = process.env.REACT_APP_LOGS_API_BASE_URL;
    const {data:resultLogsPost,loading:loadingLogsPost,error:errorLogsPost,postData} = usePost();
    const {currentTenantId,currentCompany,currentBranchId,userId,userName} = useCurrent();
    const {data:resultLogsFetch,loading:loadingLogsFetch,error:errorLogsFetch} = useFetch(`${_url}/${currentTenantId}`);

    const postDataHandler = async({moduleName,resourceName,eventName,logContentEn,logContentAr}) => {
        
        const _data = {
            appId: "000000",
            tenantId: currentTenantId,
            companyId: currentCompany,
            branchId: currentBranchId,
            userId:userId,
            userName: userName,
            moduleName: moduleName,
            resourceName: resourceName,
            eventName:eventName,
            logContentEn: logContentEn,
            logContentAr: logContentAr
        }
        await postData(_url,_data)
    }

    return {
        resultLogsPost,
        loadingLogsPost,
        errorLogsPost,
        postDataHandler,
        resultLogsFetch,
        loadingLogsFetch,
        errorLogsFetch
    }
}

export default useLogs
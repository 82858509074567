import React from 'react'
import {BadgeActive} from '../ui'

const SalesReportsListItem = ({item,formatter}) => {
    
    return (
        <div className='px-5 py-2 flex gap-3 justify-between items-center text-xs border-t border-slate-300 hover:bg-slate-100 hover:font-bold'>
            <div  className='w-20 text-center'>{item.inv_no}</div>
            {/* <div  className='w-24 text-center'>{item.inv_ref}</div> */}
            <div  className='text-center'>{item.inv_date.substring(0,10)}</div>
            {/* <div  className='w-24 text-center'>{item.inv_date_due}</div> */}
            <div  className='w-20 text-center'>{formatter.format(item.total_amount)}</div>
            <div  className='w-16 text-center'>{formatter.format(item.discount_amount || 0)}</div>
            <div  className='w-24 text-center'>{formatter.format(item.total_amount - (item.discount_amount || 0))}</div>
            <div  className='w-16 text-center'>{formatter.format(item.vat_amount)}</div>
            <div  className='w-20 text-center'>{formatter.format(item.net_amount)}</div>
            <div  className='w-20 text-center'> <BadgeActive state={item.is_paid} label={item.is_paid ? 'مدفوع' : 'غير مدفوع'} /></div>
        </div>
    )
}

export default SalesReportsListItem
import React, { useEffect } from 'react'

const Logout = () => {
    const redirectUrl = `${process.env.REACT_APP_Auth_WEB_BASE_URL}/logout`;

    const logoutHandler = async() => {
        await localStorage.clear();
        window.location.assign(redirectUrl);
    }

    logoutHandler();

    return (
        <div>Logout...</div>
    )
}

export default Logout

import {useEffect, useState} from 'react'
import {MdOutlineAddBox} from 'react-icons/md'
import { ConfirmDelete, MwButton, MwSpinnerButton } from '../../components/ui';
import { useCurrent, useDelete, usePost } from '../../hooks';
import ListProductsItems from './ListProductsItems';
import NoDataFound from '../ui/NoDataFound';
import CardHeadeWithActions from '../ui/CardHeadeWithActions';

const ListProducts = ({idSelected,setIdSelected,setMode}) => {
    const [products,setProducts] = useState();
    const { currentTenantId ,currentCompanyId} = useCurrent();
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const {data:postResult,loading:postLoading,error,postData} = usePost();
    const {data:deleteResult ,loading:deleteLoading,deleteItem} = useDelete()
    
    const getProducts = () =>{
        postData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/search/All`,{
            tenantId: currentTenantId,
            companyId: currentCompanyId
        })
    }



    const onChangeSearch = (newValue) => {
       // setData(newValue);
    }
    const editHandeler = (id) =>{
        setIdSelected(id)
        setMode('Edit');
    }
    const deleteHandeler = (id) =>{
        setIdSelected(id)
        setShowConfirmDelete(true);
    }
    const confirmDeleteHandler = () =>{
        deleteItem(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/delete/${idSelected}`)
    }

    useEffect(() => {
        getProducts();
    },[]);

    useEffect(()=>{
        if(postResult){
            setProducts(postResult.data)
            console.log('Products',postResult.data)
        }
    },[postResult]);


    useEffect(()=>{
        if(deleteResult && !deleteLoading){
            setShowConfirmDelete(false);
            getProducts();
        }
    },[deleteResult]);

    return (
        <>
            <ConfirmDelete 
                msg={`هل انت متاكد من حذف المنتج`} 
                onShow={showConfirmDelete} 
                setOnShow={setShowConfirmDelete}
                onDeleteHandler = {confirmDeleteHandler}
                />
            <div className='text-slate-500 bg-slate-50 rounded-lg text-xs p-3'>
                <CardHeadeWithActions title={`قائمة المنتجات`}>
                        <MwButton inGroup={true} type='saveBtn' onClick={()=>setMode('Add')}  >
                            <MdOutlineAddBox size={18}/>
                            انشاء منتج جديدة
                        </MwButton>
                </CardHeadeWithActions>
                { postLoading && <MwSpinnerButton  withLabel={false} isFullCenter={true} />}
                {  
                    products?.length > 0 ? 
                    <div className='pt-3'>{
                        products?.map((item,index) =>(<ListProductsItems key={item._id} item={item} index={index} editHandeler = {editHandeler} deleteHandeler = {deleteHandeler} />))
                }</div> : !postLoading && <NoDataFound msg={`لا يوجد منتجات`}/>  }
            </div>
        </>
    )
}

export default ListProducts
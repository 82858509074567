import {useState,useEffect} from 'react'
import {MdOutlineAddBox} from 'react-icons/md'
import { useCurrent,useFetch,useDelete } from '../../../hooks';
import ListBrandsItem from './ListBrandsItem';
import { ConfirmDelete, MwButton } from '../../ui';
import NoDataFound from '../../ui/NoDataFound';
import CardHeadeWithActions from '../../ui/CardHeadeWithActions';

const ListBrands = ({idSelected,setIdSelected,setMode}) => {
    const {currentTenantId,currentCompanyId} = useCurrent();
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const {data:brands,loading,error,refreshHandler} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/brands/${currentTenantId}/${currentCompanyId}`);
    const {data:deleteResult,loading:deleteLoading,deleteItem} = useDelete()

    const editHandeler = (id) => {
        setIdSelected(id)
        setMode('Edit')
    } 

    const deleteHandeler = (id) => {
        setIdSelected(id)
        setShowConfirmDelete(true);
    }

    const confirmDelete = () => {
        deleteItem(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/brands/delete/${idSelected}`)
    }

    useEffect(() =>{
        if(deleteResult){
            setShowConfirmDelete(false);
            refreshHandler();
        }
    },[deleteResult]);

    return (
        <>
            <div className='text-slate-500 bg-slate-50 rounded-lg text-xs p-3'>
                
                <ConfirmDelete onDeleteHandler={()=>confirmDelete()} onShow={showConfirmDelete} setOnShow={setShowConfirmDelete} msg={`هل انت متاكد من حذف الماركة`}/>
                
                <CardHeadeWithActions title={`قائمة الماركات`}>
                    <MwButton inGroup={true} type='saveBtn' onClick={() => setMode('Add')}  >
                        <MdOutlineAddBox size={18}/>
                        انشاء ماركة جديدة
                    </MwButton>
                </CardHeadeWithActions>
                
                <div className='py-5'> {
                    brands?.data.length > 0 ? brands?.data.map((el) =>(<ListBrandsItem el={el} key={el._id} editHandeler = {editHandeler} deleteHandeler = {deleteHandeler} />)) : <NoDataFound msg={`لا توجد ماركات`} />
                } </div>
            </div>
        </>
        )


}

export default ListBrands
import React, { useState , useEffect} from 'react'
import {getAllSalesman} from '../services/salesmanService';
import ListSalesmans from '../components/Salesmans/ListSalesmans'
import { useCurrent } from '../hooks';
import { MwButton } from '../components/ui';
import { Link, useParams } from 'react-router-dom';
import {LiaUsersSolid} from 'react-icons/lia'
import {MdOutlineAddBox} from 'react-icons/md'

const Salesmans = () => {
    const [salesmans,setSalesman] = useState([]); 
    const { currentTenantId ,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
    const params = useParams();

    const getSallesman = async () => {
        const _salesman = await getAllSalesman(currentTenantId ,currentCompanyId,currentBranchId)
        setSalesman(_salesman)
    } 

    useEffect(() => {
        getSallesman();
    },[]);

    return (
        <>
            <div className='flex justify-between items-center pb-3'>
                <div className=' flex gap-1 px-2 text-sm text-gray-400 '>
                    <LiaUsersSolid size={18} />
                    إدارةالمناديب </div>
                <Link to={`/${params.tenant}/salesmans/add`}>
                    <MwButton inGroup={true} type='saveBtn'  >
                        <MdOutlineAddBox size={18}/>
                        أضف مندوب جديد
                        </MwButton>
                </Link>
            </div>
        
            <div className='flex flex-col gap-3 m-2 '>
                <ListSalesmans 
                    data={salesmans} 
                    setData = {setSalesman}
                    currentCompanyId = {currentCompanyId} 
                    currentBranchId = {currentBranchId}
                    currentLangId = {currentLangId}
                    refreshResult = {getSallesman}
                    />
            </div>
        </>
    )
}

export default Salesmans
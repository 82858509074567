import React from 'react'
import {TiWarningOutline} from 'react-icons/ti'

const NoPermission = ({msg}) => {
    return (
        <div 
            className='w-full flex flex-col justify-center items-center gap-3 text-sm text-slate-400 font-bold '
            >
                <TiWarningOutline size={40}/>
                {msg}
        </div>
    )
}

export default NoPermission
import React, {  useEffect, useState } from 'react'
import {RiDeleteBin6Line} from 'react-icons/ri'
import MwButton from './MwButton';
import MwSpinnerButton from './MwSpinnerButton';

const ConfirmDelete = ({title,msg,onShow,setOnShow,loading = false,onDeleteHandler=()=>{},onCancelHandle=()=>{}}) => {
    const [isHidden,setIsHidden] = useState(true);

    const onHide = () => {
        setIsHidden(true);
        setOnShow(false);
        
    }
    
    useEffect(()=>{
        if(onShow && isHidden){
            setIsHidden(false);
        }
        if(!onShow && !isHidden){
            onHide();
        }
    },[onShow,isHidden]);
    
    
    return (
        <div className={`flex flex-col items-center justify-center h-screen w-full bg-gray-700/75 absolute bottom-0 top-0 left-0 right-0 z-50 ${ !isHidden ? 'block' : 'hidden'  } `} >
            {/* <div className='p-5 m-5 rounded-full border-4 bg-red-300 opacity-95'><RiDeleteBin6Line className='text-slate-50'  size={70}/></div> */}
            <div className=' flex flex-col items-between justify-between  h-max w-11/12 md:w-4/6  bg-gray-300  rounded-lg overflow-y-auto relative '>
                {/* <div
                    className=' flex items-center justify-between  bg-slate-200  rounded-t  border-b border-slate-400 p-3 text-sm bg-slate-200   sticky top-0'
                    >
                    <div>{title}</div>
                    <AiFillCloseCircle size={24} onClick={onHide} className='hover:text-sky-800' />
                </div> */}
                <div className=' h-full bg-slate-100 ' >
                    <div className='py-2 px-2'>
                        { loading ?
                            <div className='flex justify-center p-5'><MwSpinnerButton/></div> : 
                            <div className='p-5 flex flex-col justify-between gap-3 '>
                                <div className='text-sm text-red-500  flex   gap-1 justify-start'>
                                    <RiDeleteBin6Line  size={20}/>
                                    <span>{msg}</span>
                                </div>
                                <div className='flex justify-end items-end'>
                                    <MwButton 
                                        inGroup = {true}
                                        type = 'cancelBtn' 
                                        onClick ={onHide}
                                        > الغاء </MwButton> 
                                    <MwButton 
                                        inGroup = {true}
                                        type = 'deleteBtn'
                                        onClick = {onDeleteHandler}
                                        > تاكيد الحذف </MwButton>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ConfirmDelete
import React from 'react'
import { IoIosCloseCircleOutline } from "react-icons/io";

const NotificationBarItem = ({children,setShow}) => {
    return (
        <div className='flex items-center justify-center bg-indigo-100 h-8 text-sm text-indigo-600 border-indigo-200 border-b'>    
            <div className='flex-1 flex  items-center justify-center'>
                {children}
            </div>
            <IoIosCloseCircleOutline onClick={()=>setShow(false)} size={20} className='mx-3' />
        </div>
    )
}

export default NotificationBarItem
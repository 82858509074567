import {tenant} from '../utils/tenantManager'
import { useSelector } from 'react-redux'

const useCurrent = () => {

    const currentTenantId = tenant()?._id;

    const currentLangList = tenant()?.languages;

    const currentCompanyId = useSelector(state => state.currentCompany).value

    const currentCompany = tenant()?.companies?.filter(company => company._id === currentCompanyId)[0]

    const currentBranchId = useSelector(state => state.currentBranch).value 

    const user = useSelector(state => state.auth).user
    const userId = user?._id 
    const userName = user?.name 


    const currentLangId = useSelector(state => state.lang).value

    const currentSettings = useSelector(state => state.settings);

    const currentSettingsDetails = currentCompany?.settings;

    return {currentTenantId,currentCompany,currentCompanyId,currentBranchId,user,userId,userName,currentLangList,currentLangId,currentSettings,currentSettingsDetails}
}

export default useCurrent
import {  useTranslation } from 'react-i18next';

const InvoiceHeader = ({
    logo,
    companyName,
    companyDesc,
    vatNo,
    inv_no,
    inv_date,
    inv_date_due,
    companyAddress,
    invoiceSettings
}) => {

    const [t] = useTranslation('global')
    console.log('invoiceSettings',invoiceSettings);
    return (
        <div >
            <div className='flex justify-between'>
                <div className='flex gap-1'>
                    {invoiceSettings?.SHOW_LOGO_ON_PRINT === 'true' && <div><img src={logo} className='w-24' /></div> }
                    <div className='flex  flex-col gap-1 ps-2 '>
                        <div className='font-bold' >{companyName}</div>
                        <div className='flex  flex-col gap-1 text-xs text-gray-500'>
                            <div>{companyDesc}</div>
                            <div>{t('invoice.VAT_NUMBER')}: {vatNo}</div>
                            <div >{companyAddress}</div>
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center font-bold'> عرض سعر </div>
                <div className='flex   '>
                    <div className='w-full flex  flex-col pe-2 items-start  gap-1 '>
                        <div className='text-xs flex items-center justify-between gap-2  text-gray-500  '>
                            <span>رقم العرض:</span>
                            <span>{inv_no}</span>
                        </div>
                        <div className='text-xs flex items-center justify-between  gap-2  text-gray-500 '>
                            <span>{t('invoice.invoiceDate')}:</span>
                            <span>{inv_date?.substring(0,10)}</span>
                        </div>
                        <div className='text-xs flex items-center justify-between gap-2   text-gray-500'>
                            <span>{t('invoice.invoiceDateDue')}:</span>
                            <span>{inv_date_due}</span>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default InvoiceHeader
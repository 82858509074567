import React from 'react'
import { BasicSelector , MwInputText, MwSelector, MwSwitch, MwTextArea } from '../../ui';
import FormLangBar from '../../FormLangBar';

const CategoryForm = ({
    categories,
    currentLangList,
    formLang,
    setFormLang,
    errors,
    categoryParent,
    setCategoryParent,
    categoryClasses,
    setCategoryClass,
    categoryName,
    setCategoryName,
    categoryDesc,
    setCategoryDesc,
    categoryMetaKeywords,
    setCategoryMetaKeywords,
    categoryMetaDesc,
    setCategoryMetaDesc,
    isShowOnWebsite,
    setIsShowOnWebsite,
    isActive,
    setIsActive
}) => {
    return (
        <form >
        <div id='categoryType' className='flex justify-between items-center py-4 '>
                <label className='text-xs text-slate-400'>نوع التصنيف</label>
                <div className='flex gap-7'>
                    <div id='categoryParentMain'>
                        <input id='isActiveMain' type="radio" checked={categoryParent==='main'}  onChange={()=>setCategoryParent('main')} value={categoryParent}/>
                            <label htmlFor="isActiveMain" className='px-1' >رئيسي</label>
                        </div>
                    <div id='categoryParentbranch'>
                        <input id='isActiveSub' type="radio" checked={categoryParent !== 'main'} onChange={()=>setCategoryParent('sub')} value={categoryParent}/> 
                        <label htmlFor="isActiveSub" className='px-1' >فرعي</label> 
                        </div>
                </div>
        </div>
        { categoryParent !== 'main' && <div className={`relative my-3   z-50 `}>
                <label className='text-xs text-slate-400'>التصنيف الرئيسي</label>
                <MwSelector 
                    className={`${errors.categoryParent && ' border rounded border-red-500'}`}
                    withAddNew = {false}
                    _data={categories} 
                    dataType={`labelValue`} 
                    selectedItem={categoryParent} 
                    initalValue='اختار التصنيف الرئيسي' 
                    setSelectedItem={(newValue) => setCategoryParent(newValue)} />
                {errors.categoryParent && <div className='text-xs text-red-400 px-2'>{errors.categoryParent}</div>}
        </div> }
        <div className={`relative my-3   `}>
                <label className='text-xs text-slate-400'>فئة التصنيف</label>
                <BasicSelector 
                    className={`bg-white ${errors.categoryClass && ' border-red-500 '} `}
                    listItem={categoryClasses} 
                    label='اختار فئة التصنيف ' 
                    size='lg'
                    onSelected={(newValue) => setCategoryClass(newValue.value)} />
                {errors.categoryClass && <div className='text-xs text-red-400 px-2'>{errors.categoryClass}</div>}
        </div>
        <div id='MultiLangBlock' className='flex flex-col'>
        <FormLangBar currentLangList = {currentLangList} formLang = {formLang} setFormLang = {setFormLang} />
            { currentLangList.map((lang) => (
                            <div key={lang.langCode} className=''>
                                <div className={` ${ formLang === lang?.langCode ? 'block' : 'hidden' } `}>
                                    <div id='categoryName' className=' '>
                                    <MwInputText
                                        label={`اسم التصنيف (${lang?.langName})`}
                                        id={`categoryName${lang?.langCode}`} 
                                        value={categoryName[lang?.langCode]}
                                        invalid={!!errors[`categoryName.${lang?.langCode}`] }
                                        invalidMsg = {errors[`categoryName.${lang?.langCode}`]}
                                        onChange={(e)=>{
                                            setCategoryName({...categoryName, [lang?.langCode]:e.target.value});
                                        }}
                                    />
                                    </div>
                                    <div id='categoryDesc' className='my-2'>
                                        <MwTextArea
                                            id={`categoryDesc${lang?.langCode}`}
                                            value={categoryDesc[lang?.langCode]}
                                            placeholder={`وصف التصنيف (${lang.langName}) `}
                                            rows={4}
                                            onChange={(e ) => setCategoryDesc({...categoryDesc,[lang?.langCode]:e.target.value})}
                                        />
                                    </div>
                                    <div id='categoryMetaKeywords' className='my-2'>
                                        <MwTextArea
                                            id={`categoryMetaKeywords${lang?.langCode}`}
                                            value={categoryMetaKeywords[lang?.langCode]}
                                            placeholder={` الكلمات الدليلة SEO (${lang.langName}) `}
                                            rows={4}
                                            onChange={(e ) => setCategoryMetaKeywords({...categoryMetaKeywords,[lang?.langCode]:e.target.value})}
                                        />
                                    </div>
                                    <div id='categoryMetaDesc' className='my-2'>
                                        <MwTextArea
                                            id={`categoryMetaDesc${lang?.langCode}`}
                                            value={categoryMetaDesc[lang?.langCode]}
                                            placeholder={` الوصف SEO (${lang.langName}) `}
                                            rows={4}
                                            onChange={(e ) => setCategoryMetaDesc({...categoryMetaDesc,[lang?.langCode]:e.target.value})}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        )) }
        </div>
        <div id='OptionsBock' className='flex flex-col justify-between pb-2 px-1'>
            <div className='flex justify-between items-center py-4  border-b mb-2'>
                <label className='text-xs text-slate-400'>  يظهر / لا يظهر (علي الموقع الالكتروني) </label>
                <MwSwitch custKey='isActive' isActive={isShowOnWebsite} setIsActive={setIsShowOnWebsite} onChangeSwitch={()=> setIsShowOnWebsite(!isShowOnWebsite)} />
            </div>
            <div className='flex justify-between items-center py-4  border-b mb-2'>
                <label className='text-xs text-slate-400'>مفعل / غير مفعل</label>
                <MwSwitch custKey='isActive' isActive={isActive} setIsActive={setIsActive} onChangeSwitch={() => setIsActive(!isActive)} />
            </div>
        </div>
    </form>
    )
}

export default CategoryForm
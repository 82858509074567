import React from 'react'

const MwTextArea = ({id,value,onChange,label,placeholder,rows}) => {
    return (
        <>
            { label && 
                <label className='text-xs text-slate-500 ' htmlFor="inv_no">{label}</label>}
            <textarea 
                className=' text-xs text-slate-600 border w-full rounded border-0 p-2 ' 
                id={id}
                name={id} 
                value={value} 
                onChange={onChange}  
                placeholder={placeholder}
                rows={rows} 
                style={{resize: 'none'}}
                >
                                        
            </textarea>
        </>
    )
}

export default MwTextArea
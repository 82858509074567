import React, { useEffect, useState } from 'react'
import {MwButton} from '../../components/ui'
import {addVendor} from '../../services/vendorService'
import { useCurrent, usePost } from '../../hooks'
import CardHeadeWithActions from '../ui/CardHeadeWithActions'
import BasicInfoForm from './forms/BasicInfoForm'
import ResponsiblesForm from './forms/ResponsiblesForm'
import AddressForm from './forms/AddressForm'
import AdvancedForm from './forms/AdvancedForm'
import {langObjType,objLangTextHandler} from '../../utils/global'

const CreateVendor = ({onHide ,onVendorCreated = ()=>{} ,setMode}) => {

    const {currentTenantId,currentCompanyId,currentBranchId,currentLangList,currentLangId} = useCurrent()
    const [errors,setErrors] = useState({});
    const [tabActive,setTabActive] = useState('TabOne');
    const {data:resultPost,postData} = usePost();
    // Basic Form  
    const [fullname,setFullname] = useState(langObjType())
    const [customerNo,setCustomerNo] = useState('')
    const [groupSelected,setGroupSelected] = useState([]); // customerGroupId
    const [mobileNo,setMobileNo] = useState('')
    const [phoneNo,setPhoneNo] = useState();
    const [email,setEmail] = useState('')
    const [customerNotes,setCustomerNote] = useState('')
    const [isActive,setIsActive] = useState(true)
    // Rresponsibles Form
    const [responsibles,setResponsibles] = useState([]);
    // Addresses Form
    const [address,setAddress] = useState([]);
    // Advanced Form
    const [customerAccountNo,setCustomerAccountNo] = useState()
    const [customerVatNo,setCustomerVatNo] = useState()
    const [limitCredit,setLimitCredit] = useState();


    const formValidate = () => {
        let errorObj = {};

        if(!fullname[currentLangId]){
            errorObj = { ...errorObj, [`fullname.${currentLangId}`] : 'يجب ادخال اسم العميل'};
            
        }

        if(isNaN(mobileNo)){
            errorObj.mobileNo = 'رقم الجوال يجب ان يكون ارقام';
        }
        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            console.log('errors',errors)
            return true
        }
        return false;
    }
    const saveHandler = async() => { 
        // e.preventDefault();
        if(formValidate()) {
            return  ;
        }
        const _data={
            fullname: objLangTextHandler(fullname),
            vendorNo:customerNo,
            mobileNo,
            phoneNo,
            email,
            notes:customerNotes,
            limitCredit,
            accountNo:customerAccountNo,
            vatNo:customerVatNo,
            vendorGroupsIds: groupSelected.map(el => el.value),
            responsibles:responsibles,
            vendorAddresses:address,
            isActive,
            tenantId:currentTenantId,
            companyId:currentCompanyId,
            branchId:currentBranchId
        }

        postData(`${process.env.REACT_APP_VENDORS_API_BASE_URL}/`,_data)

        
    };
    const cancelHandle = () => {
        onHide();
    }

    useEffect(()=>{
        if(resultPost){
            console.log('added',resultPost)
            onHide ? cancelHandle() : setMode('List');
            onVendorCreated();
        }
    },[resultPost]);
    
    return (
        <div className={`${onHide && 'p-5'}`} >
            <CardHeadeWithActions title={`أضف مورد جديد`}>
                { onHide && <MwButton inGroup={true}  type='cancelBtn' onClick={cancelHandle}>الغاء</MwButton> }
                <MwButton inGroup={true} type='cancelBtn' onClick={() => setMode('List')}  >
                    ادارة الموردين
                </MwButton>
                    <MwButton actionType={`button`} inGroup={true} onClick={saveHandler}   type='saveBtn' >
                    حفظ 
                    </MwButton>
            </CardHeadeWithActions>
            <form  >
                <div className=' bg-slate-50 rounded-lg text-xs p-3 flex flex-col gap-1'>

                    <ul className='flex gap-3 items-centers'>
                        <li onClick ={()=>setTabActive('TabOne')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabOne' ? 'bg-indigo-100 font-bold' : 'bg-slate-100'}`}>المعلومات الاساسية</li>
                        <li onClick ={()=>setTabActive('TabTwo')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabTwo' ? 'bg-indigo-100 font-bold': 'bg-slate-100'}`} >بيانات المسئول</li>
                        <li onClick ={()=>setTabActive('TabThree')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabThree' ? 'bg-indigo-100 font-bold': 'bg-slate-100'}`} >العناوين</li>
                        <li onClick ={()=>setTabActive('TabFour')} className={`cursor-default rounded px-2 py-1 hover:font-bold ${tabActive==='TabFour' ? 'bg-indigo-100 font-bold': 'bg-slate-100'}`} >بيانات متقدمة</li>
                    </ul>
                    {
                        tabActive === 'TabOne' && 
                        <BasicInfoForm
                            currentTenantId = {currentTenantId} 
                            currentCompanyId = {currentCompanyId}
                            currentBranchId = {currentBranchId}
                            currentLangId = {currentLangId}
                            currentLangList = {currentLangList}
                            errors = {errors}
                            customerNo = {customerNo}
                            setCustomerNo = {setCustomerNo}
                            groupSelected = {groupSelected}
                            setGroupSelected = {setGroupSelected}
                            fullname = {fullname}
                            setFullname = {setFullname}
                            mobileNo = {mobileNo}
                            setMobileNo = {setMobileNo}
                            phoneNo = {phoneNo}
                            setPhoneNo = {setPhoneNo}
                            email  = {email}
                            setEmail = {setEmail}
                            customerNotes = {customerNotes}
                            setCustomerNote = {setCustomerNote}
                            isActive = {isActive}
                            setIsActive = {setIsActive}
                            /> 
                    }
                    {
                        tabActive === 'TabTwo' &&
                        <ResponsiblesForm
                            currentLangId = {currentLangId}
                            currentLangList = {currentLangList}
                            errors = {errors}
                            responsibles = {responsibles}
                            setResponsibles = {setResponsibles}
                            />
                    }
                    {
                        tabActive === 'TabThree' &&
                        <AddressForm 
                            errors ={errors}
                            address = {address}
                            setAddress = {setAddress}
                            />
                    }
                    {
                        tabActive === 'TabFour' &&
                        <AdvancedForm
                            errors = {errors}
                            customerAccountNo = {customerAccountNo}
                            setCustomerAccountNo = {setCustomerAccountNo}
                            customerVatNo = {customerVatNo}
                            setCustomerVatNo = {setCustomerVatNo}
                            limitCredit = {limitCredit}
                            setLimitCredit = {setLimitCredit}
                            />
                    }
                </div>
            </form>
        </div>
    )
}

export default CreateVendor
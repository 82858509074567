import { createSlice } from "@reduxjs/toolkit";
import { tenant } from "../utils/global";



const initialState = {
    name: tenant()?.companies[0].branches[0]?.name[0].text || 'Select a Branch',
    value: tenant()?.companies[0].branches[0]?._id,
}

const currentBranchSlice = createSlice({
    name: 'currentBranch',
    initialState,
    reducers: {
        setCurrentBranch:(state,action)=> {
            state.name = action.payload.name;
            state.value = action.payload.value;
        }
    }
});

export const {setCurrentBranch} = currentBranchSlice.actions;
export default currentBranchSlice.reducer
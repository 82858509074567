
import { configureStore } from '@reduxjs/toolkit';
import langReducer from './langSlice';
import currentCompanyReducer from './currentCompanySlice';
import currentBranchReducer from './currentBranchSlice';
import authReducer from './authSlice';
import settingReducer from './settingSlice';

export const store = configureStore({
        reducer:{
            lang: langReducer,
            currentCompany:currentCompanyReducer,
            currentBranch:currentBranchReducer,
            auth:authReducer,
            settings:settingReducer
        }
    });


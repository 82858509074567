import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from 'chart.js';
import {Chart, Line} from 'react-chartjs-2'
import { MwSpinnerButton } from '../ui';

ChartJS.register(
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
)

const LineChart = ({datasetData,loading,className}) => {
    const data = {
        labels: ['يناير', 'فبراير', 'مارس', 'ابريل','مايو', 'يونيو', 'يوليو', 'اغسطس', 'سمبتمبر', 'اكتوبر','نوفمبر', 'ديسمبر'],
        datasets:[
            {
                label:'مشتريات السنة الحالية' ,
                data:datasetData,
                borderColor: 'gray',
                backgroundColor: 'indigo',
                tension:0.4,

            }
        ]
    }
    const options = {
        plugins: {
            // defaultFontFamily:'"Tajawal", sans-serif',
            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 14,
                        family: '"Tajawal", sans-serif',
                    }
                }
            }
        }
    }

    return (
        <div className='rounded-lg bg-slate-50  flex-1 border-2 border-slate-50 flex items-center justify-center md:px-12 '>
            { !loading ? <Line className={` ${className}`}
            data={data}
            options={options}
            >
            </Line> :  <MwSpinnerButton withLabel={false} isFullCenter={true} />}
        </div>
            
    )
}

export default LineChart
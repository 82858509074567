import { useState,useEffect} from 'react'
import { useCurrent, useFetch, usePut } from '../../../hooks';
import {  MwButton, MwSpinner} from '../../ui';
import {langObjType,objLangTextHandler,LangArrayToObjKeyValue} from '../../../utils/global'
import CategoryForm from './CategoryForm';
import CardHeadeWithActions from '../../ui/CardHeadeWithActions';

const EditCategory = ({id,setMode}) => {
   // let categoriesHandler = [];
    // public state
    const {currentLangList,currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const [formLang,setFormLang] = useState(currentLangId);
    const [errors,setErrors] = useState({});
    const [categories,setCategories] = useState([]);
    const categoryClasses = [
        {label: 'منتج' , value:'product'},
        {label: 'خدمة' , value:'service'},
    ];
    
    const {data:dataFetchCategory,loading:loadingFetchCategory} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/categories/show/${id}`);
    const {data:resultPut,loading:loadingPost,error:errorPost,putData} = usePut();
    const {data:dataFetchResponse,loading,error} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/categories/${currentTenantId}/${currentCompanyId}`);
    
    const [loadingPage,setLoadingPage] = useState(true);
    const [categoryParentLabel,setCategoryParentLabel] = useState('اختار التصنيف الرئيسي');
    const [categoryClassLabel,setCategoryClassLabel] = useState('اختار فئة التصنيف ');

    // Start Form state //////
    const [categoryParent,setCategoryParent] = useState('main');
    const [categoryClass,setCategoryClass] = useState();
    const [isActive,setIsActive] = useState(true);
    const [isShowOnWebsite,setIsShowOnWebsite] = useState(true);
    const [categoryName,setCategoryName] = useState(langObjType());
    const [categoryDesc,setCategoryDesc] = useState(langObjType());
    const [categoryMetaKeywords,setCategoryMetaKeywords] = useState(langObjType());
    const [categoryMetaDesc,setCategoryMetaDesc] = useState(langObjType());

    // TODO: Functions
    const getCategory = () =>{
        let cats = [];
        dataFetchResponse.map((e) => {
            //const name = LangArrayToObjKeyValue(e.categoryName);
            if(e._id !== id)
                e.categoryName.forEach(name => {
                    cats.push( {label:name?.text,value:e._id,lang:name.lang})
                });
        });
        setCategories(cats);
    }

    const formValidate = () => {
        let errorObj = {};
        setErrors('');
        if(categoryParent==='sub'){
            errorObj = { ...errorObj, categoryParent : 'يجب اختيار التصنيف الفرعي' };
        }
        if(!categoryClass){
            errorObj = { ...errorObj, categoryClass : 'يجب اختيار فئة التصنيف ' };
        }
        if(!categoryName[currentLangId]){
            errorObj = { ...errorObj, [`categoryName.${currentLangId}`] : 'يجب ادخال اسم التصنيف'};
            setFormLang(currentLangId)
        }

        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            return true
        }

        return false;
    }
    const submitHandler = () => {        
        const _data = {
            categoryParent:categoryParent === 'main' ? null : categoryParent.value  ,
            categoryClass,
            categoryName: objLangTextHandler(categoryName),
            categoryDesc: objLangTextHandler(categoryDesc),
            categoryMetaKeywords: objLangTextHandler(categoryMetaKeywords),
            categoryMetaDesc: objLangTextHandler(categoryMetaDesc),
            isActive,
            isShowOnWebsite,
            tenantId:currentTenantId,
            companyId:currentCompanyId
        }
        
        if(formValidate()) return ;
        putData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/categories/update/${id}`,_data);
        
    }
    // TODO: useEffect
    useEffect(() =>{
        if(dataFetchResponse){
            getCategory();
        }
    },[dataFetchResponse]);

    useEffect(() =>{
        if(resultPut){
            setMode('List');
        }
    },[resultPut])

    useEffect(() =>{
        if(dataFetchCategory){
            console.log(dataFetchCategory);
            if( dataFetchCategory.categoryParent === null ) {
                    setCategoryParent('main') 
            } else{
                dataFetchCategory.categoryParent.categoryName.forEach(name => {
                    if(currentLangId === name.lang)
                    setCategoryParent( {label:name?.text,value:dataFetchCategory.categoryParent._id,lang:name.lang})
                });
            }
            const labelClass = () => {
                if(dataFetchCategory.categoryClass === 'product' ){
                    return 'منتج'
                }else if(dataFetchCategory.categoryClass === 'service' ){
                    return 'خدمة'
                }else{
                    return 'اخري'
                }
            } 
            setCategoryClassLabel(labelClass());
            setCategoryClass(dataFetchCategory.categoryClass);
            setCategoryName(LangArrayToObjKeyValue(dataFetchCategory.categoryName))
            setCategoryDesc(LangArrayToObjKeyValue(dataFetchCategory.categoryDesc))
            setCategoryMetaKeywords(LangArrayToObjKeyValue(dataFetchCategory.categoryMetaKeywords))
            setCategoryMetaDesc(LangArrayToObjKeyValue(dataFetchCategory.categoryMetaDesc))
            setIsShowOnWebsite(dataFetchCategory.isShowOnWebsite)
            setIsActive(dataFetchCategory.isActive)
            setLoadingPage(false)
        }
    },[dataFetchCategory]);
    

    return (
        <>
            {dataFetchCategory && !loadingPage ? <div className='flex flex-col gap-5 px-3 text-slate-500 text-sm' >
                    <CardHeadeWithActions title={`تعديل تصنيف`}>
                            <MwButton type="cancelBtn" inGroup={true} onClick = {()=>setMode('List')} >ادارة التصنيفات</MwButton>
                            <MwButton type="saveBtn" inGroup={true} onClick={submitHandler} >حفظ</MwButton>
                    </CardHeadeWithActions>
                    <CategoryForm
                        categories = {categories}
                        currentLangList = {currentLangList}
                        formLang = {formLang}
                        setFormLang = {setFormLang}
                        errors = {errors}
                        categoryParent = {categoryParent}
                        setCategoryParent = {setCategoryParent}
                        categoryClasses = {categoryClasses}
                        setCategoryClass = {setCategoryClass}
                        categoryName = {categoryName}
                        setCategoryName = {setCategoryName}
                        categoryDesc = {categoryDesc}
                        setCategoryDesc = {setCategoryDesc}
                        categoryMetaKeywords = {categoryMetaKeywords}
                        setCategoryMetaKeywords = {setCategoryMetaKeywords}
                        categoryMetaDesc = {categoryMetaDesc}
                        setCategoryMetaDesc = {setCategoryMetaDesc}
                        isShowOnWebsite = {isShowOnWebsite}
                        setIsShowOnWebsite = {setIsShowOnWebsite}
                        isActive = {isActive}
                        setIsActive = {setIsActive}
                    />
            </div> : <MwSpinner/>
            }
        </>
    )
}

export default EditCategory
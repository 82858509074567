import FormLangBar from '../../FormLangBar';
import { MwInputText,MwTextArea,MwSwitch } from '../../ui';

const BrandForm = ({
    currentLangList,
    formLang,
    setFormLang,
    errors,
    brandName,
    setBrandName,
    brandDesc,
    setBrandDesc,
    brandMetaKeywords,
    setBrandMetaKeywords,
    isShowOnWebsite,
    setIsShowOnWebsite,
    isActive,
    setIsActive
}) => {
    return (
        <form>
        <div id='MultiLangBlock' className='flex flex-col'>
        <FormLangBar currentLangList = {currentLangList} formLang = {formLang} setFormLang = {setFormLang} />
            { currentLangList.map((lang) => (
                            <div key={lang.langCode} className=''>
                                <div className={` ${ formLang === lang?.langCode ? 'block' : 'hidden' } `}>
                                    <div id='brandName' className=' '>
                                    <MwInputText
                                        label={`اسم الماركة (${lang?.langName})`}
                                        id={`brandName${lang?.langCode}`} 
                                        value={brandName[lang?.langCode]}
                                        invalid={!!errors[`brandName.${lang?.langCode}`] }
                                        invalidMsg = {errors[`brandName.${lang?.langCode}`]}
                                        onChange={(e)=>{
                                            setBrandName({...brandName, [lang?.langCode]:e.target.value});
                                        }}
                                    />
                                    </div>
                                    <div id='brandDesc' className='my-2'>
                                        <MwTextArea
                                            id={`brandDesc${lang?.langCode}`}
                                            value={brandDesc[lang?.langCode]}
                                            placeholder={`وصف الماركة (${lang.langName}) `}
                                            rows={4}
                                            onChange={(e ) => setBrandDesc({...brandDesc,[lang?.langCode]:e.target.value})}
                                        />
                                    </div>
                                    <div id='brandMetaKeywords' className='my-2'>
                                        <MwTextArea
                                            id={`brandMetaKeywords${lang?.langCode}`}
                                            value={brandMetaKeywords[lang?.langCode]}
                                            placeholder={` الكلمات الدليلة SEO (${lang.langName}) `}
                                            rows={4}
                                            onChange={(e ) => setBrandMetaKeywords({...brandMetaKeywords,[lang?.langCode]:e.target.value})}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        )) }
        </div>
        <div id='OptionsBock' className='flex flex-col justify-between pb-2 px-1'>
            <div className='flex justify-between items-center py-4  border-b mb-2'>
                <label className='text-xs text-slate-400'>  يظهر / لا يظهر (علي الموقع الالكتروني) </label>
                <MwSwitch custKey='isActive' isActive={isShowOnWebsite} setIsActive={setIsShowOnWebsite} onChangeSwitch={()=> setIsShowOnWebsite(!isShowOnWebsite)} />
            </div>
            <div className='flex justify-between items-center py-4  border-b mb-2'>
                <label className='text-xs text-slate-400'>مفعل / غير مفعل</label>
                <MwSwitch custKey='isActive' isActive={isActive} setIsActive={setIsActive} onChangeSwitch={() => setIsActive(!isActive)} />
            </div>
        </div>
    </form>
    )
}

export default BrandForm
import React, { useState } from 'react'
import { AiOutlinePrinter } from 'react-icons/ai';
import { MdOutlinePaid } from 'react-icons/md';
import { AiOutlineEdit } from 'react-icons/ai';
import {  useTranslation } from 'react-i18next';


import { BasicSelector, MwButton, MwModal } from '../../ui';
import { useNavigate, useParams } from 'react-router-dom';
import NotHaveInPackage from '../../NotHaveInPackage';


const InvoiceNavbar = ({handlePrint,deleteHandler,isPaid,isReturned,fullReturned,countReturned,showpaymentModal,setShowPaymentModal,invId}) => {
    const [t] = useTranslation('global')
    const navigate = useNavigate();
    const params = useParams();
    const [showSendMailModal,setShowSendMailModal] = useState(false);
    const onSelectSendInvoiceToCustomer = (value) => {
       // console.log(value)
        setShowSendMailModal(true);
    }
    return (
        <>
            <MwModal onShow={showSendMailModal} setOnShow={setShowSendMailModal}  >
                <NotHaveInPackage/>
            </MwModal>
            <div className='flex py-2 px-1 justify-between  '>
                    <div className='flex'>
                        <MwButton 
                            size = 'sm'
                            inGroup = {true}
                            type = 'secondary' 
                            onClick={handlePrint}>
                                <AiOutlinePrinter size={16}/> طباعة
                        </MwButton>

                        {/* <MwButton 
                            size = 'sm'
                            inGroup = {true}
                            type = 'secondary-inline-end' 
                            onClick={()=>{
                                navigate(`/${params.tenant}/invoices/edit/${params.id}`)
                            }}>
                                <AiOutlineEdit size={16}/> تعديل 
                        </MwButton>
                        <MwButton 
                            size = 'sm'
                            inGroup = {true}
                            type = 'deleteBtn-inline' 
                            onClick={  deleteHandler }>
                                <AiOutlinePrinter size={16}/> حذف 
                        </MwButton> */}
                        { 
                            isPaid && !fullReturned &&
                                <MwButton 
                                    disabled = {!isPaid}
                                    size = 'sm'
                                    inGroup = {true}
                                    type = 'secondary-inline-end' 
                                    onClick={ () => { navigate(`/${params.tenant}/invoices-returns/${invId}`) } }>
                                        انشاء فاتورة مرتجعة
                                </MwButton>
                        }
                        {
                            !isPaid &&  
                                <MwButton 
                                    disabled = {isPaid}
                                    size = 'sm'
                                    inGroup = {true}
                                    type = 'saveBtn' 
                                    onClick={ () => setShowPaymentModal(!showpaymentModal) }>
                                        <MdOutlinePaid size={16}/> {t('invoice.PayInvoice')}
                                </MwButton>
                        }
                    </div>
                    <div className='flex items-center justify-center gap-1  text-xs'>
                        <BasicSelector
                            className={`rounded-xl text-slate-500`}
                                label={`ارسال نسخة للعميل pdf`}
                                onSelected={onSelectSendInvoiceToCustomer}
                                listItem={[
                                    {
                                        label:'بريد الكتروني',
                                        value:'sendMail'
                                    },
                                    {
                                        label:' رسالة نصية',
                                        value:'sendSms'
                                    }
                                ]}
                            />
                        {isPaid ? <div className='text-green-300 px-3 py-1  border border-green-300 rounded-xl'>{t('invoice.PAID')}</div> : <div className='text-red-300 px-3 py-1 border border-red-300 '>{t('invoice.UNPAID')}</div>}
                        {isReturned && countReturned > 0 && 
                            <div className='text-orange-300 px-3 py-1  border border-orange-300 rounded-xl'> 
                            
                            { fullReturned ? <span>فاتورة مرتجعة كليا</span> :  <span>فاتورة مرتجعة جزئيا</span>}
                            <span> [{countReturned}] </span>
                            </div> }
                    </div>
                </div>
        </>
    )
}

export default InvoiceNavbar
import React from 'react'

const MwSpinner = ({hightScreen=true}) => {
    return (
        <div className={`flex flex-col  justify-center text-center items-center  w-full ${hightScreen ? ' h-screen' : 'h-full' }`} >
            <span className="relative flex  h-6 w-6">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-6 w-6 bg-sky-500"></span>
            </span>
        </div>
    )
}

export default MwSpinner
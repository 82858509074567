import React from 'react'
import {AiFillCloseCircle} from 'react-icons/ai'

const TagListItem = ({tag,removeTagHandler}) => {
    return (
        <div 
            className='flex cursor-default bg-slate-300 mx-1 px-2 py-1 text-white rounded-lg'>
            <span>{tag}</span>
            <AiFillCloseCircle onClick={()=>removeTagHandler(tag)}/>
        </div>
    )
}

export default TagListItem
import React,{useEffect, useState} from 'react'
import {BiChevronDown} from 'react-icons/bi'
import {AiOutlineSearch} from 'react-icons/ai'
import {MdAddBox} from 'react-icons/md'
import CustomerSelectorItem from '../CustomerSelectorItem';
import { useCurrent } from '../../hooks';
import {LangArrayToObjKeyValue} from '../../utils/global';

const MwSelector = ({label,_data,dataType,onClickNew,setSelectedItem,selectedItem,initalValue='Choose',withLabel=true,withAddNew=true,className,selectFirstValue='false',disabled=false,withSearch=true}) => {
    const [inputValue,setInputValue] = useState('');
    const {currentLangId} = useCurrent()
    const [open,setOpen] = useState(false);

    const onSelectedHandler = (item) => {
        setSelectedItem({...item,label:item.label})
        setOpen(false);
        setInputValue("")
    }

    
    const dataList = _data &&  _data?.map((item) => {
        if(dataType === 'customer' || dataType === 'salesman'){
            return <CustomerSelectorItem 
                        key={item._id}
                        item={item} 
                        setSelectedItem={setSelectedItem} 
                        setOpen={setOpen}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                        />
        }
        if(dataType === 'labelValue' ){
            if(item.lang === currentLangId )
                return <div 
                            className={`px-4 py-1 border-t cursor-default hover:bg-indigo-50 hover:font-bold  ${item?.label?.toLowerCase().startsWith(inputValue) ? 'block' : 'hidden'}`}
                            key={item.value}
                            onClick={()=>onSelectedHandler(item)}
                            >
                            {item.label}
                        </div>
        }

    })

    useEffect(() => {
        if(selectFirstValue === 'true' && _data[0]?.fullname ){
            if(dataType === 'customer' || dataType === 'salesman'){
                const name = LangArrayToObjKeyValue(_data[0]?.fullname)?.[currentLangId] || _data[0].fullname[0].text 
                setSelectedItem({label:name,value:_data[0]?._id , lang:currentLangId})
              //  console.log(`Selected`,selectedItem)
                
            }
        }
    },[]);

    // useEffect(() => {
    //     console.log(`Selected`,selectedItem)
    // },[selectedItem]);
    return (
        <>
            <div className={` z-10 ${className}`}>
                <label className='text-xs text-slate-400'> {label} </label>
                <div
                    onClick={()=> !disabled && setOpen(!open)}
                    className={`cursor-default flex text-xs bg-white  w-full h-8 px-2 items-center justify-between rounded ${ disabled ? ' text-slate-400 ': 'text-slate-500'}`} >
                        {selectedItem && selectedItem !== 'sub'  ? selectedItem.label : initalValue}
                        <BiChevronDown size={20}/>
                </div>
                <ul className={`bg-slate-100 start-0  overflow-y-auto absolute w-full  rounded-b-lg   ${open ? 'max-h-60 block' : 'max-h-0 hidden'}`}>
                    
                    {withSearch &&
                        <div className='sticky  top-0 p-3 bg-slate-100'>
                            { 
                            withAddNew && <div className='text-end'>
                                <div className='text-sky-700 absolute top-4 end-4'  
                                onClick={()=>{ 
                                    onClickNew()
                                    setOpen(false) 
                                }}
                                > <MdAddBox size={24}/> </div>
                            </div>
                            }
                            <div className='flex items-center justify-between  px-2 bg-white rounded '>
                                <AiOutlineSearch size={18} className='text-gray-600' />
                                <input 
                                    type="text"
                                    placeholder= {label}
                                    className='placeholder:text-gray-400 p-2 outline-none text-xs p-2 w-full bg-white'
                                    value={inputValue}
                                    onChange={(e)=>setInputValue(e.target.value.toLowerCase())}
                                    />
                            </div>
                        </div>
                    }
                    <div className='mb-3 '>
                        {dataList}
                    </div>
                </ul>
            </div>
        </>
    )
}

export default MwSelector
import { useParams } from "react-router-dom";
import InvFormUpdate from "../../components/quote/edit/InvFormUpdate";
import { useCurrent, useFetch } from "../../hooks";
import withGuard from "../../utils/withGuard";
import { MwSpinner } from "../../components/ui";


const EditQuote = () => {
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const params = useParams();
    const invID = params.id;
    const {data,loading,error} = useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/quotes/show/${params.id}`)
    if(!invID) return ;

    console.log('data',data);

    return (
        <div className="  w-full h-full">
            {!loading && !error && data ? <InvFormUpdate
                tenantId = {currentTenantId}
                tenantUsername ={params.tenant}
                companySelected = {currentCompanyId}
                branchSelected = {currentBranchId}
                invData = {data}
            /> : <MwSpinner className='flex  items-center justify-center'/>
            }
        </div>
    )
}

export default withGuard(EditQuote)
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { MwSpinnerButton, NoDataFound } from '../ui'
import { useInvoice } from '../../hooks';

const InvListBasic = ({data,loading=true, isSummary=false}) => {
    const params = useParams();
    const {formatter} = useInvoice();
    return (
        <>
            {!loading ? 
            <div className={ `${!isSummary && 'h-[calc(100vh-250px)]' }`}>
                { data.length > 0 ? <ul>
                    <li className='flex justify-between p-2 text-xs border-b'>
                        <div className='w-24 px-1 text-center'>رقم العرض </div>
                        <div className='flex-1'>اسم العميل</div>
                        <div className='w-28 px-1  text-center'>المبلغ </div>
                        <div className='w-24 px-1  text-center'>التاريخ </div>
                    </li>
                    {data.map((result) => (
                        <Link  to={`/${params.tenant}/quotes/show/${result.id}/${result.company_id}/${result.branch_id}`} key={result.id}>
                            <li className=' flex justify-between p-2 text-xs border-b hover:bg-indigo-50 hover:font-bold'>
                                <div className='w-24 px-1  text-center'>{result.inv_no}</div>
                                <div className='flex-1'>{result.customer_name || 'عميل عام'}</div>
                                <div className='w-28 px-1  text-center'>{ formatter.format(result.net_amount)} </div>
                                <div className='w-24 px-1  text-center'>{result.inv_date.substring(0,10)}</div>
                            </li>
                        </Link>
                    ))}
                </ul> :  <NoDataFound msg='لا توجد عروض اسعار ' />  }
            </div> : <MwSpinnerButton withLabel={false} isFullCenter={true} />}
        </>
    )
}

export default InvListBasic
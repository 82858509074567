import React from 'react'

const LogoEchoSystem = ({name}) => {
  return (
    <div className=' flex-1 flex   justify-center items-center  '>
                        <div className='  secondary-color s text-xs font-bold '>أبسط</div>
                        <img src={`/logo-dark.webp`} className='w-10' title='Back to ABSAT applications' alt='Back to ABSAT applications'  />
                        <div className=' secondary-color  text-xs font-bold '>{name}</div>
                    </div>
  )
}

export default LogoEchoSystem

import InvForm from "../../components/quote/add/InvForm";

import withGuard from "../../utils/withGuard";

const CreateQuote = ({companySelected,branchSelected,tenantId,tenantUsername}) => {
    return (
        <div>
            <InvForm
                tenantId = {tenantId}
                tenantUsername ={tenantUsername}
                companySelected = {companySelected}
                branchSelected = {branchSelected}
            />
        </div>
    )
}

export default withGuard(CreateQuote)
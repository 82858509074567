import {useEffect, useRef, useState} from 'react'
import { usePut,useCurrent } from '../../hooks'
import { MwSpinnerButton,MwButton, MwInputText, MwTextArea } from '../../components/ui';
import FormLangBar from '../../components/FormLangBar';

const MyCompany = ({refreshTenant}) => {
    const {currentLangList,currentCompany,currentCompanyId,currentTenantId,currentLangId} = useCurrent();
    const {data:dataPutResult,loading,error,putData} = usePut();

    const [formLang,setFormLang] = useState(currentLangId);

    // form state controller
    const [nameCompany,setNameCompany] = useState({text:'',lang:'en'});
    const [desCompany,setDescCompany] = useState({text:'',lang:'en'});
    const [mainAddress,setMainAddress] = useState({text:'',lang:'en'});
    const commercialRegisterationRef = useRef();
    const vatNumberRef = useRef();
    const logoRef = useRef();
    const mainPhoneRef = useRef();
    const mainEmailRef = useRef();
    const websiteUrlRef = useRef();

    const ArrayToObj = (array) => {
        return array.reduce( (obj,item) => {
            if(item.text){
                obj[item.lang] = item.text;
                return obj;
            }
        },{});
    }

    const objToArray = (obj) => {
            const array =   Object.entries(obj).map(([key, value]) => ({ lang:key , text: value } ) );
            return array.filter(a => a.lang !== "undefined" && a.lang )
    }

    const init = () => {
        const nameHandler = ArrayToObj(currentCompany.name)
        const descHandler = ArrayToObj(currentCompany.desc)
        const addressHandler = ArrayToObj(currentCompany.mainAddress);
        setNameCompany(nameHandler)
        setDescCompany(descHandler)
        setMainAddress(addressHandler);
        commercialRegisterationRef.current.value = currentCompany?.commercialRegisteration || '';
        vatNumberRef.current.value = currentCompany?.vatNumber || '';
        logoRef.current.value = currentCompany?.logo || '';
        mainPhoneRef.current.value = currentCompany?.mainPhone || '';
        mainEmailRef.current.value = currentCompany?.mainEmail || '';
        websiteUrlRef.current.value = currentCompany?.websiteUrl || '';

    }

    useEffect(() => {
        init();
        console.log(currentCompany);

    },[]);
    

    const submitHandler = (e) =>{
        e.preventDefault();
        const _data = {
            companyId:currentCompanyId,
            companyData:{
                commercialRegisteration:commercialRegisterationRef.current.value,
                desc:objToArray(desCompany) ,
                logo:logoRef.current.value ,
                mainAddress:objToArray(mainAddress) ,
                mainEmail:mainEmailRef.current.value ,
                mainPhone:mainPhoneRef.current.value ,
                name:objToArray(nameCompany),
                vatNumber:vatNumberRef.current.value ,
                websiteUrl:websiteUrlRef.current.value
            }
        }
        console.log('_data',_data);
        putData(`${process.env.REACT_APP_Auth_API_BASE_URL}/tenants/companies/${currentTenantId}`,_data)
        //refreshTenant();
        console.log("nameCompany",nameCompany,objToArray(nameCompany));
        
    }
    useEffect(()=>{
        if(dataPutResult && !loading){
            refreshTenant()
        }
    },[dataPutResult]);
    return (
        <>
            <div>
                <div className='flex  justify-between items-center gap-2 text-xs text-slate-500 font-bold px-1 '>
                    <div>
                        إعدادات الشركة : {nameCompany[currentLangId]}
                    </div>
                    { loading ? <MwSpinnerButton/> : <MwButton 
                                    inGroup = {true}
                                    size='sm'
                                    onClick={submitHandler}
                                    type = 'saveBtn'  > حفظ التغيرات
                                    </MwButton> }
                </div>
                <div className='p-3 my-3  rounded-lg border border-slate-200'>
                    <form onSubmit={submitHandler}>
                        <FormLangBar formLang={formLang} setFormLang={setFormLang} currentLangList = {currentLangList}  />
                        <div className='flex flex-col gap-3 py-2  md:flex-row md:gap-0  '>
                            { currentLangList.map( lang => {
                                return  ( 
                                    <div  key={lang._id} className={` w-full  ${formLang === lang.langCode ? 'block' : 'hidden'} `} >
                                        <MwInputText
                                            label = 'اسم الشركة'
                                            value={nameCompany[lang?.langCode]}
                                            onChange={(e)=>setNameCompany({...nameCompany, [lang.langCode]:e.target.value })}
                                        />
                                        <MwInputText
                                            label = 'وصف نشاط الشركة'
                                            value={desCompany[lang?.langCode]}
                                            onChange={(e)=>setDescCompany({...desCompany, [lang.langCode]:e.target.value })}
                                        />
                                        <MwTextArea
                                            label='عنوان الشركة'
                                            placeholder="Enter payment method description"
                                            value={mainAddress[lang.langCode]}
                                            onChange={(e)=>setMainAddress({...mainAddress, [lang.langCode]:e.target.value })}
                                        />
                                    </div>
                                )})}
                        </div>
                        <MwInputText
                            label = 'رقم السجل التجاري'
                            inputRef = {commercialRegisterationRef}
                        />
                        <MwInputText
                            label = 'الرقم الضريبي'
                            inputRef = {vatNumberRef}
                        />
                        
                        <div className='flex justify-between items-center gap-2'>
                            <div className='flex-1'>
                                <MwInputText
                                label = 'رابط شعار الشركة'
                                inputRef = {logoRef}
                                />
                            </div>
                            {logoRef &&<div className='m-2 flex items-center justify-center'>
                                <img src={logoRef?.current?.value} width={90} />
                            </div>}
                        </div>
                        <MwInputText
                            label = 'رقم الهاتف'
                            inputRef = {mainPhoneRef}
                                />
                        <MwInputText
                            label = 'البريد الالكتروني'
                            inputRef = {mainEmailRef}
                        />
                        <MwInputText
                            label = 'رابط الموقع الالكتروني'
                            inputRef = {websiteUrlRef}
                        />
                    </form>
                </div>
            </div>
        </>
    )
}

export default MyCompany
import {useState,useEffect} from 'react'
import { useCurrent, usePost } from '../../../hooks';
import {langObjType,objLangTextHandler} from '../../../utils/global'
import { MwButton } from '../../ui';
import BrandForm from './BrandForm';
import CardHeadeWithActions from '../../ui/CardHeadeWithActions';


const CreateBrand = ({setMode}) => {
    const {currentLangList,currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const [formLang,setFormLang] = useState(currentLangId);
    const [errors,setErrors] = useState({});

    const {data:resultPost,loading:loadingPost,error:errorPost,postData} = usePost();

    // Start Form state //////
    const [isActive,setIsActive] = useState(true);
    const [isShowOnWebsite,setIsShowOnWebsite] = useState(false);
    const [brandName,setBrandName] = useState(langObjType());
    const [brandDesc,setBrandDesc] = useState(langObjType());
    const [brandMetaKeywords,setBrandMetaKeywords] = useState(langObjType());

    const formValidate = () => {
        let errorObj = {};
        setErrors('');
        if(!brandName[currentLangId]){
            errorObj = { ...errorObj, [`brandName.${currentLangId}`] : 'يجب ادخال اسم الماركة'};
            setFormLang(currentLangId)
        }

        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            return true
        }
        return false;
    }

    const submitHandler = () => {
       // e.preventDefault();
        
        const _data = {
            brandName: objLangTextHandler(brandName),
            brandDesc: objLangTextHandler(brandDesc),
            brandMetaKeywords: objLangTextHandler(brandMetaKeywords),
            isActive,
            isShowOnWebsite,
            tenantId:currentTenantId,
            companyId:currentCompanyId
        }
        
        if(formValidate()) return ;
        postData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/brands`,_data);
        setMode('List');
    }

    useEffect(() =>{
        if(resultPost){
            console.log(resultPost);
        }
    },[resultPost])

    return (
        <>
            <div className='flex flex-col gap-5 px-3 text-slate-500 text-sm' >
                <CardHeadeWithActions title={`اضف ماركة جديدة`}>
                        <MwButton type="cancelBtn" inGroup={true} onClick = {()=>setMode('List')} >ادارة الماركات</MwButton>
                        <MwButton type="saveBtn" inGroup={true} onClick={submitHandler} >حفظ</MwButton>
                </CardHeadeWithActions>
                <BrandForm
                        currentLangList = {currentLangList}
                        formLang = {formLang}
                        setFormLang = {setFormLang}
                        errors = {errors}
                        brandName = {brandName}
                        setBrandName = {setBrandName}
                        brandDesc = {brandDesc}
                        setBrandDesc = {setBrandDesc}
                        brandMetaKeywords = {brandMetaKeywords}
                        setBrandMetaKeywords = {setBrandMetaKeywords}
                        isShowOnWebsite = {isShowOnWebsite}
                        setIsShowOnWebsite = {setIsShowOnWebsite}
                        isActive = {isActive}
                        setIsActive  = {setIsActive}
                />
            </div>
        </>
    )

}

export default CreateBrand
import  { useEffect, useState } from 'react'
import axios from 'axios';
import InvListBasic from './InvListBasic';
import { useCurrent } from "../../hooks";

const InvListWidget = () => {

    const [invoices,setInvoices] = useState();
    const [loading,setLoading] = useState(true);
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();

    const invoicesHandler = async () => {
        const response = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
        setInvoices(response?.data.data);
        setLoading(false);
        
    }

    useEffect(() => {
        invoicesHandler();
    },[]);

    return (
        <div className='rounded-lg bg-slate-50 h-60 overflow-y-auto'>
            <h5 className='ps-5 pt-3 pb-2 text-slate-500 text-sm  font-bold' >احدث الفواتير</h5>
            <InvListBasic withBorder={false} withSummary={true} loading={loading} data={invoices}/>
        </div>
    )
}

export default InvListWidget
import { useState } from 'react';
import axios from 'axios';

const usePost = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const postData = async (url, payload) => {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post(url, payload,{
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8',
                                'Authorization': `Bearer ${token}` 
                            }
                        });
            setData(response.data);
            setLoading(false);
            console.log('Post Request:',`URL:` ,url, `PAYLOAD:`,payload,`RESPONE:`,response.data);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    return { data, loading, error, postData };
};

export default usePost;
import { useState } from 'react';
import ListProducts from '../../components/Products/ListProducts';
import CreateProduct from '../../components/Products/CreateProduct';
import EditProduct from '../../components/Products/EditProduct';


const Products = () => {
    const [mode,setMode] = useState('List');
    const [idSelected,setIdSelected] = useState();

    return (
        <>
            <div className="p-3">
                { mode === 'List' && <ListProducts idSelected = {idSelected} setIdSelected = {setIdSelected} setMode={setMode} /> }
                { mode === 'Add' && <CreateProduct setMode={setMode} /> }
                { (mode === 'Edit' && idSelected) && <EditProduct id={idSelected} setMode={setMode} /> }
            </div>
        </>
    );
}

export default Products
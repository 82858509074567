import React from 'react'

const CardHeadeWithActions = ({children,title}) => {
    return (
        <div id='Header' className='flex items-center '>
            <div  className='flex-1 font-bold text-slate-400'>{title}</div>
            {children}
        </div>
    )
}

export default CardHeadeWithActions
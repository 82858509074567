import React, {  useEffect, useState } from 'react'
import {AiFillCloseCircle} from 'react-icons/ai'

const MwModal = ({title,children,onShow,setOnShow,saveHandler=()=>{},cancelHandle=()=>{}}) => {
    const [isHidden,setIsHidden] = useState(true);

    const onHide = () => {
        setIsHidden(true);
        setOnShow(false);
        
    }
    
    useEffect(()=>{
        if(onShow && isHidden){
            setIsHidden(false);
        }
    },[onShow,isHidden]);
    
    const ChildrenComponent = (props) => {
        return React.cloneElement(children, props);
    }
    return (
        <div className={`flex flex-col items-center justify-center h-screen w-full bg-gray-700/75 absolute bottom-0 top-0 left-0 right-0 z-50 ${ !isHidden ? 'block' : 'hidden'  } `} >
            <div className=' flex flex-col items-between justify-between  w-11/12 md:w-4/6  bg-gray-300  rounded-lg overflow-y-auto relative '>
                {/* <div
                    className=' flex items-center justify-between  bg-slate-200  rounded-t  border-b border-slate-400 p-3 text-sm bg-slate-200   sticky top-0'
                    >
                    <div>{title}</div>
                    <AiFillCloseCircle size={24} onClick={onHide} className='hover:text-sky-800' />
                </div> */}
                <div className=' h-full bg-slate-100 ' >
                    <ChildrenComponent onHide={onHide} /> 
                </div>
            </div>

        </div>
    )
}

export default MwModal
import { useEffect, useState } from "react";
import {TbFileInvoice} from 'react-icons/tb'
import {MdOutlineAddBox} from 'react-icons/md'
import { Link, useParams } from "react-router-dom";
import withGuard from "../../utils/withGuard";
import InvListBasic from "../../components/invoice/InvListBasic";
import { useCurrent, useFetch } from "../../hooks";
import SearchInvoice from "../../components/SearchInvoice";
import { MwButton } from "../../components/ui";
import {BiSearch} from 'react-icons/bi'


const ManageInvoices = () => {
    const params = useParams();
    const [invoices,setInvoices] = useState([]);
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const [links,setLinks] = useState([]) 
    const [url,setUrl] = useState(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:resultInv,loading:loading,refreshHandler} = useFetch(url);
    const [searchLoading,setSearchLoading] = useState(false);



    const invoicesHandler = async (newUrl=url) => {
        setUrl(newUrl)
    }
    
    const onChangeSearch = (newValue) => {
        //console.log(newValue)
        setLinks(newValue.links)
        setInvoices(newValue.data)
        setSearchLoading(false);
    }
    useEffect(() => {
        if(resultInv){
            setLinks(resultInv.links)
            setInvoices(resultInv.data);
            console.log('invoice data',resultInv);
        }
    },[resultInv]);

    // useEffect(()=>{
    //     if(currentBranchId){
    //         invoicesHandler(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    //     }
    // },[currentBranchId])
    
    return (
        <>
            <div className='flex justify-between items-center pb-3'>
                <div className=' flex gap-1  text-sm text-gray-400 '>
                    <TbFileInvoice size={18} />
                    إدارة الفواتير </div>
                <Link to={`/${params.tenant}/invoices/create`}>
                    <MwButton inGroup={true} type='saveBtn'  >
                        <MdOutlineAddBox size={18}/>
                        انشاء فاتورة جديدة
                        </MwButton>
                </Link>
            </div>
            <div className="bg-slate-50 text-slate-500  rounded-lg text-xs h-full">
                {/* <div className="flex items-center justify-center ">
                    <div className="w-full font-bold">قائمة الفواتير</div>
                </div> */}
                {/* <hr className="my-3"/> */}
                <div className="flex gap-3">
                    
                    <InvListBasic loading={loading || searchLoading} links={links} paginateHandler = {invoicesHandler} data={invoices}/> 
                    <div className="w-56 ">
                        <div className="flex gap-1 py-2">
                            <BiSearch size={16}/>
                            <span>أبحث عن فاتورة</span>
                        </div>
                        {/* <hr className="my-2 pb-2"/> */}
                        <SearchInvoice className='bg-white' withFullResults={true} setLoadingCallback={setSearchLoading} onChangeSearch={onChangeSearch} isOpen={false} withFilter={true} />
                    </div> 
                </div>
            </div>
        </>
    )
}

export default withGuard(ManageInvoices)
import React,{useEffect} from 'react'

const MwProgressBar = (props) => {

    const {bgcolor,completed} = props;
    const containerStyles = {
        height: 10,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        
    }
    
    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 0.1s ease-in-out',
    }
    
    const labelStyles = {
        padding: 0,
        color: 'white',
        // fontWeight: 'bold'
        fontSize:13,
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                {props.withPercent && <span style={labelStyles}>{`${completed}%`}</span>}
            </div>
        </div>
    )
}

export default MwProgressBar
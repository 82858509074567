import { useEffect, useState } from 'react';
import {  useTranslation } from 'react-i18next';
import  {MwSelectorProduct,MwInputText,InputQty} from '../../ui'
import {TiDeleteOutline} from 'react-icons/ti' 
import {LangArrayToObjKeyValue} from '../../../utils/global.js';
import { useCurrent, useInvoice } from '../../../hooks';
import {amountFormat} from '../../../utils/invoceHelper.js'

const InvFormListItem = ({
    index,
    reloadProductList,
    showModalProductHandle,
    productList,
    setProductList,
    formErrors,
    SETTING_PRICE_INCLUDE_VAT
    }) => {
    const {currentLangId} = useCurrent();
    const [t] = useTranslation('global')
    const [pricelist,setPricelist]= useState({}); 
    const {SETTING_INVOICE, toFloat } = useInvoice();

    const deleteItem = () => {
        console.log(productList);
        
        const newFilter = productList.map((item,i) => {
            if (item.index === index){
                item.productId = ''
                item.productName = 'Deleted'
                item.qty = ''
                item.price = ''
            }
            return item;
        });
        // console.log(newFilter)
        setProductList(newFilter)
    }

    const onChangeProduct = (value) => {
       // console.log('onChangeProduct')
        if(!value?._id){
            productList[index] = {
                index,
                productId:'0', 
                productName: value,
                qty:1,
                price:''
            }
            setPricelist({...pricelist});
            setBalanceRow(index)
            return 
        }
        const newPrice =  SETTING_PRICE_INCLUDE_VAT === 'true' ? value?.price * 100/115   : value?.price;
        pricelist[`${value?._id}`] = newPrice;
        setPricelist({...pricelist});
        //console.log('pricelist',pricelist);
        productList[index] = {
            index,
            productId:value?._id, 
            productName: LangArrayToObjKeyValue(value?.productName)?.[currentLangId] || value?.productName[0]?.text || value?.productName[1]?.text ,
            qty:1,
            price:newPrice,
            priceUnit:newPrice,
        }
        setProductList([...productList]); 
        setBalanceRow(index)
       // console.log(productList)
    };

    const incressQty = (index) => {
        if(!!productList[index] && parseInt(productList[index].qty) > 0){
            const newValue = parseInt(productList[index].qty)+ 1
            productList[index].qty = newValue;
            onChangeQty(newValue,index)
            setBalanceRow(index)
        }
        
    }
    const decressQty = (index) => {
        if(!!productList[index] && parseInt(productList[index].qty) > 1){
            const newValue = parseInt(productList[index].qty)- 1
            productList[index].qty = newValue;
            onChangeQty(newValue,index)
          //  setBalanceRow(index)
        }
        
    }
    const onChangeQty = (value,index) => {
        if (productList[index]?.productId === '0' ) {
            
            productList[index].qty = value
            productList[index].price = productList[index].priceUnit * value
            setProductList([...productList]); 
            return;
        };
        // const priceOne = parseFloat( pricelist[`${productList[index].productId}`])  ;
        const priceOne = productList[index].priceUnit ;
        console.log('priceOne',priceOne);
        if(!(!!productList[index].qty)) {
            productList[index].qty=1
        }  
        if( !isNaN(productList[index].qty) && !isNaN(parseInt(productList[index].qty)) >= 1 ){
           // priceOne = (productList[index].price / productList[index].qty )
            productList[index].qty = value
            if((productList[index].qty * priceOne) &&  !isNaN(productList[index].qty * priceOne) ){
                productList[index].price  =  productList[index].qty * priceOne
            }else{
                if(!isNaN(productList[index].price)){
                    productList[index].price  = priceOne
                }else{
                    //productList[index].price = 
                }
            }
            console.log('productList.qty',productList[index].qty,'price;',productList[index].price)
        }
        setProductList([...productList]); 
        setBalanceRow(index)
    }

    const onChangePrice = (value,index) =>{
        productList[index].price = value ;
        setProductList([...productList]); 
    }

    const onChangePriceUnit = (value,index) =>{
        productList[index].priceUnit = value ;
        if(productList[index].qty ){
            productList[index].price = productList[index].qty * value ;
        }
        setBalanceRow(index)
        setProductList([...productList]); 
    }
    const onChangeProductDiscount = (value,index) =>{
        if(value > parseInt(productList[index].price || 0) ){
            productList[index].productDiscount = 0;
        }else{
            productList[index].productDiscount = value ;
        }
        if(productList[index].price ){
            setBalanceRow(index)
        }
        
        setProductList([...productList]); 
    }

    const setBalanceRow = (index) => {
      //  const _price = productList[index].price ;
        // const _productDiscount = productList[index].productDiscount || 0;
       // const _productNetTotal = productList[index].productNetTotal || 0 ; 

        if( (productList[index].productNetTotal || 0) > productList[index].price ){
            productList[index].productDiscount = 0 ;
        }
        productList[index].productNetTotal = productList[index].price - (productList[index].productDiscount || 0);
        productList[index].productVat = (productList[index].productNetTotal || 0) * SETTING_INVOICE.INV_VAT_PERCENT /100
        productList[index].productNetTotalWithVat = productList[index].productVat + (productList[index].productNetTotal || 0) ;
        setProductList([...productList]); 
    }

    useEffect(()=>{
        
    },[productList]);
    useEffect(()=>{
        console.log(SETTING_INVOICE)
    },[]);


    return (
        <>
            {productList[index]?.productName !== 'Deleted' && 
            <div className='flex border-b items-center py-2  '>
                <div className="flex flex-1 flex-col ">
                    <MwSelectorProduct 
                        disabled = {true}
                        invalid = {!!formErrors?.productList}
                        withLabel={index === 0 && true}
                        initalValue = {productList[index]?.productName}
                        reloadProductList={reloadProductList} 
                        onCreateProduct={showModalProductHandle} 
                        onChangeCallback ={onChangeProduct} />
                </div>
                <div className="  w-20 mx-1   ">
                    <MwInputText 
                        label={index === 0 && 'سعر الوحدة'}
                        disabled = {!(!!productList[index]?.productId && !!productList[index]?.productName ) || productList[index]?.productId != 0 }
                        id='priceUnit' 
                        value={ amountFormat(productList[index]?.priceUnit) || ''} 
                        onChange={(e)=>onChangePriceUnit(e.target.value,index)} />
                </div>
                <div className={ `flex flex-col me-1 ${index === 0 && 'mt-1' } `}>
                    <InputQty 
                        disabled={true}
                        label= {index === 0 && t('invoice.quantity') }
                        id='qty' 
                        // disabled = {!(!!productList[index]?.productId)}
                        value={productList[index]?.qty || ''} 
                        onChange={(e)=>onChangeQty(e.target.value,index)}
                        onIncress={(e)=>incressQty(index)}
                        onDecress={(e)=>decressQty(index)}
                        // disabled={true}
                        />
                </div>
                <div className="w-20  ">
                    <MwInputText 
                        label={`${index === 0 ? t('invoice.price')  : ''}`}
                        id='price' 
                        disabled={ true}
                        value={ amountFormat(productList[index]?.price) || ''} 
                        onChange={(e)=>onChangePrice(e.target.value,index)} />
                </div>
                <div className=" w-16 mx-1 ">
                    <MwInputText 
                        disabled ={true}
                        label={index === 0 && 'الخصم'}
                        id='productDiscount' 
                        value={ productList[index]?.productDiscount || ''} 
                        onChange={(e)=>onChangeProductDiscount(e.target.value,index)} />
                </div>
                <div className=" w-20   ">
                    <MwInputText 
                        label={index === 0 && 'الإجمالي'}
                        disabled={ true}
                        id='productNetTotal' 
                        value={ amountFormat(productList[index]?.productNetTotal) || ''} 
                        onChange={(e)=>onChangePrice(e.target.value,index)} />
                </div>
                <div className=" w-20  mx-1 ">
                    <MwInputText 
                        disabled={ true}
                        label={index === 0 &&  ` الضريبة  ${SETTING_INVOICE.INV_VAT_PERCENT}%`}
                        id='productVat' 
                        value={ amountFormat(productList[index]?.productVat) || ''} 
                        onChange={(e)=>onChangePrice(e.target.value,index)} />
                </div>
                <div className="w-24">
                    <MwInputText 
                        disabled={ true}
                        label={index === 0 && 'الصافي'}
                        id='productNetTotalWithVat' 
                        value={ amountFormat(productList[index]?.productNetTotalWithVat) || ''} 
                        onChange={(e)=>onChangePrice(e.target.value,index)} />
                </div>
                <div className={ `flex flex-col w-4 mx-2  ${index === 0 ? 'pt-5' : '' } `}>
                    {productList[index]?.price && <TiDeleteOutline onClick={()=>deleteItem()} size={18} title='حذف المنتج' className='text-red-500' /> }
                </div>
            </div>}
        </>
    )
}

export default InvFormListItem
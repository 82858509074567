import React, { useEffect,useState } from 'react'
import {MdOutlineAddBox} from 'react-icons/md'
import { ConfirmDelete, MwButton } from '../../ui'
import { useFetch,useCurrent, useDelete } from '../../../hooks'

import ListCategoriesItem from './ListCategoriesItem'
import NoDataFound from '../../ui/NoDataFound'
import CardHeadeWithActions from '../../ui/CardHeadeWithActions'

const ListCategories = ({idSelected,setIdSelected,setMode}) => {
    const {currentTenantId,currentCompanyId} = useCurrent();
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const {data:categories,loading,error,refreshHandler} = useFetch(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/categories/${currentTenantId}/${currentCompanyId}`);
    const {data:deleteResult,loading:deleteLoading,deleteItem} = useDelete()

    const editHandeler = (id) => {
        setIdSelected(id)
        setMode('Edit')
    } 

    const deleteHandeler = (id) => {
        setIdSelected(id)
        setShowConfirmDelete(true);
    }

    const confirmDelete = () => {
        deleteItem(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}/categories/delete/${idSelected}`)
    }



    useEffect(() =>{
        if(deleteResult){
            setShowConfirmDelete(false);
            refreshHandler();
        }
    },[deleteResult])
    return (
    <>
        <div className='text-slate-500 bg-slate-50 rounded-lg text-xs p-3'>
            <ConfirmDelete onDeleteHandler={()=>confirmDelete()} onShow={showConfirmDelete} setOnShow={setShowConfirmDelete} msg={`هل انت متاكد من حذف الممنتج`}/>
            <CardHeadeWithActions title={`قائمة التصنيفات`}>
                <MwButton inGroup={true} type='saveBtn' onClick={() => setMode('Add')}  >
                    <MdOutlineAddBox size={18}/>
                    انشاء تصنيف جديدة
                </MwButton>
            </CardHeadeWithActions>
            <div className='py-5'> {
                categories?.data.length > 0 ? categories?.data.map((el) =>(<ListCategoriesItem el={el} key={el._id} editHandeler = {editHandeler} deleteHandeler = {deleteHandeler} />)) : <NoDataFound msg={`لا يوجد تصنيفات`} />
            } </div>
        </div>
    </>
    )
}

export default ListCategories
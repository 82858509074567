import React from 'react'

const CardAmount = ({label,amountValue,footerValue}) => {
    return (
        <div id='total' className=' flex-1 text-center'>
            <div className=' text-slate-400 text-xs'>{label}</div>
            { !!amountValue && 
                <div className='text-xs' >
                    <div className='font-bold text-base text-slate-600  '>{amountValue}</div>
                    <div className='text-slate-400'>{!!footerValue && footerValue}</div>
                </div>
            }
        </div>
    )
}

export default CardAmount
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';
import {Doughnut } from 'react-chartjs-2'
import { MwSpinnerButton } from '../ui';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
)

const PieChart = ({labels,datasetsLabel,datasetsData,borderColor,backgroundColor,className,loading=false}) => {
    const data = {
        labels: labels,
        datasets:[
            {
                label:datasetsLabel ,
                data:datasetsData,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
                tension:0.4,
                
                // circumference:180,
                // rotation:270
            }
        ]
    }
    const options = {
        plugins: {
            // defaultFontFamily:'"Tajawal", sans-serif',
            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 14,
                        family: '"Tajawal", sans-serif',
                    }
                }
            }
        }
    }

    return ( 
        <div className='flex-1 rounded-lg bg-slate-50 flex items-center justify-center  md:w-fit border-2  border-slate-50'>
            { !loading ? 
                    <Doughnut 
                        className={` ${className}`}
                        data={data}
                        options={options}
                    >
                    </Doughnut>
                : <MwSpinnerButton withLabel={false} isFullCenter={true} />
            }
        </div> 
    )
}

export default PieChart
import axios from "axios";

export const addSalesman = async(_data) => {
    const res = await axios.post(`${process.env.REACT_APP_Auth_API_BASE_URL}/salesmans`,_data);
    if(res){
        return res.data
    }else{
        return false;
    }
};
export const updateSalesman = async(id,_data) => {
    const res = await axios.put(`${process.env.REACT_APP_Auth_API_BASE_URL}/salesmans/update/${id}`,_data);
    if(res){
        return res.data
    }else{
        return false;
    }
};

export const getAllSalesman = async(tenantId,companyId,branchId) => {
    const response = await axios.get(`${process.env.REACT_APP_Auth_API_BASE_URL}/salesmans/${tenantId}/${companyId}/${branchId}`);
    const resData = response?.data ;
    if(resData){
        return resData.data;
    }
}
import React from 'react'
import { BasicSelector, MwInputText, MwTextArea } from '../../components/ui'
import {  useTranslation } from 'react-i18next';


const SettingListItem = ({
    settingName,
    settingValue,
    filterDefineSetting,
    setGeneralSetting,
    generalSetting,
    currentLangId,
    prefixLang = 'general'
}) => {
    const [t] = useTranslation('settings')

    const getValue = (settingName,value) => {
        return filterDefineSetting(settingName)?.valueTypeList?.filter(item => (item.value === value && item.lang === currentLangId ))[0]?.label
    }

    const handleList = (settingName) => {
        const _genList = filterDefineSetting(settingName)?.valueTypeList.filter(item => item.lang === currentLangId);
        const _list = _genList.map(item => ({
            label: item.label,
            value: {[settingName]: item.value}
        }));
        // console.log('_list',_list);
        return _list;
    }
    const onSelected = (item) => {
        const obj = item.value;
        setGeneralSetting({...generalSetting, ...obj })
        console.log(generalSetting)
    }
    return (
        <div className=' text-xs border-b py-1'>
            { 
            settingValue === 'true' || settingValue === 'false' ? 
            <div className='grid grid-cols-2 items-center'>
                <div className='text-slate-400 px-2'>{t(`${prefixLang}.${settingName}`)}</div>
                <BasicSelector
                label={t(`${prefixLang}.${settingValue.toUpperCase()}`)}
                listItem={[
                    {label:t(`${prefixLang}.TRUE`),value:{[settingName]:'true'}},
                    {label:t(`${prefixLang}.FALSE`),value:{[settingName]:'false'}}
                ]}
                onSelected={onSelected}
                >
                </BasicSelector>
            </div>
            : filterDefineSetting(settingName)?.valueType === 'list' ? 
            <div className='grid grid-cols-2 items-center'>
                <div className='text-slate-400 px-2'>{t(`${prefixLang}.${settingName}`)}</div>
                <BasicSelector
                label={getValue(settingName,settingValue)}
                listItem={handleList(settingName)}
                onSelected={onSelected}
                >
                </BasicSelector>
            </div>
            :filterDefineSetting(settingName)?.valueType === 'textArea' ? 
            <div className='grid grid-cols-2 items-center'>
                <MwTextArea
                label={t(`${prefixLang}.${settingName}`)}
                value={settingValue}
                rows={7}
                onChange={(e)=>setGeneralSetting({...generalSetting, [settingName]:e.target.value})}
                />
            </div>
            :<div className='grid grid-cols-2 items-center'>
                <MwInputText
                label={t(`${prefixLang}.${settingName}`)}
                value={settingValue}
                onChange={(e)=>setGeneralSetting({...generalSetting, [settingName]:e.target.value})}
                />
            </div>}
        </div>
    )
}

export default SettingListItem
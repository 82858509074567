import TenantBar from './TenantBar.jsx'
import MenuButton from './MenuButton.jsx'
import UserBar from './UserBar.jsx'
import NotificationBar from './NotificationBar.jsx'
import LogoEchoSystem from '../ui/LogoEchoSystem.jsx'

const HeaderNavBar = ({openMenu,setOpenMenu,companiesList,branchesList,tenantUsername,tenant,companySelected,branchSelected}) => {
    
    return (
        <>
            <div className='primary-bg-color  sticky top-0 z-50 text-white  '>
            <NotificationBar />
            <div className="px-3  m-auto  w-full p-1">
                <div className='flex flex-col md:flex-row  w-full py-1 justify-between items-center '>
                    <div className='flex-1 flex items-center justify-between '>
                        <MenuButton openMenu = {openMenu}  setOpenMenu = {setOpenMenu} />
                        <TenantBar 
                            companiesList = {companiesList}
                            branchesList = {branchesList}
                            companySelected={companySelected} 
                            branchSelected={branchSelected} />
                    </div>
                    <LogoEchoSystem name="مشتريات" />
                    <UserBar
                        tenantUsername = {tenantUsername}
                        tenant = {tenant}
                    />
                </div> 
            </div>
            </div>
        </>
    )
}

export default  HeaderNavBar 
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {  useTranslation } from 'react-i18next';
import LineChart from '../components/charts/LineChart';
import DaugeChart from '../components/charts/DaugeChart';
import PieChart from '../components/charts/PieChart';
import TimeLine from '../components/timeline/TimeLine';
import SearchBar from '../components/SearchBar';
import { useCurrent } from '../hooks';
import InvListWidget from '../components/invoice/InvListWidget';
import QuoteListWidget from '../components/quote/QuoteListWidget';
import {AiOutlineDashboard} from 'react-icons/ai'
import { MwButton } from '../components/ui';
import {MdSupportAgent} from 'react-icons/md'
import { Link, useParams } from 'react-router-dom';

const DashBoard = () => {
    const [t] = useTranslation('global')
    const params = useParams();
    const {currentSettings,currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const [salesCurrentYear,setSalesCurrentYear] = useState(0);
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYeay = date.getFullYear();
    const _token = localStorage.getItem('token');

    // const Target Monthly
    const SETTING_TARGET_MONTHLY = currentSettings?.generalSettings?.TARGET_MONTHLY
    const [targetMonthyPercent,setTargetMonthlyPercent] = useState([0,0]);
    const [targetMonthyLabel,setTargetMonthlyLabel] = useState([0,0]);
    const [targetMonthyLoading,setTargetMonthlyLoading] = useState(true);
    // const Target Yearly
    const SETTING_TARGET_YEARLY = currentSettings?.generalSettings?.TARGET_YEARLY
    const [targetYearlyPercent,setTargetYearlyPercent] = useState([0,0]);
    const [targetYearlyLabel,setTargetYearlyLabel] = useState([0,0]);
    const [targetYearlyLoading,setTargetYearlyLoading] = useState(true);
    // const Paid invoices 
    const [paidInvoicePercent,setPaidInvoicePercent] = useState([0,0]);
    const [paidInvoiceLabel,setPaidInvoiceLabel] = useState([0,0]);
    const [paidInvoiceLoading,setPaidInvoiceLoading] = useState(true);
    // const Sales Months groups
    const [salesMonths,setSalesMonths] = useState([]);
    const [salesMonthsLoading,setSalesMonthsLoading] = useState(true);
    // const Salesman
    const [salesmanTotals,setSalesmanTotals] = useState([]);
    const [salesmanLabels,setSalesmanLabels] = useState([]);
    const [salesmanLoading,setSalesmanLoading] = useState([]);
    // const Product
    const [productTotals,setProductTotals] = useState([]);
    const [productLabels,setProductLabels] = useState([]);
    const [productLoading,setProductLoading] = useState([]);



    const targetMonthlyHandler = async () => {
        const res = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/month/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${currentMonth}`);
        const sum = res.data;
        const percentSum = (parseFloat(sum) / parseFloat(SETTING_TARGET_MONTHLY) ) * 100 ;
        setTargetMonthlyLoading(false)
        setTargetMonthlyPercent([percentSum,(100 - percentSum)])
        setTargetMonthlyLabel([parseFloat(sum),parseFloat(SETTING_TARGET_MONTHLY - sum)])
    }
    const targetYearlyHandler = async () => {
        const res = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/year/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${currentYeay}`);
        const sum = res.data;
        setSalesCurrentYear(sum);
        //salesCurrentYear = sum;
        const percentSum = (parseFloat(sum) / parseFloat(SETTING_TARGET_YEARLY) ) * 100 ;
        setTargetYearlyLoading(false)
        setTargetYearlyPercent([percentSum,(100 - percentSum)])
        setTargetYearlyLabel([parseFloat(sum),parseFloat(SETTING_TARGET_YEARLY - sum)])
    }
    const paidInvoiceHandler = async () => {
        const res = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/paid/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${currentYeay}/true`);
        const sum = res.data;
        if(salesCurrentYear > 0){
            const percentSum = (parseFloat(sum) / parseFloat(salesCurrentYear)) * 100 ;
            setPaidInvoiceLoading(false)
            setPaidInvoicePercent([percentSum,(100 - percentSum)])
            setPaidInvoiceLabel([parseFloat(sum),parseFloat(salesCurrentYear - sum)])
        }
        console.log('paid',sum,salesCurrentYear);

    }
    const salesMonthsHandler = async () => {
        const res = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/month-group/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${currentYeay}`);
        const sum = res.data;
        setSalesMonths(sum)
        setSalesMonthsLoading(false)
    }
    const salesmanhsHandler = async () => {
        const res = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/salesman/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${currentYeay}`);
        const data = res.data;
        setSalesmanTotals(data.total)
        setSalesmanLabels(data.salesman)
        setSalesmanLoading(false)
    }
    const productsHandler = async () => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/topProducts/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${currentYeay}`);
            const data = res.data;
                setProductTotals(data.total)
                setProductLabels(data.productName) 
                setProductLoading(false)
        }catch(e){
            console.error(e)
            setProductLoading(false)
        }
    }

    useEffect(()=>{
        targetMonthlyHandler();
        targetYearlyHandler();
        paidInvoiceHandler();
        salesMonthsHandler();
        salesmanhsHandler();
        productsHandler();
        console.log('SETTING_TARGET_MONTHLY',currentMonth,currentYeay);
    },[salesCurrentYear])



    return (
        <>
            <div className='flex flex-col  '>
                <div className='flex-1 flex gap-1 justify-between items-center p-2 text-slate-500'>
                    <div className='flex gap-1 items-center'>
                        <AiOutlineDashboard size={18}/>{t('menu.Dashboard')}
                    </div>
                    <Link  target='_blank'  to={`${process.env.REACT_APP_WEB_SUPPORT_BASE_URL}/${params.tenant}/token/${_token}`}> 
                        <MwButton classNameCustom='w-full' inGroup={true} type='saveBtn'  ><MdSupportAgent size={20}/> الدعم الفني </MwButton>
                    </Link>
                </div>
                <SearchBar/>
                <div className='flex flex-col  gap-1 '>
                    <div className='flex flex-col md:flex-row justify-between gap-1 md:h-80  '>
                        <LineChart datasetData = {salesMonths} loading={salesMonthsLoading} />
                    </div>
                    {/* <div className=' flex flex-col md:flex-row justify-between  gap-1  '>
                        <div className='flex-1 grid grid-cols-1 md:grid-cols-3 justify-center md:justify-between gap-1'>
                            <DaugeChart
                                labels={ ['الفعلي', 'الهدف'] }
                                datasetLabel = {`الهدف الشهري`}
                                labelMetrics = {SETTING_TARGET_MONTHLY}
                                datasetData={targetMonthyPercent}
                                datasetDataLabel={targetMonthyLabel}
                                loading={targetMonthyLoading}
                                />
                            <DaugeChart 
                                labels={ ['الفعلي', 'الهدف'] }
                                datasetLabel = {`الهدف السنوي`}
                                labelMetrics = {SETTING_TARGET_YEARLY}
                                datasetData={targetYearlyPercent}
                                datasetDataLabel={targetYearlyLabel}
                                loading={targetYearlyLoading}
                                />
                            <DaugeChart 
                                labels={ ['مدفوع','غير مدفوع'] }
                                datasetLabel = {`المبيعات`}
                                labelMetrics = {salesCurrentYear}
                                datasetData={paidInvoicePercent}
                                datasetDataLabel={paidInvoiceLabel}
                                loading={paidInvoiceLoading}
                                />
                            
                        </div>
                    </div> */}

                    {/* <div className='flex flex-col md:flex-row justify-between gap-1'>
                        <PieChart
                                labels = {productLabels}
                                datasetsLabel = 'Products'
                                datasetsData = {productTotals}
                                borderColor = {['indigo','gray','black','purple','orange']}
                                backgroundColor = {['indigo','gray','black','purple','orange']}
                                loading = {productLoading}
                            />
                        <PieChart
                                labels = {salesmanLabels}
                                datasetsLabel = 'Salesman'
                                datasetsData = {salesmanTotals}
                                borderColor = {['indigo','gray','black','purple','orange']}
                                backgroundColor = {['indigo','gray','black','purple','orange']}
                                loading = {salesmanLoading}
                            />
                        <TimeLine/>
                    </div> */}
                    

                    <div className='flex flex-col gap-2 md:flex-row mt-5 '>
                        <div className='flex-1'><InvListWidget/></div>
                        <div className='flex-1'><QuoteListWidget /></div>
                    </div>
                    <div className='h-5'></div>
                </div>
            </div>
        </>
    )
}

export default DashBoard
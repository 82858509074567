import React from 'react'
import {useCurrent} from '../../../hooks'
import { useTranslation } from 'react-i18next'

const InvPaymentTransactions = ({data,SETTING_INV_CURRENCY,formatter}) => {
    const {currentLangId} = useCurrent();
    const [t] = useTranslation('global')
    let total = 0;
    console.log(data);
    const listTransactions = data?.map((transaction) =>
        { 
            const paymentMethod = transaction.payment_method?.description?.filter(des => des.lang === currentLangId )[0] || transaction.payment_method?.description[0];
            if(transaction.type.trim() === 'out'){
                total += parseFloat(transaction.amount)
            }
            
            return (
                
                <div key={transaction.id} className=' '>
                { transaction.type.trim() === 'out' &&
                    <div className='flex justify-between items-center  gap-2 px-2 py-2 border-t'>
                        <div className='flex-1'>{transaction.transaction_no}</div>
                        <div className='flex-1'>{paymentMethod?.name || 'Unknown' }</div>
                        <div className='w-24 text-center'>
                            {
                                transaction.type.trim() === 'in' ?
                                <span className='text-green-500'>{`${formatter.format(transaction.amount)} `} </span> : 
                                <span className='text-red-500'>{`(${formatter.format(transaction.amount)} )`}</span>
                            }
                            
                            </div>
                        <div className='w-30'>{transaction.created_at}</div>
                    </div>
                }
                </div>
            );
        });

        return (
        <div className='text-xs'>
            <div className='font-bold '>- {t('invoice.PaymentTransactions')}</div>
            <div className='px-2 my-1 border rounded'>{listTransactions}</div>
            <div className='flex px-1 '> {t('invoice.TOTALPAYMENT')}:  <span className='font-bold px-1'> {formatter.format(total)} </span> </div>
        </div>
    )
}

export default InvPaymentTransactions
import React, { useEffect, useState } from 'react'
import {useInvoice,usePut,useCurrent} from '../../hooks/'
import { BasicSelector, MwButton, MwInputText, MwSpinnerButton } from '../../components/ui'
import { useDispatch } from 'react-redux'
import {updateSettings} from '../../store/settingSlice'
import SettingListItem from '../../components/settings/SettingListItem'

const InvoiceSettings = ({refreshTenant,filterDefineSetting}) => {
    const dispatch = useDispatch();
    const {SETTING_INVOICE} = useInvoice()
    const [invSetting,setInvSetting] = useState(SETTING_INVOICE)
    const {data,loading,error,putData} = usePut()
    const {currentCompanyId,currentTenantId,currentLangId} = useCurrent();

    // const onSelected = (item) => {
    //     const obj = item.value;
    //     setInvSetting({...invSetting, ...obj })
    //     console.log(invSetting)
    // }

    const invSettingArray = Object.entries(invSetting);
    const invSettingList =  invSettingArray.map(([settingName,settingValue]) =>(
        <SettingListItem
            key={settingName}
            settingName = {settingName}
            settingValue = {settingValue}
            filterDefineSetting = {filterDefineSetting}
            setGeneralSetting = {setInvSetting}
            generalSetting = {invSetting}
            currentLangId = {currentLangId}
            prefixLang = 'invoice'
            />
        // <div key={settingName} className=' text-xs border-t py-1'>
        //     { settingValue === 'true' || settingValue === 'false' ? 
        //     <div className='flex justify-between items-center'>
        //         <div className='text-slate-400 px-2'>{settingName}</div>
        //         <BasicSelector
        //         label={settingValue.toUpperCase()}
        //         listItem={[{label:'TRUE',value:{[settingName]:'true'}},{label:'FALSE',value:{[settingName]:'false'}}]}
        //         onSelected={onSelected}
        //         >
        //         </BasicSelector>
        //     </div>
        //     :<div className='grid grid-cols-2 items-center'>
        //         <MwInputText
        //         label={settingName}
        //         value={settingValue}
        //         onChange={(e)=>setInvSetting({...invSetting, [settingName]:[e.target.value]})}
        //         />
        //     </div>}
        // </div>
    ));

    useEffect(()=>{
        if(data){
            refreshTenant();
        }
    },[data]);


    const saveHandler = async() => {
        const invSettingArray = Object.entries(invSetting).map(([key, value]) => ({ name:key , value: value }));
        const _data = {
            companyId:currentCompanyId,
            settingsInvoice:invSettingArray
        }
        console.log(_data);
        putData(`${process.env.REACT_APP_Auth_API_BASE_URL}/tenants/invoice-setting/${currentTenantId}`,_data)
        dispatch(updateSettings({
            typeSetting : 'invoice',
            value : invSetting
        }));
    }

    return (
        <>
        <div>
            <div className='flex  justify-between items-center gap-2 text-xs text-slate-500 font-bold px-1 '>
                    <div>
                        إعدادات الفاتورة: 
                    </div>
                    { loading ? <MwSpinnerButton/> : <MwButton 
                                    inGroup = {true}
                                    size='sm'
                                    onClick={saveHandler}
                                    type = 'saveBtn'  > حفظ التغيرات
                                    </MwButton> }
            </div>
            <div className='p-3 my-3  rounded-lg border border-slate-200'>{invSettingList}</div>
        </div>
        </>
    )
}

export default InvoiceSettings
import {useState} from 'react'
import { MwButton, MwInputText, MwTextArea } from '../../ui'
import {RiDeleteBin5Line} from 'react-icons/ri'
import {AiOutlineSave,AiOutlineEdit} from 'react-icons/ai'

const AddressForm = ({
    errors,
    address,
    setAddress
}) => {
    const [editableMode,setEditableMode] = useState({state:false,index:0});

    const [newAddress,setNewAddress] = useState({
        name:'',
        city:'',
        region:'',
        street:'',
        desc:''
    });

    const [addressEditable,setAddressEditable] = useState({
        name:'',
        city:'',
        region:'',
        street:'',
        desc:''
    });



    const editItem = (index,item) => {
        setEditableMode({state:true,index:index});
        setAddressEditable(item)
        
    }
    const updateItem = (index) => {
        address[index] = addressEditable;
        setAddress([...address]);
        setEditableMode({state:false,index:index});
    } 

    const deleteItem = (index) => {
        const filter = address.filter((el,i) => i !== index)
        setAddress(filter)
    }

    const addNewHandler = () => {
        console.log('newAddress ==> ',newAddress)
        if(!newAddress.city) return;
        newAddress && setAddress([...address,newAddress]);
        setNewAddress({
            name:'',
            city:'',
            region:'',
            street:'',
            desc:''
        })
    }
    return (
        <>
            <div id='AddNewAddress' >
                <div className='flex-1 py-2'>
                    <MwInputText 
                        label='الاسم'
                        id='newAddress.name' 
                        value={newAddress.name} 
                        invalid={!!errors.newAddressName}
                        invalidMsg={errors.newAddressName}
                        onChange={(e)=> setNewAddress({...newAddress,name:e.target.value}) } />
                </div>
                <div className='flex justify-between gap-3'>
                    <div className='flex-1 py-2'>
                        <MwInputText 
                            label='المدينة'
                            id='newAddressCity' 
                            value={newAddress.city} 
                            invalid={!!errors.newAddressCity}
                            invalidMsg={errors.newAddressCity}
                            onChange={(e)=>setNewAddress({...newAddress,city:e.target.value})  } />
                    </div>
                    <div className='flex-1 py-2'>
                        <MwInputText 
                            label='الحي'
                            id='newAddressRegion' 
                            value={newAddress.region} 
                            invalid={!!errors.newAddressRegion}
                            invalidMsg={errors.newAddressRegion}
                            onChange={(e)=>setNewAddress({...newAddress,region:e.target.value}) } />
                    </div>
                </div>
                <div className='py-2'>
                        <MwInputText 
                            label='الشارع'
                            id='newAddressStreet' 
                            value={newAddress.street} 
                            invalid={!!errors.newAddressStreet}
                            invalidMsg={errors.newAddressStreet}
                            onChange={(e)=>setNewAddress({...newAddress,street:e.target.value})  } />
                </div>
                <div className='py-2'>
                    <MwTextArea 
                        label='الوصف'
                        id='newAddressDesc' 
                        value={newAddress.desc} 
                        rows={2}
                        onChange={(e)=>setNewAddress({...newAddress,desc:e.target.value})  } />
                </div>
                <div id='Actions' className='flex justify-end'>
                    <MwButton type='saveBtn' actionType='button' onClick={addNewHandler} >حفظ</MwButton>
                </div>
            </div>
            { address && address.length > 0 && <div id='ListAddresses' className='bg-slate-100 rounded-lg p-3 my-3'>
                <span>قائمة العناوين</span>
                <hr className='my-1' />
                <div className='my-2' >
                    {
                        address?.map((address,index) =>(
                        <div key={index} className='rounded bg-slate-50 p-3 my-3 gap-4 flex flex-col justify-center' >
                            <div className='flex justify-between items-center gap-2'>
                                <div className='flex-1  gap-2'>
                                    <label className='text-slate-400'>الاسم:</label>
                                    { editableMode.state && editableMode.index === index  ? <MwInputText value={addressEditable.name} onChange={(e) => setAddressEditable({...addressEditable,name:e.target.value})} /> : <span>{address.name || 'لايوجد'}</span> }
                                </div>
                                <div className='flex-1  gap-2'>
                                    <label className='text-slate-400'>المدينة:</label>
                                    { editableMode.state && editableMode.index === index  ? <MwInputText value={addressEditable.city} onChange={(e) => setAddressEditable({...addressEditable,city:e.target.value})} /> : <span>{address.city || 'لايوجد'}</span> }
                                </div>
                                <div className='flex-1  gap-2'>
                                    <label className='text-slate-400'>المنطقة:</label>
                                    { editableMode.state && editableMode.index === index  ? <MwInputText value={addressEditable.region} onChange={(e) => setAddressEditable({...addressEditable,region:e.target.value})} /> : <span>{address.region || 'لايوجد' }</span> }
                                </div>
                                <div className='flex-1 gap-2'>
                                    <label className='text-slate-400'>الشارع: </label>
                                    { editableMode.state && editableMode.index === index  ? <MwInputText value={addressEditable.street} onChange={(e) => setAddressEditable({...addressEditable,street:e.target.value})} />  : <span>{address.street || 'لايوجد'}</span> }
                                </div>
                            </div>
                            <div className=''>
                                <div className='flex-1  gap-2'>
                                    <label className='text-slate-400'>الوصف: </label>
                                    { editableMode.state && editableMode.index === index  ? <MwTextArea value={addressEditable.desc} onChange={(e) => setAddressEditable({...addressEditable,desc:e.target.value})} />  : <span>{address.desc || 'لايوجد'}</span> }
                                </div>
                                <div id='actions' className='flex gap-2 items-center pt-3 justify-end'>
                                    { editableMode.state && editableMode.index === index ? <MwButton  actionType='button'  type='secondary' size='sm'  onClick={()=>updateItem(index)}><AiOutlineSave size={18}/></MwButton> : <MwButton  actionType='button'   type='secondary' size='sm'   onClick={()=>editItem(index,address)}><AiOutlineEdit size={18}/></MwButton> }
                                    <MwButton actionType='button' type='secondary' size='sm' onClick={()=>deleteItem(index)}><RiDeleteBin5Line size={18}/></MwButton>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div> }
        </>
    )
}

export default AddressForm
import React from 'react'
import {RiMenuUnfoldFill,RiMenuFoldLine} from 'react-icons/ri'

const MenuButton = ({openMenu,setOpenMenu}) => {
    return (
        <div className='text-sm me-3 text-slate-500'>
            { openMenu ? 
            <RiMenuFoldLine 
                size={20}
                className='text-indigo-500'
                onClick={() => setOpenMenu(!openMenu)}
                /> :
            <RiMenuUnfoldFill 
                size={20}
                className='text-indigo-500'
                onClick={() => setOpenMenu(!openMenu)}
                /> 
            }
            {/* {`${user()?.name}`} 
                <span className=' text-sm px-1 '>
                    (<Link to={`${tenant().username}/invoices/logout`} className='text-red-500 text-xs' >Logout</Link>)
                </span> */}
        </div>
    )
}

export default MenuButton
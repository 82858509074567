import React, { useState , useEffect} from 'react'
import {getVendor} from '../../services/vendorService';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useCurrent } from '../../hooks';
import { MwSpinnerButton } from '../ui';
import InvListBasic from '../invoice/InvListBasic';

const ShowVendor = () => {
    const params = useParams();
    const {currentTenantId,currentLangId} = useCurrent();
    const [customer,setCustomer] = useState([]); 
    const [invCustomer,setInvCustomer] = useState([]);
    const [invCustomerLoading,setInvCustomerLoading] = useState(true);
    
    const customerHandler = async() => {
        const data=  await getVendor(params.tenant ,params.companyId,params.branchId,params.id)
        const fullname =  data?.fullname?.filter(item => item.lang.trim() === currentLangId)[0].text || data?.fullname[0].text || data?.fullname[1].text
        setCustomer({...data,name: fullname})
    }
    const getInvCustomer = async() => {
        const response = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/customers/${currentTenantId}/${params.companyId}/${params.branchId}/${params.id}`)
        setInvCustomer(response.data.data);
        setInvCustomerLoading(false)
    }

    useEffect(() => {
        customerHandler();
        getInvCustomer();
        console.log(customer,invCustomer)
    },[]);

    return (
        <>
            <div className='flex flex-col gap-3'>
                <div className='bg-slate-50 text-slate-500  rounded-lg p-3 text-xs'>
                    <div className='font-bold px-2 pt-3 text-sm text-center'> {customer?.name} </div>
                    <div className='flex justify-between  p-2'>
                        <span> رقم المورد </span>
                        <span>{customer?.customerNo || 'لايوجد'}</span>
                    </div>
                    <hr/>
                    <div className='flex justify-between  p-2'>
                        <span>رقم الجوال</span>
                        <span>{customer?.mobileNo  || 'لايوجد' }</span>
                    </div>
                    <hr/>
                    <div className='flex justify-between p-2'>
                        <span>تاريخ الانشاء</span>
                        <span>{customer.createdAt}</span>
                    </div>
                    <hr/>
                    <div className='flex justify-between p-2'>
                        <span>ملاحظات</span>
                        <span>{customer?.customerNotes  || 'لايوجد' }</span>
                    </div>
                </div>
                <div className='bg-slate-50 text-slate-500  rounded-lg p-3 text-xs '>
                    <InvListBasic loading={invCustomerLoading} data={invCustomer}/>
                </div>
            </div>
        </>
    )
}

export default ShowVendor
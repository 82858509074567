import React from 'react'
import { MwButton } from './ui'
import { Link } from 'react-router-dom'

const NotHaveInPackage = ({onHide}) => {
    return (
        <div className='flex flex-col   p-10 gap-3 '>
            <div className='text-center text-lg font-bold text-slate-500 pt-7 '>
                عفوا ، هذه الخدمة غير مفعلة في الباقة الخاصة بك
            </div>
            <div className='text-center mb-10'>
                <span className='   text-slate-700 text-sm px-2'>
                    لتفعيل الخدمة عليك التواصل مع الدعم الفني
                </span>
                <Link to='https://absat.org/#helpAndsupport' target='_blank'>
                    اضغط هنا
                </Link>
            </div>
            <MwButton type='saveBtn' onClick={onHide}>إغلاق</MwButton>
        </div>
    )
}

export default NotHaveInPackage
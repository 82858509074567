import {useState} from 'react'
import FormLangBar from '../../FormLangBar'
import { useCurrent } from '../../../hooks';
import { MwTextArea } from '../../ui';

const SEO = ({
    productMetaKeywords,
    setProductMetaKeywords,
    productMetaDesc,
    setProductMetaDesc
}) => {

    const {currentLangList,currentLangId} = useCurrent();
    const [formLang,setFormLang] = useState(currentLangId);

    return (
        <div>
            <FormLangBar currentLangList = {currentLangList} formLang = {formLang} setFormLang = {setFormLang} />
            { currentLangList.map((lang) => (
            <div key={lang.langCode} className=''>
                <div className={` ${ formLang === lang?.langCode ? 'block' : 'hidden' } `}>
                    <div id='productMetaKeywords' className='my-2'>
                        <MwTextArea
                            id={`productMetaKeywords${lang?.langCode}`}
                            value={productMetaKeywords[lang?.langCode]}
                            placeholder={` الكلمات الدليلة SEO (${lang.langName}) `}
                            rows={4}
                            onChange={(e ) => setProductMetaKeywords({...productMetaKeywords,[lang?.langCode]:e.target.value})}
                        />
                    </div>
                    <div id='productMetaDesc' className='my-2'>
                        <MwTextArea
                            id={`productMetaDesc${lang?.langCode}`}
                            value={productMetaDesc[lang?.langCode]}
                            placeholder={` الوصف SEO (${lang.langName}) `}
                            rows={4}
                            onChange={(e ) => setProductMetaDesc({...productMetaDesc,[lang?.langCode]:e.target.value})}
                        />
                    </div>
                </div>
            </div>
            )) }
        </div>
    )
}

export default SEO
import axios from "axios";

// export const addCustomer = async(_data) => {
//     const res = await axios.post(,_data);
//     if(res){
//         return res.data
//     }else{
//         return false;
//     }
// };

// export const updateCustomer = async(id,_data) => {
//     const res = await axios.put(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/update/${id}`,_data);
//     if(res){
//         return res.data
//     }else{
//         return false;
//     }
// };

export const getAllVendors = async(tenantId,companyId,branchId)=>{
    const res = await axios.get(`${process.env.REACT_APP_VENDORS_API_BASE_URL}/${tenantId}/${companyId}/${branchId}`);
    const data = res?.data?.data ;
    return data;

}
export const getVendor = async(tenantId,companyId,branchId,id)=>{
    const res = await axios.get(`${process.env.REACT_APP_VENDORS_API_BASE_URL}/show/${tenantId}/${companyId}/${branchId}/${id}`);
    const data = res?.data?.data ;
    return data;

}

import React,{ useEffect, useState} from 'react'
import {MwButton, MwInputText,MwSpinnerButton} from '../ui'

const UpdatePaymentMethod = ({
    currentLangList,
    currentTenantId,
    currentCompanyId,
    currentBranchId,
    userId,
    data,
    putData,
    loading,
    currentItem
}) => {

    const [namepaymentMethod,setNamepaymentMethod] = useState({});
    const [despaymentMethod,setDescpaymentMethod] = useState({});
    const [tabLang,setTabLang] = useState('en');

    const tabLangHandler = (newValue) => {
        setTabLang(newValue);
    }
    
    const submitHandler = (e) => {
        e.preventDefault();
        if( Object.keys(namepaymentMethod).length === 0 && Object.keys(despaymentMethod).length === 0 ) return
        let description = [];

        currentLangList.map((lang) => {
            if( !namepaymentMethod[lang.langCode] && !despaymentMethod[lang.langCode] ) return
            description = [...description, {
                "name": namepaymentMethod[lang.langCode],
                "desc" : despaymentMethod[lang.langCode],
                "lang" : lang.langCode
            }]
        })
        const _data = {
            "isActive" : true,
            "tenantId" : currentTenantId,
            "companyId": currentCompanyId,
            "branchId" : currentBranchId,
            "createdBy" : userId,
            "description" : description
        }
        console.log(_data);
        putData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/payment-methods/update/${currentItem.id}`,_data)

    }

    useEffect(() => {
        if(!!data && !loading){
            setNamepaymentMethod({})
        }
    },[data]);

    useEffect(() => {
        const initialName = Object.keys(currentItem).length > 0 ? { en: currentItem?.description[0].name || ' ', ar:currentItem?.description[1]?.name || ''} : {} ;
        const initialDesc = Object.keys(currentItem).length > 0 ? { en: currentItem?.description[0].desc || '', ar:currentItem?.description[1]?.desc || ''}  : {} ;
        
        setNamepaymentMethod(initialName);
        setDescpaymentMethod(initialDesc);
    },[currentItem]);

    return (
        <div>
            <div className=' text-sm text-slate-500 pb-3'>PaymentMethod</div>
            <div className='p-3  rounded-lg border border-slate-200'>
                
                <form onSubmit={submitHandler}>
                    <div className='flex justify-between items-center'>
                        <div className=' text-xs py-2 text-slate-500' >Update Payment Method</div>
                        { loading ? <MwSpinnerButton/> : <MwButton 
                            inGroup = {true}
                            size='sm'
                            type = 'saveBtn'  > Save
                            </MwButton> }
                    </div>
                    <div className='flex gap-2 mt-3 text-xs'>
                    { currentLangList.map( lang => ( <div key={lang._id} onClick={ () => tabLangHandler(lang.langCode)} className={` cursor-default ${tabLang === lang.langCode && 'text-indigo-700' }`} >{lang.langName}</div> )) }
                    </div>
                    <div className='flex flex-col gap-3 py-1  md:flex-row md:gap-0  '>
                        { currentLangList.map( lang => {
                            return  ( 
                                <div  key={lang._id} className={` flex flex-1 ${tabLang === lang.langCode ? 'block' : 'hidden'} `} >
                                    <MwInputText
                                        placeholder="Enter payment method name"
                                        size='sm'
                                        value={namepaymentMethod[lang.langCode]}
                                        onChange={(e)=>setNamepaymentMethod({...namepaymentMethod, [lang.langCode]:e.target.value })}
                                    />
                                    <MwInputText
                                        placeholder="Enter payment method description"
                                        size='sm'
                                        value={despaymentMethod[lang.langCode]}
                                        onChange={(e)=>setDescpaymentMethod({...despaymentMethod, [lang.langCode]:e.target.value })}
                                    />
                                </div>
                            )})}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UpdatePaymentMethod
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    invoiceSettings: {},
    generalSettings: {},
}

const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettings:(state,action) => {
            state.invoiceSettings = action.payload.invoiceSettings;
            state.generalSettings = action.payload.generalSettings;
        },
        updateSettings:(state,action) => {
            if(action.payload.typeSetting === 'invoice') {
                state.invoiceSettings = {...state.invoiceSettings,...action.payload.value}
            }
            if(action.payload.typeSetting === 'general') {
                state.generalSettings = {...state.generalSettings,...action.payload.value}
            }
        }

    },
})

export const {setSettings,updateSettings} = settingSlice.actions;
export default settingSlice.reducer;
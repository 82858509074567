import React, { useState } from 'react'
import ListVendors from './ListVendors'
import CreateVendor from './CreateVendor'
import EditVendor from './EditVendor'

const VendorsHome = () => {

    const [mode,setMode] = useState('List');
    const [idItemSelected,setIdItemSelected] = useState();

    return (
        <>
            {mode === 'List' && <ListVendors setMode={setMode} idItemSelected={idItemSelected} setIdItemSelected = {setIdItemSelected} /> }
            {mode === 'Add' && <CreateVendor setMode={setMode} /> }
            {mode === 'Edit' && <EditVendor id = {idItemSelected} setMode={setMode} /> }
        </>
    );

}

export default VendorsHome

import { useSelector } from 'react-redux';
import useCurrent from './useCurrent';
import { useEffect } from 'react';

const useInvoice = () => {
    const {currentLangId} = useCurrent();
    const settings = useSelector(state => state.settings)
    const SETTING_INVOICE = settings?.invoiceSettings;

    // Initalize the product items list
    const initalProductItem = Array.from(Array(parseInt( SETTING_INVOICE?.INV_ITEMS_ROW_NUMBER) || 10),(e,index)=>{
        return {
            index:index,
            productId:'', 
            productName:'',
            qty:'',
            price:'',
            priceUnit:'',
            productDiscount:'',
            productNetTotal:'',
            productVat:'',
            productNetTotalWithVat:'',
        }
    });

    const toFloat = (valueString,toFixed=true) => {
        if(!SETTING_INVOICE) return 
        const tryToFloat = parseFloat(valueString)
        if(!isNaN(tryToFloat)){
            if( tryToFloat && toFixed ){
                return  tryToFloat.toFixed(SETTING_INVOICE.INV_TO_FIXED_NUMBER)
            }else if( tryToFloat && !toFixed ){
                return  tryToFloat
            }
        }
        return 0;
        
    }
    // Calculate the sum price of the product list
    const calcSumPrice = (productList) => {
        const totalAmount = productList.reduce((accumulatar ,obj) => {
            if( obj !== undefined && obj.price !== undefined && obj.price != null && !isNaN(obj?.price) )
                return  ( toFloat(accumulatar,false) + toFloat( obj?.price,false) )
            else
                return toFloat(accumulatar,false)
        
            },0);
        return toFloat(totalAmount,false);
    }

    const calcVat = (amountValue) => {
        if(!SETTING_INVOICE) return 
        let amountWithVat = 0 ;
        // if(SETTING_INVOICE.PRICE_INCLUDE_VAT === 'true'){
        //     amountWithVat = amountValue * (parseFloat(SETTING_INVOICE.INV_VAT_PERCENT)/toFloat(`1${SETTING_INVOICE.INV_VAT_PERCENT}`,false))
        // }else{
            amountWithVat = amountValue * (parseFloat(SETTING_INVOICE.INV_VAT_PERCENT)/100)

       // }
        return amountWithVat;
    }
    
    useEffect(()=>{
        
    },[])
    
    
    const currency = SETTING_INVOICE?.INV_CURRENCY ;
   // const currency = JSON.parse( SETTING_INVOICE.INV_CURRENCY);
    // Create our number formatter.
    const formatter = new Intl.NumberFormat(`${currentLangId || 'en'}-US`, {
    style: 'currency',
    // currency: currency
    currency: currency || 'SAR'
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });


    return {
        SETTING_INVOICE,
        initalProductItem,
        calcSumPrice,
        toFloat,
        calcVat,
        formatter
    }

}

export default useInvoice
import React from 'react'

const LicenseBar = ({typeLicense,licenseDays}) => {
    return (
        <>
            <span className="px-1 font-bold">تنبية :</span>
            { 
                typeLicense === 'Trail' ?
                <span>هذة النسخة تجريبية صالحة لمدة </span> :
                <span>هذة النسخة  صالحة لمدة </span>
            }
            <span className='px-1 font-bold'>{licenseDays}</span>
            <span>يوم</span>
        </>
    )
}

export default LicenseBar
import { useState } from 'react';
import {MwInputText, MwSwitch, MwTextArea} from '../../components/ui'
import FormLangBar from '../FormLangBar';

const SalesmanForm = ({
    currentLangList,
    currentLangId,
    errors,
    salesmanNo,
    setSalesmanNo,
    fullname,
    setFullname,
    mobileNo,
    setMobileNo,
    email,
    setEmail,
    salesmanNotes,
    setSalesmanNote,
    targetMonthly,
    setTargetMonthly,
    targetYearly,
    setTargetYearly,
    isActive,
    setIsActive,
}) => {

    const [formLang,setFormLang] = useState(currentLangId);

    return (
        <form >
        <div className='p-3 bg-slate-50 rounded-lg mb-3 text-sm flex flex-col gap-1'>
            <div className='py-2'>
                <MwInputText 
                    label='رقم المندوب'
                    id='salesmanNo' 
                    value={salesmanNo} 
                    onChange={(e)=>setSalesmanNo(e.target.value)} />
            </div>
            <div className='py-2'>
            <FormLangBar currentLangList = {currentLangList} formLang = {formLang} setFormLang = {setFormLang} />
            { currentLangList.map((lang) => (
            <div key={lang.langCode} className=''>
                <div className={` ${ formLang === lang?.langCode ? 'block' : 'hidden' } `}>
                    <MwInputText 
                        label={`اسم المندوب (${lang?.langName})`}
                        id={`salesmanName${lang?.langCode}`} 
                        value={fullname[lang?.langCode]}
                        invalid={!!errors[`fullname.${lang?.langCode}`] }
                        invalidMsg = {errors[`fullname.${lang?.langCode}`]}
                        onChange={(e)=>{
                            setFullname({...fullname, [lang?.langCode]:e.target.value});
                        }}
                        />
                </div>
            </div>
            )) }
            </div>
                <div className='py-2'>
                    <MwInputText 
                        label='رقم الجوال'
                        id='mobileNo' 
                        value={mobileNo} 
                        invalid={!!errors.mobileNo}
                        invalidMsg={errors.mobileNo}
                        onChange={(e)=>setMobileNo(e.target.value)} />
                </div>
                <div className='py-2'>
                    <MwInputText 
                        label='البريد الالكتروني'
                        id='email' 
                        value={email} 
                        onChange={(e)=>setEmail(e.target.value)} />
                </div>
                <div className='py-2'>
                    <MwInputText 
                        label='الهدف الشهري'
                        id='targetMonthly' 
                        value={targetMonthly} 
                        onChange={(e)=>setTargetMonthly(e.target.value)} />
                </div>
                <div className='py-2'>
                    <MwInputText 
                        label='الهدف السنوي'
                        id='targetYearly' 
                        value={targetYearly} 
                        onChange={(e)=>setTargetYearly(e.target.value)} />
                </div>
                <div className='py-2'>
                    <MwTextArea 
                        label='ملاحظات'
                        id='salesmanNotes' 
                        value={salesmanNotes} 
                        rows={2}
                        onChange={(e)=>setSalesmanNote(e.target.value)} />
                </div>
                <div className='flex justify-between items-center py-4   mb-2'>
                    <label className='text-xs text-slate-400'>مفعل / غير مفعل</label>
                    <MwSwitch custKey='isActive' isActive={isActive} setIsActive={setIsActive} onChangeSwitch={() => setIsActive(!isActive)} />
                </div>
        </div>
    </form>
    )
}

export default SalesmanForm
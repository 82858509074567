import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

import { Provider } from 'react-redux';
import {store} from './store'

import global_en from './translations/en/global.json' 
import settings_en from './translations/en/settings.json' 
import global_ar from './translations/ar/global.json' 
import settings_ar from './translations/ar/settings.json' 
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation:{ escapeValue: false},
  lng:'en',
  resources:{
    en:{
      global:global_en,
      settings:settings_en,
    },
    ar:{
      global:global_ar,
      settings:settings_ar,
    },
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

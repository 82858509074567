import React from 'react'
import SalesReportsListItem from './SalesReportsListItem'
import { useInvoice } from '../../hooks'

const SalesReportsList = ({data}) => {
    const {formatter} = useInvoice()
    return (
        <div className=' my-5 rounded-lg bg-slate-100'>
            <div className='flex gap-3 justify-between items-center bg-slate-200 p-3 rounded-t-lg text-slate-600  text-sm '>
                <div className='w-20 text-center'>رقم الفاتورة</div>
                {/* <div className='w-24 text-center'>الرقم المرجعي</div> */}
                <div className=' text-center'>تاريخ الفاتورة</div>
                {/* <div className='w-24 text-center'>تاريخ الاستحقاق</div> */}
                <div className='w-20 text-center'>مبلغ الفاتورة</div>
                <div className='w-16 text-center'>الخصم</div>
                <div className='w-24 text-center text-xs'>الإجمالي بعد الخصم</div>
                <div className='w-16 text-center'>الضريبة</div>
                <div className='w-20 text-center'> الإجمالي</div>
                <div className='w-20 text-center'>حالة الدفع</div>
            </div>
            {
                data.data.map(item => (
                    <SalesReportsListItem key={item.id} item={item} formatter={formatter}/>
                ))
            }
        </div>
    )
}

export default SalesReportsList
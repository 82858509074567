
const MwSwitch = ({onChangeSwitch,custKey,isActive,setIsActive}) => {

    const onClickSwitch = () => {
        setIsActive(!isActive);
        onChangeSwitch({key:custKey , value:isActive})
    }

    return (
        <div 
            onClick={() => onClickSwitch()} 
            className={`relative flex h-4 w-12 rounded-lg ${isActive ? 'bg-green-500 justify-end' : 'bg-gray-200' }`}>
            <div className='h-4 w-4 rounded-full bg-gray-300'></div>
        </div>
    )
}

export default MwSwitch
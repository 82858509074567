import {  useTranslation } from 'react-i18next';
import {MwInputText,MwButton} from '../ui'

const PromoCodeBox = (props) => {
    const {
        havePromoCode,
        setHavePromoCode,
        promoCode,
        setPromoCode,
        promoCodeHandler
    } = props
    const [t] = useTranslation('global')
    return (
        <div className='flex items-center text-xs text-slate-400 px-2 pt-2'>
            <div className='font-medium cursor-default' onClick={()=> setHavePromoCode(!havePromoCode) } >{t('invoice.havePromoCode')} </div>
            { havePromoCode && <div className='mx-2 flex border rounded-lg border-slate-200'>
                <MwInputText
                    size='sm'
                    value={promoCode}
                    placeholder={t('invoice.enterPromoCode')}
                    onChange={(e)=> setPromoCode(e.target.value)}
                    classNameInput='ml-1 rounded-none rounded-l '
                />
                <MwButton
                    type = 'secondary'
                    classNameCustom = 'rounded-none rounded-r'
                    size='sm' 
                    onClick={promoCodeHandler}
                    >Check</MwButton>
            </div> }
        </div>
    )
}

export default PromoCodeBox
import React from 'react'
import {QRCodeSVG} from 'qrcode.react';
import {  useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const InvSm = ({
    logo,
    companyName,
    companyCR,
    companyDesc,
    vatNo,
    inv_no,
    inv_date,
    inv_date_due,
    companyAddress,
    qrText,
    invoiceSettings,
    customerName,
    customer,
    salesmanName,
    invProducts,
    isDelivery,
    SETTING_INV_CURRENCY,
    SETTING_INV_VAT_PERCENT,
    formatter,
    totalAmount,
    vatAmount,
    discountAmount,
    netAmount,
    invTerms,
    SETTING_PRICE_INCLUDE_VAT,
    SETTING_SHOW_TERMS_ON_PRINT,
    invPaymentTransactions,
    currentLangId
}) => {

    const [t] = useTranslation('global')
    console.log('invoiceSettings',invoiceSettings);
    let total = 0;

    const invProductsList = invProducts.map((prod) => (
        <div key={prod.id} className='flex justify-between border-b  '>
            <div className='flex-1 p-2 border-e'>{prod.product_name}</div>
            {/* {!isDelivery && <div className='w-20 text-center  border-e p-2'>{formatter.format(prod.price).slice(0,-6)} </div>} */}
            <div className='w-16 text-center border-e p-2'>{prod.qty}</div>
            {/* {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.total_price).slice(0,-6)} </div>} */}
            {/* {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_discount).slice(0,-6)} </div>} */}
            {/* {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_net_total).slice(0,-6)} </div>} */}
            {/* {!isDelivery && <div className='w-24 text-center border-e p-2'>{formatter.format(prod.product_vat).slice(0,-6)} </div>} */}
            {!isDelivery && <div className='w-20 text-center border-e p-2'>{formatter.format(prod.product_net_total_with_vat).slice(0,-6)} </div>}
        </div>
    ));

    const listTransactions = invPaymentTransactions?.map((transaction) =>
    { 
        
        const paymentMethod = transaction.payment_method?.description?.filter(des => des.lang === currentLangId )[0] || transaction.payment_method?.description[0];
        if(transaction.type.trim() === 'out'){

            total += parseFloat(transaction.amount)
        }
        return (
            <div key={transaction.id} >
                {
                    transaction.type.trim() === 'out' && 
                    <div className='w-full flex items-center px-2  gap-2 '>
                        <div >{paymentMethod?.name  || 'Unknown' }</div>
                        {invPaymentTransactions.length > 1 && <div className='w-24 text-center'>{`${formatter.format(transaction.amount)} `}</div> }
                    </div>
                }
            </div>
        );
    });
    return (
        <div className='w-full text-xs'>
            <div id='headerInvoice' >
                <div className='flex flex-col gap-3 '>
                    <div className='flex flex-col gap-3'>
                        {invoiceSettings?.SHOW_LOGO_ON_PRINT === 'true' && <div className='flex justify-center'><img src={logo} className='w-40' /></div> }
                        <div className='ps-2 '>
                            <div className='font-bold py-2 text-sm' >{companyName}</div>
                            <div className='flex flex-col gap-1 text-xs text-gray-500'>
                                <div>{companyDesc}</div>
                                <div className='flex items-center justify-between gap-3'>
                                    <span>   سجل تجاري:</span>
                                    <span className='font-bold'>{companyCR}</span>
                                    <span>:.C.R </span>
                                </div>
                                <div className='flex items-center justify-between gap-3'>
                                    <span>  الرقم الضريبي:</span>
                                    <span className='font-bold'>{vatNo}</span>
                                    <span>:VAT NO</span>
                                </div>
                                <div >{companyAddress}</div>
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center justify-center'>
                        <div className='text-sm font-bold items-center  flex flex-col '>
                            <span>فاتورة مرتجع</span>
                            <span>ٍReturn Invoice</span>
                        </div>
                    </div>
                    <div className='flex justify-center mb-3'>
                        <QRCodeSVG size={120}  value={qrText}  />
                    </div>
                </div>
                <div  className='flex flex-col gap-2   text-xs pt-1 '>
                    <div className='flex gap-3  justify-between '>
                        <span>تاريخ الاصدار</span>
                        <span className='font-bold'>{inv_date.substring(0,10)}</span>
                        <span>ISSUE DATE</span>
                    </div>
                    <div className='flex gap-3  justify-between '>
                        <span> رقم الفاتورة</span>
                        <span className='font-bold'>{inv_no}</span>
                        <span>INVOICE NO</span>
                    </div>
                    {/* <div className='flex  gap-3  justify-between'>
                        <span>تاريخ الاستحقاق</span>
                        <span className='font-bold'>{inv_date_due}</span>
                        <span>DUE DATE</span>
                    </div> */}
                </div>
            </div>
            <div id='bodyInvoice'  >
                <div className='flex flex-col gap-2 pt-2  '>
                    {customer && customerName && 
                        <div className='flex items-center justify-between gap-2 '> 
                        <div className='flex flex-col text-right'>
                            <span>اسم العميل</span>
                            <span>CUSTOMER NAME</span>
                        </div>
                            <span className='font-bold'>{customer?.fullname[0].text || customer?.fullname[1].text }</span>
                        </div>
                    }
                    {customer?.customerNo && 
                        <div className=' flex items-center justify-between gap-2 '>
                            <div className='flex flex-col text-right'>
                                <span>رقم العميل</span>    
                                <span >CUSTOMER NO</span>
                            </div>
                            <span className='font-bold'>{customer.customerNo}</span>
                        </div>
                    }
                    {customer?.customerVatNo && 
                        <div className=' flex-1 flex items-center justify-between gap-2'>
                            <div className='flex flex-col text-right'>
                                <span className='text-xs'>الرقم الضريبي للعميل</span>
                                <span>CUSTOMER VAT NO</span>
                            </div>
                            <span className='font-bold'>{customer.customerVatNo}</span>
                        </div>}
                    {/* {salesmanName && <div>{t('invoice.Salesman')}: {salesmanName}</div>} */}
                </div>
                <div  className='flex justify-between items-center mt-4 border ps-2 '>
                    <div className='flex-1  flex flex-col'>
                        <span>اسم المنتج </span>
                        <span>PRODUCT NAME</span>
                    </div>
                    {/* {
                    !isDelivery && 
                        <div className='w-20 text-center flex flex-col '>
                            <span>سعر الوحدة</span>
                            <span>PRICE UNIT</span>
                        </div>
                    } */}
                    <div className='w-16 text-center flex flex-col'>
                        <span>{t('invoice.quantity')}</span>
                        <span>QUANTITY</span>
                    </div>
                    {/* {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>السعر</span>
                        <span>PRICE</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>الخصم</span>
                        <span>PRICE</span>
                        </div>}
                    {!isDelivery && <div className='w-24 text-center flex flex-col'>
                        <span>إج. بعد الخصم </span>
                        <span>T.A DISCOUNT</span>
                        </div>} */}
                    {/* {!isDelivery && <div className='w-24 text-center  flex flex-col'>
                        <span>الضريبة ({SETTING_INV_VAT_PERCENT}%)</span>
                        <span>VAT ({SETTING_INV_VAT_PERCENT}%)</span>
                        </div>} */}
                    {!isDelivery && <div className='w-20 text-center flex flex-col'>
                        <span>السعر</span>
                        <span>PRICE</span>
                        </div>}
                </div>
                <div className='border '>{invProductsList}</div>
            </div>
            <div id='footerInnvoice'  >
                { !isDelivery &&
                    <div> 
                        <div className='my-3 border rounded text-xs'>
                            
                            {
                                discountAmount ? 
                                <div className='flex-col'>
                                    <div className='flex justify-between px-3 py-2 border-b'>
                                        <div className='flex flex-col'>
                                            <span>الإجمالي قبل الخصم </span>
                                            <span>TOTAL BEFORE DISCOUNT</span>
                                        </div>
                                        <div className='flex flex-col justify-center '>{ formatter.format(totalAmount)  } </div>
                                    </div>
                                    <div className='flex justify-between px-3 py-2 border-b'>
                                        <div className='flex flex-col'>
                                            <span> الخصم </span>
                                            <span>DISCOUNT</span>
                                        </div>
                                        <div className='flex flex-col justify-center '>{formatter.format(discountAmount)}</div>
                                    </div>
                                    <div className='flex justify-between px-3 py-2 border-b'>
                                        <div>{t('invoice.TOTAL_AFTER_DISCOUNT')}</div>
                                        <div className=''>{formatter.format(totalAmount - discountAmount )} </div>
                                    </div>
                                </div> : 
                                <div className='flex justify-between px-3 py-2 border-b'>
                                    <div className='flex flex-col'>
                                        <span>الإجمالي الفرعي </span>
                                        <span>SUB TOTAL</span>
                                    </div>
                                    <div className='flex flex-col justify-center '>{formatter.format(totalAmount)}  </div>
                                </div>
                            }
                            <div className='flex justify-between px-3 py-2 border-b'>
                                <div className='flex flex-col'>
                                        <span> ضريبة القيمة المضافة ({SETTING_INV_VAT_PERCENT}%)</span>
                                        <span>VAT ({SETTING_INV_VAT_PERCENT}%)</span>
                                    </div>
                                <div className='flex flex-col justify-center '>{formatter.format(vatAmount)} </div>
                            </div>
                            <div className='flex justify-between px-3 py-2 border-b'>
                                <div className='flex flex-col'>
                                        <span>الإجمالي شامل الضريبة </span>
                                        <span>TOTAL</span>
                                    </div>
                                <div className='font-bold flex flex-col justify-center '>{formatter.format(netAmount)}  </div>
                            </div>
                        </div>
                        <div className='pt-2 flex flex-col justify-center items-center'>
                                        { SETTING_PRICE_INCLUDE_VAT === 'true' ?
                                            <div className='flex justify-center items-center text-xs text-slate-400 '>{t('invoice.priceIncludesVat')}</div> : 
                                            <div className='text-xs text-slate-400 text-center'>{t('invoice.priceNotIncludesVat')}</div> 
                                            
                                        }
                                    </div>
                    </div>
                }
                { SETTING_SHOW_TERMS_ON_PRINT === 'true' && invTerms && !isDelivery && 
                    <div className='text-xs py-5'>
                        <div className='font-bold '>- {t('invoice.ConditionAndTerms')}</div>
                        <div className='pt-1 px-2 '>{ parse(invTerms.replaceAll('\n','<br/>')) }</div>
                    </div>
                }
            </div>
            <div id='transactions' className=' pt-2'>
                <div>طرق الدفع : </div>
                <div className='py-2'>{listTransactions}</div>
            </div>

        </div>
    )
}

export default InvSm
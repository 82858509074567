import React from 'react'
import InvFormListItem from '../../invoice/InvFormListItem'
import { MwButton } from '../../ui'
// import {updateSettings} from '../../../store/settingSlice'
// import { useDispatch } from 'react-redux';


const InvFormBody = ({
    initalProductItem,
    reloadProductList,
    showModalProductHandle,
    productList,
    setProductList,
    formErrors
}) => {
    //const dispatch = useDispatch()

    const addewItemHandler = () => {
    console.log('addewItemHandler')    
    // dispatch(updateSettings({
    //     typeSetting : 'invoice', value :{
    //         INV_ITEMS_ROW_NUMBER : productList.length + 1
    //     }
    // }));

    } 
    return (
        <>
        <hr />
        <div id='inv-body' className='relative  h-96 overflow-y-auto ' >
                    { initalProductItem.map((e,index)=>{
                        return <InvFormListItem
                                    key = {index}
                                    index = {index}
                                    reloadProductList = {reloadProductList}
                                    showModalProductHandle = {showModalProductHandle}
                                    productList = {productList}
                                    setProductList = {setProductList}
                                    formErrors = {index ===0 && formErrors}
                                    />
                        })
                    }
        {/* <div className='absolute bottom-2 end-2'>
            <MwButton
                actionType='button'
                onClick={ addewItemHandler}
                >جديد</MwButton>
        </div> */}
        </div>
        <hr />
        </>
    )
}

export default InvFormBody
import { useEffect, useState } from 'react'
import { useDispatch,useSelector } from 'react-redux';

import {setCurrentCompany} from '../store/currentCompanySlice.js'
import {setCurrentBranch} from '../store/currentBranchSlice.js'
import {getCompanyById} from '../utils/tenantManager.js'
import {setSettings} from '../store/settingSlice.js'
import {setLang} from '../store/langSlice.js'

import {arrayNameByLang,arrayToObjKeyValue} from '../utils/global.js'
import useLicense from './useLicense.js';

const useStartup = () => {
    
    const dispatch = useDispatch();
    const [startup,setStartup] = useState(false);
    const user = useSelector(state => state.auth).user
    const lang = useSelector(state => state.lang) 

    const userSettings = arrayToObjKeyValue(JSON.parse(localStorage.getItem('user'))?.userSettings);
    const DEFAULT_LANG = userSettings?.DEFAULT_LANG || 'ar';

    // User Company and branch to set initial state  
    const userCompany = getCompanyById(user?.company_id)
    const userCompanyName = userCompany && arrayNameByLang(userCompany?.name,lang.value)?.text;
    const userBranch =  userCompany?.branches?.filter((branch) => {
        if(branch._id === user?.branch_id){
            return branch;
        }
    })[0];

    const userBranchName = userBranch && arrayNameByLang(userBranch?.name,lang.value)?.text;

    const invoiceSettings = userCompany && arrayToObjKeyValue(userCompany?.settings.invoice);
    const generalSettings = userCompany && arrayToObjKeyValue(userCompany?.settings.generalSettings);
    
    const {data:dataLicense} = useLicense();

    useEffect(() =>{
        const pathArray = window.location.pathname.split('/');
        if(pathArray.length > 2 && pathArray[pathArray.length-2] === 'token'){
            setStartup(true)
        }
    },[dataLicense]);
    
    useEffect(()=>{
        if(userCompany){
            dispatch(setLang({
                label : DEFAULT_LANG === 'ar' ? 'Arabic' : 'English'  ,
                value : DEFAULT_LANG
            }))
            dispatch(setCurrentCompany({
                name:userCompanyName,
                value:userCompany._id
            }));
    
            dispatch(setCurrentBranch({
                name:userBranchName,
                value:userBranch._id
            }));
    
            dispatch(setSettings({
                invoiceSettings ,
                generalSettings
            }));


    
            setStartup(true)
        }

    },[user]);

    return {startup}
}

export default useStartup
import React, { useEffect, useState } from 'react'
import { MwButton, MwInputText } from '../ui'
import { useCurrent, useFetch, usePut } from '../../hooks';

const UpdateVatBar = ({setShow}) => {

    const [vatNumber,setVatNumber] = useState();
    const  {currentCompanyId,currentTenantId} = useCurrent();
    const {data:resultPut,loading,putData} = usePut();
    const {data:dataTenantFetch,loading:loadingTenantFetch,fetchData} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/tenants/getById/${currentTenantId}`,false);

    // vatNumber
    const onSubmitHandler = (e) => {
        e.preventDefault();
        const _data = {
            companyId : currentCompanyId,
            companyData : {
                vatNumber: vatNumber
            }
        }
        putData(`${process.env.REACT_APP_Auth_API_BASE_URL}/tenants/companies/${currentTenantId}`,_data);
    }

    useEffect(() => {
        if(resultPut && !loading){
            console.log('Vat Number Updated Success');
            localStorage.setItem('isVatNnumberUpdated',true);
            fetchData();
            
        }
    },[resultPut]);

    useEffect(()=>{
        if(dataTenantFetch){
            console.log('dataTenantFetch',dataTenantFetch);
            localStorage.setItem('tenant',JSON.stringify(dataTenantFetch.data));
            setShow(false);
        }
    },[dataTenantFetch]);

    return (
        <div >
            <form onSubmit={onSubmitHandler} className='flex justify-center items-center bg-slate-100 rounded-lg  '>
                <span className='text-xs text-slate-500 px-2 '>
                    من فضلك يرجي 
                    ادخل الرقم الضريبي 
                </span>
                <div className='flex'>
                    <div className='flex flex-col'>
                        <MwInputText
                            inputType='number'
                            size='sm' 
                            classNameInput="w-72 "
                            placeholder = "00000000000"
                            value={vatNumber}
                            onChange={(e)=>setVatNumber(e.target.value)}
                            // invalid={true}
                            // invalidMsg='الرقم الضريبي ييجب ان يتكون من ١٥ '
                            />
                    </div>
                    <MwButton size='sm' type="saveBtn" inGroup={true}  >حفظ</MwButton>
                </div>
            </form>
        </div>
    )
}

export default UpdateVatBar
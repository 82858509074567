import React, { useState } from 'react'

import {LiaUsersCogSolid} from 'react-icons/lia'
import {MdGroups2} from 'react-icons/md'
import {FaUsersBetweenLines} from 'react-icons/fa6'
import VendorsGroups from '../components/Vendors/groups/VendorsGroups';
import VendorsHome from '../components/Vendors/VendorsHome';

const Vendors = () => {
    const [tabActive,setTabActive] = useState('vendors');

 

    return (
        <>
            <div className='flex justify-between items-center pb-3'>
                <div className=' flex gap-1 px-2 text-sm text-gray-400 '>
                    <LiaUsersCogSolid size={18} />
                    إدارة الموردين </div>
            </div>
            <div id='Tabs' className='w-full flex mt-3 gap-3  border-b'>
                <div onClick={()=>setTabActive('vendors')} className={`flex-1 flex flex-col gap-1 items-center justify-center rounded-t-lg text-slate-500  p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive === 'vendors' ? ' bg-indigo-100 text-indigo-800 font-bold' : 'bg-slate-50'}`}>
                    <FaUsersBetweenLines  size={28}/>
                    <hr />
                    <span className='text-sm '> الموردين </span>
                </div>
                <div onClick={()=>setTabActive('Groups')} className={`flex-1 flex flex-col gap-1 items-center justify-center rounded-t-lg text-slate-500  p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive === 'Groups' ? 'bg-indigo-100 text-indigo-800 font-bold' : 'bg-slate-50'}`}>
                    <MdGroups2  size={28}/>
                    <hr />
                    <span className='text-sm  '> مجموعات الموردين </span>
                </div>
            </div>
            <div className='text-slate-500 bg-slate-50 rounded-b-lg text-xs p-3 py-5 '>
                { tabActive === 'vendors' && <VendorsHome/> }
                { tabActive === 'Groups' && <VendorsGroups /> }
            </div>
        </>
    )
}

export default Vendors
import React, { useEffect,useState } from 'react'
import CreatePaymentMethod from '../../components/payment/CreatePaymentMethod'
import PaymentMethodList from '../../components/payment/PaymentMethodList'
import { useFetch,usePost,useCurrent,useDelete,usePut } from '../../hooks';
import { MwSpinner,ConfirmDelete } from '../../components/ui';
import UpdatePaymentMethod from '../../components/payment/UpdatePaymentMethod';

const PaymentMethod = () => {
    const {currentTenantId,currentCompanyId,currentBranchId,userId,currentLangList,currentLangId} =  useCurrent()
    const {data:paymentMethods,loading,refreshHandler} = useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/payment-methods/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)
    const {data:dataCreate,loading:loadingCreate,postData:postCreate} = usePost();
    const {data:dataUpdate,loading:loadingUpdate,putData:putData} = usePut();
    const [itemSelected,setItemSelected] = useState({});
    const {deleteItem,loading:loadingDelete} = useDelete();
    const [isConfirmDelete,setIsConfirmDelete] = useState(false);
    const [mode,setMode] = useState('Adding');


    useEffect(() => {
        console.log(paymentMethods)
    },[paymentMethods]);


    useEffect(() => {
        if(!loadingUpdate){
            refreshHandler();
            setMode('Adding');
        }
    },[dataCreate,loadingDelete,loadingUpdate]);

    const confirmDeleteHandler = (item) =>{
        setItemSelected(item)
        setIsConfirmDelete(true)
        console.log(isConfirmDelete)
    }

    const deleteHandler = () =>{
        const id = itemSelected?.id;
        console.log(itemSelected);
        const url = `${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/payment-methods/delete/${id}`;
        deleteItem(url);
    }

    const editHandler = (item) => {
        setItemSelected(item)
        setMode('Editing')
    }

    useEffect(() => {
        if(!loadingDelete && isConfirmDelete){
            setIsConfirmDelete(false);
        }
    },[loadingDelete]);

    return (
        <div className=''>
            <ConfirmDelete 
                onShow={isConfirmDelete} 
                setOnShow={setIsConfirmDelete}  
                title={`Deleting...`}
                msg={`Are you sure you want to delete`}
                onDeleteHandler={deleteHandler}
                loading ={loadingDelete}
                />
            { mode === 'Adding' ? <CreatePaymentMethod
                currentLangList = {currentLangList} 
                currentTenantId = {currentTenantId}
                currentCompanyId = {currentCompanyId}
                currentBranchId = {currentBranchId}
                userId = {userId}
                data = {dataCreate}
                loading = {loadingCreate}
                postData = {postCreate}
            /> : <UpdatePaymentMethod
                    currentLangList = {currentLangList} 
                    currentTenantId = {currentTenantId}
                    currentCompanyId = {currentCompanyId}
                    currentBranchId = {currentBranchId}
                    userId = {userId}
                    currentItem = {itemSelected}
                    data = {dataUpdate}
                    loading = {loadingUpdate}
                    putData = {putData}
                />
            }
            <div className='h-8'></div>
            { !!paymentMethods ? <PaymentMethodList 
                                confirmDeleteHandler ={confirmDeleteHandler} 
                                editHandler = {editHandler}
                                data={paymentMethods} 
                                loading={loading} 
                                currentLangId={currentLangId}
                                /> : <div className='h-40'> <MwSpinner hightScreen={false}/>  </div>}
        </div>
    )
}

export default PaymentMethod
import {LangArrayToObjKeyValue} from '../../utils/global'
import {BiShow} from 'react-icons/bi'
import {AiOutlineEdit} from 'react-icons/ai'
import {RiDeleteBin6Line} from 'react-icons/ri'
import {Link, useParams } from 'react-router-dom';

const ListSalesmanItem = ({item,currentLangId,currentCompanyId,currentBranchId,editHandeler,deleteHandeler}) => {
    const params = useParams();

    return (
        <div 
            className='flex justify-between items-center border-t p-2 hover:bg-indigo-50 hover:font-bold'>
            <div className='flex-1'>{LangArrayToObjKeyValue(item.fullname)?.[currentLangId] || item?.fullname[0]?.text || item?.fullname[1]?.text }</div>
            {/* <div className='w-24 text-center'>{item.mobileNo}</div> */}
            <div className='w-20 text-center'>{item.createdAt.substring(0,10)}</div>
            <div className='w-42 flex justify-center gap-3'>
                <div className={` w-20 text-center px-2 pb-1 rounded-lg   ${item.isActive ? ' bg-green-200 ' : 'bg-slate-200' }`}>{item.isActive ? 'مفعل' : 'غير مفعل'}</div>
                {/* <Link  to={`/${params.tenant}/salesmans/show/${item._id}/${currentCompanyId}/${currentBranchId}`}> <BiShow size={18}/></Link> */}
                <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>editHandeler(item._id)}><AiOutlineEdit size={18}/></div>
                <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>deleteHandeler(item._id)}><RiDeleteBin6Line size={18} /></div>
            </div>
        </div>
    )
}

export default ListSalesmanItem
import { useState } from 'react'
import {LuLayoutDashboard} from 'react-icons/lu'
import ProductsNavbar from '../../components/Products/ProductsNavbar';
import ProductsCategories from './ProductsCategories';
import ProductsBrands from './ProductsBrands';
import ProductsTags from './ProductsTags';
import Products from './Products';

const ProductsHome = () => {

    const [tabActive,setTabActiive] = useState('Products');

    return (
        <>
            <div className='flex justify-between items-center pb-3'>
                    <div className=' flex gap-1 px-2 text-sm text-gray-400 '>
                        <LuLayoutDashboard size={18} />
                        إدارة المنتجات والخدمات 
                    </div>
            </div>
            
            <ProductsNavbar tabActive={tabActive} setTabActiive={setTabActiive}   />

            <div id='tab' className='bg-slate-50 p-2 pt-5 rounded-b-lg'>
                <div id='Products'  
                    className={`flex flex-col gap-3 ${tabActive === 'Products' ? 'block' : 'hidden'} `}>
                    <Products/>
                </div>
                <div id='Categories'  
                    className={`flex flex-col gap-3 ${tabActive === 'Categories' ? 'block' : 'hidden'} `}>
                        <ProductsCategories/>
                </div>
                <div id='Brands'  
                    className={`flex flex-col gap-3 ${tabActive === 'Brands' ? 'block' : 'hidden'} `}>
                        <ProductsBrands/>
                </div>
                <div id='Tags'  
                    className={`flex flex-col gap-3 ${tabActive === 'Tags' ? 'block' : 'hidden'} `}>
                        <ProductsTags/>
                </div>
            </div>
        </>
    );
}

export default ProductsHome
import { useState } from "react"
import CreateCategory from "../../components/Products/category/CreateCategory"
import ListCategories from "../../components/Products/category/ListCategories";
import EditCategory from "../../components/Products/category/EditCategory";

const ProductsCategories = () => {
    const [mode,setMode] = useState('List');
    const [idSelected,setIdSelected] = useState();
    return (
        <>
            <div className="p-3">
                { mode === 'List' && <ListCategories idSelected = {idSelected} setIdSelected = {setIdSelected} setMode={setMode} /> }
                { mode === 'Add' && <CreateCategory setMode={setMode} /> }
                { (mode === 'Edit' && idSelected) && <EditCategory id={idSelected} setMode={setMode} /> }
            </div>
        </>
    )
}

export default ProductsCategories
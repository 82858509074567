import { useSelector,useDispatch } from "react-redux"; 
import {arrayNameByLang} from '../utils/global.js'
import {useLang} from '../hooks/'
import { useEffect, useState } from "react";
import {setCurrentCompany} from '../store/currentCompanySlice.js'

const useTenant = () => {
    const dispatch = useDispatch();

    const {langSelected} = useLang();

    const [loading,setLoading] = useState(true)
    const [tenant,setTenant] = useState({});
    const [companiesList,setCompaniesList] = useState([]);
    const [tenantId,setTenantId] = useState();
    const [tenantUsername,setTenantUsername] = useState();
    const [allAppSetting,setAllAppSetting] = useState();
    const [initialCompanyName,setInitialCompanyName] = useState();
    const [initialCompanyId,setInitialCompanyId] = useState();
    const [getSettingsByCompanySelected,setSettingsByCompanySelected] = useState({});

    const companySelected  = useSelector((state)=>state.currentCompany)
    const branchSelected = useSelector((state)=>state.currentBranch)

    const getCompanyById = (id) => tenant?.companies?.filter(company => company._id === id)[0]
    const companyNameByLang =  (company) => company?.name?.filter((companyname) => companyname.lang === langSelected.value )[0]
    const currentCompany = getCompanyById(companySelected?.value)

    const tenantCompaniesByLang = (lang) => {
        return tenant?.companies?.map( company => { 
                return {
                    companyId:company._id ,
                    companyName: arrayNameByLang(company.name,lang)
                }
            });
    }
    const companiesListHandler = () => tenantCompaniesByLang(langSelected.value)?.map( (item) => {
        return {label : item?.companyName?.text , value : item?.companyId }
    });

   // const [companiesList,setCompaniesList] = useState(companiesListHandller)

    const BranchesByCompanyId = (companyId) => tenant?.companies?.filter( (company) => company._id === companyId)[0]?.branches;

    const branchesList = BranchesByCompanyId(companySelected.value)?.map(branch => {
        const branchId = branch._id
        const branchName = branch.name.filter(name => name.lang === langSelected.value)[0].text;
        return {
            label : branchName,
            value: branchId
        }
    })
    
useEffect(() => {
    const tenantHandler = () => JSON.parse( localStorage.getItem('tenant'))
    if(tenantHandler()){
        setTenant(tenantHandler())
    }
},[]);    

useEffect(() => {
    if(Object.keys(tenant).length > 0) {
        setTenantId(tenant?._id);
        setTenantUsername(tenant?.username);
        setAllAppSetting(tenant?.setting?.appSetting);
        setInitialCompanyName(tenant?.companies[0]?.name[0]?.text);
        setInitialCompanyId(tenant?.companies[0]?.value);
        setSettingsByCompanySelected(currentCompany)
        setCompaniesList(companiesListHandler())
        dispatch(setCurrentCompany({name:companiesListHandler()[0]?.label,value:companiesListHandler()[0]?.value}));
        setLoading(false);
    }
},[tenant,getSettingsByCompanySelected]);

    return {
            loading,
            tenant,
            tenantId,
            tenantUsername,
            allAppSetting,
            initialCompanyName,
            initialCompanyId,
            companiesList,
            branchesList,
            tenantCompaniesByLang,
            BranchesByCompanyId,
            getCompanyById,
            companyNameByLang,
            getSettingsByCompanySelected,
            companySelected,
            branchSelected
        }
}

export default useTenant
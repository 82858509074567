import React from 'react'
import {  useTranslation } from 'react-i18next';

import {MwInputText,MwSelector} from '../../ui';

const InvFormHeader = ({
    invNo,
    setInvNo,
    invRef,
    invDate,
    invDateDue,
    invCurrency,
    customers,
    onClickNewCustomer,
    salesmans,
    onClickNewSalesman,
    customerSelected,
    setCustomerSelected,
    salesmanSelected,
    setSalesmanSelected,
    formErrors,
    SETTING_INVOICE

    }) => {
        const [t] = useTranslation('global')

        const date = new Date();
        const today = date.toLocaleDateString('en-CA'); // Get current date in the format 'YYYY-MM-DD'
        const todayAndTime = date.toLocaleDateString('en-CA')+' '+date.toLocaleTimeString('it-IT'); // Get current date in the format 'YYYY-MM-DD'
        // invDate.current.value = todayAndTime;
        // invDateDue.current.value = today
        console.log('SETTING_INVOICE',SETTING_INVOICE.INV_SALESMAN_DEFAULT)
    return (
            <div className="flex flex-col md:flex-row justify-between  items-center gap-2 my-2">
                <div className="flex w-full md:w-fit gap-2 " >
                    <div className="flex-1 md:w-24   ">
                        <MwInputText 
                            label={t('invoice.invoiceNo')}
                            id='inv_no' 
                            value={invNo} 
                            disabled={true} 
                            onChange={(e)=>setInvNo(e.target.value)} />
                    </div>
                    <div className="flex-1 md:w-20">
                        <MwInputText 
                            label={t('invoice.invoiceRef')}
                            inputRef={invRef}
                            id='inv_ref' 
                            defaultValue=''
                            />
                    </div>
                </div>
                    <div className="flex w-full md:w-fit " >
                    <div className="flex-1 md:w-38 ">
                        <MwInputText 
                            label={t('invoice.invoiceDate')}
                            // inputType='date'
                            inputRef={invDate} 
                            id='inv_date'
                            defaultValue={todayAndTime}
                            invalid = {!!formErrors?.invDate}
                            disabled = {true}
                            // invalidMsg={formErrors?.invDate}
                            />
                    </div>
                    <div className="flex-1 md:w-28">
                        <MwInputText 
                            label={t('invoice.invoiceDateDue')}
                            inputType='date'
                            inputRef={invDateDue} 
                            id='inv_date_due'
                            defaultValue={today}
                            invalid = {!!formErrors?.invDateDue}
                            />
                    </div>
                </div>
                <div className="relative flex-1  flex w-full ">
                    <div className="flex-1 flex  flex-col ">
                        <MwSelector 
                            label={`أختار المورد`}
                            _data={customers} 
                            dataType='customer' 
                            onClickNew = {onClickNewCustomer}
                            selectedItem = {customerSelected}
                            setSelectedItem = {setCustomerSelected}
                            selectFirstValue = {SETTING_INVOICE?.INV_CUSTOMER_DEFAULT}
                            />
                    </div>
                    {/* <div className="flex-1 flex flex-col ">
                        <MwSelector
                        label={t('invoice.selectSalesman')}
                        _data={salesmans} 
                        dataType='salesman' 
                        onClickNew={onClickNewSalesman}
                        selectedItem = {salesmanSelected}
                        setSelectedItem = {setSalesmanSelected}
                        selectFirstValue = {SETTING_INVOICE.INV_SALESMAN_DEFAULT}
                        />
                    </div> */}
                </div>
            </div>
        
    )
}

export default InvFormHeader
import  { useEffect, useState } from 'react'
import {MwButton} from '../../components/ui'
import {langObjType,objLangTextHandler} from '../../utils/global'
import {AiOutlineSave} from 'react-icons/ai'
import BaiscInfo from './forms/BaiscInfo'
import StockAndPrice from './forms/StockAndPrice'
import SEO from './forms/SEO'
import ProductLinks from './forms/ProductLinks'
import { useCurrent, usePost } from '../../hooks'
import CardHeadeWithActions from '../ui/CardHeadeWithActions'

const CreateProduct = ({onHide,onProductCreated,setReloadProductList,setMode}) => {
   
    const [tabActive,setTabActive] = useState('TabOne'); // tabs [ TabOne , TabTwo ]
    const [errors,setErrors] = useState({});
    const {currentTenantId,currentCompanyId,currentLangId} = useCurrent();
    const {data:resultPost,postData} = usePost();
    // Start Form state //////
    const [productCode,setProductCode] = useState('');
    const [productBarcode,setProductBarcode] = useState('');
    const [productFactoryCode,setProductFactoryCode] = useState('');
    const [productName,setProductName] = useState(langObjType());
    const [productDesc,setProductDesc] = useState(langObjType());
    const [productPrice,setProductPrice] = useState('');
    // const [productMetadata,setProductMetadata] = useState('');
    const [isActive,setIsActive] = useState(true);
    const [isShowOnWebsite,setIsShowOnWebsite] = useState(false);
    const [qty,setQty] = useState(0);
    const [productMetaKeywords,setProductMetaKeywords] = useState(langObjType());
    const [productMetaDesc,setProductMetaDesc] = useState(langObjType());
    const [isStock,setIsStock] = useState(false);
    const [codeInStock,setCodeInStock] = useState();
    const [weightProduct,setWeightProduct] = useState();
    const [sizeProduct,setSizeProduct] = useState();
    // Product Links state
    const [productCategorySelected,setProductCategorySelected] = useState([]);
    const [productBrandSelected,setProductBrandSelected] = useState();
    const [productTags,setProductTags] = useState([]);

    const cancelHandle = () => {
        onHide();
    }

    const saveHandler = async (e) => {
        e.preventDefault(true);
        let errorObj = {};
        if(!productName[currentLangId]){
            errorObj = { ...errorObj, [`productName.${currentLangId}`] : 'You must specify a product name'};
            //setCurrentLang(defaultLang)
        }
        if(!productPrice){
            errorObj = {...errorObj, price:'You must specify a product price'}
            productName[currentLangId] && setTabActive('TabTwo');
        }else{
            if( isNaN(parseFloat(productPrice))){
                errorObj = {...errorObj, price:' Price is not a number  '}
                productName[currentLangId] && setTabActive('TabTwo');
            }
        }
        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            console.log('errors',errors)
            return
        }
        onHide && setReloadProductList(false)
        const _data = {
            productName : objLangTextHandler(productName) ,
            productDesc :objLangTextHandler(productDesc),
            productMetaKeywords :objLangTextHandler(productMetaKeywords),
            productMetaDesc :objLangTextHandler(productMetaDesc),
            productTags: JSON.stringify(productTags),
            productCategory: productCategorySelected?.map((item) => item['value'] ),
            productBrand:productBrandSelected?.value,
            productCode,
            productBarcode,
            productFactoryCode,
            codeInStock,
            weightProduct,
            sizeProduct,
            price:productPrice,
            qty:qty,
            isStock,
            isShowOnWebsite,
            isActive,
            tenantId:currentTenantId,
            companyId : currentCompanyId
        };
        console.log(_data);
        postData(`${process.env.REACT_APP_PRODUCTS_API_SERVER_BASE_URL}`, _data);
        // const addprod = await addProduct(_data);
        
        

    }

    useEffect(()=>{
        if(resultPost){
            if(resultPost.status === 1){
                localStorage.setItem('isProductsUpdated','true')
                onHide && cancelHandle();
            }else{
                console.log('Error:',resultPost)
            }
            onHide ? onProductCreated() : setMode('List') ;
        }
    },[resultPost]);

    return (
        <div className={`${onHide && 'p-5'}`} >
            
            <CardHeadeWithActions title={`أضف منتج جديد`}>
                        { onHide && <MwButton inGroup={true}  type='cancelBtn' onClick={cancelHandle}>الغاء</MwButton> }
                        <MwButton type="cancelBtn" inGroup={true} onClick = {()=>setMode('List')} >ادارة المنتجات</MwButton>
                        <MwButton inGroup={true}  type='saveBtn' onClick={saveHandler}> حفظ  <AiOutlineSave size={18}/> </MwButton>
            </CardHeadeWithActions>

            <form  >
                <div className='bg-slate-50 rounded-lg text-sm flex flex-col justify-between items-between p-2'>
                    <ul className='flex sticky top-8 text-xs'>
                        <li 
                            onClick={()=>setTabActive('TabOne')} 
                            className={`cursor-default	px-2 py-1 m-1   text-gray  hover:text-sky-700  ${tabActive === 'TabOne' && ' text-sky-900 border-sky-900 border-b'} `}>
                            المعلومات الاساسية
                        </li>
                        <li 
                            onClick={()=>setTabActive('TabTwo')}  
                            className={ `cursor-default	px-2 py-1 m-1   text-gray  hover:text-sky-700 ${tabActive === 'TabTwo' && 'text-sky-900 border-sky-900 border-b'}`}>
                                المستودع والتسعير
                        </li>
                        <li 
                            onClick={()=>setTabActive('TabThree')}  
                            className={ `cursor-default	px-2 py-1 m-1   text-gray  hover:text-sky-700 ${tabActive === 'TabThree' && 'text-sky-900 border-sky-900 border-b'}`}>
                                روابط المنتج 
                        </li>
                        <li 
                            onClick={()=>setTabActive('TabFour')}  
                            className={ `cursor-default	px-2 py-1 m-1   text-gray  hover:text-sky-700 ${tabActive === 'TabFour' && 'text-sky-900 border-sky-900 border-b'}`}>
                              محركات البحث
                        </li>
                        <li 
                            onClick={()=>setTabActive('TabFive')}  
                            className={ `cursor-default	px-2 py-1 m-1   text-gray  hover:text-sky-700 ${tabActive === 'TabFive' && 'text-sky-900 border-sky-900 border-b'}`}>
                                صور المنتج
                        </li>
                    </ul>
                    <div id='content-body' >
                        {
                            tabActive === 'TabOne' && <BaiscInfo     
                                                            productName = {productName}
                                                            setProductName = {setProductName}
                                                            productDesc = {productDesc}
                                                            setProductDesc = {setProductDesc}
                                                            isShowOnWebsite = {isShowOnWebsite}
                                                            setIsShowOnWebsite = {setIsShowOnWebsite}
                                                            isActive = {isActive}
                                                            setIsActive = {setIsActive}
                                                            errors = {errors}
                                                            />
                            
                        }
                        {
                            tabActive === 'TabTwo' && <StockAndPrice
                                                            productCode = {productCode}
                                                            setProductCode = {setProductCode}
                                                            productPrice = {productPrice}
                                                            setProductPrice = {setProductPrice}
                                                            productBarcode = {productBarcode}
                                                            setProductBarcode = {setProductBarcode}
                                                            productFactoryCode = {productFactoryCode}
                                                            setProductFactoryCode = {setProductFactoryCode}
                                                            isStock = {isStock}
                                                            setIsStock = {setIsStock}
                                                            codeInStock = {codeInStock}
                                                            setCodeInStock = {setCodeInStock}
                                                            qty = {qty}
                                                            setQty = {setQty}
                                                            weightProduct = {weightProduct}
                                                            setWeightProduct = {setWeightProduct}
                                                            sizeProduct = {sizeProduct}
                                                            setSizeProduct  = {setSizeProduct}
                                                            errors = {errors}
                                                            />
                            
                        }
                        { tabActive === 'TabThree' && 
                        <ProductLinks
                            productCategorySelected = {productCategorySelected}
                            setProductCategorySelected = {setProductCategorySelected}
                            productBrandSelected = {productBrandSelected}
                            setProductBrandSelected = {setProductBrandSelected}
                            productTags = {productTags}
                            setProductTags = {setProductTags}
                        /> }
                        {
                            tabActive === 'TabFour' && 
                            <SEO
                                productMetaKeywords = {productMetaKeywords}
                                setProductMetaKeywords = {setProductMetaKeywords}
                                productMetaDesc = {productMetaDesc}
                                setProductMetaDesc = {setProductMetaDesc}
                            />
                            
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreateProduct
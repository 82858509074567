import React from 'react'

const FormLangBar = ({currentLangList,formLang,setFormLang}) => {

    return (
        <>
            <div className='flex gap-1 justify-end text-xs'>
                { currentLangList.map((lang) => (
                    <span  
                        onClick={()=>setFormLang(lang.langCode)}
                        className={`rounded  px-3 py-1 cursor-default  hover:text-indigo-900 hover:bg-indigo-300 ${formLang === lang.langCode ? 'text-slate-50 bg-indigo-300 font-bold' : ' bg-indigo-100 text-indigo-600'}`} 
                        key={lang.langCode}
                        >
                        {lang.langName}
                    </span>
                )) }
            </div>
        </>
    )
}

export default FormLangBar
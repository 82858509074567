import FormLangBar from '../../FormLangBar';
import { MwInputText,MwTextArea,MwSwitch } from '../../ui';

const TagForm = ({
    currentLangList,
    formLang,
    setFormLang,
    errors,
    tagName,
    setTagName,
    tagDesc,
    setTagDesc,
    isActive,
    setIsActive

}) => {
    return (
        <form >
            <div id='MultiLangBlock' className='flex flex-col'>
            <FormLangBar currentLangList = {currentLangList} formLang = {formLang} setFormLang = {setFormLang} />
                { currentLangList.map((lang) => (
                                <div key={lang.langCode} className=''>
                                    <div className={` ${ formLang === lang?.langCode ? 'block' : 'hidden' } `}>
                                        <div id='tagName' className=' '>
                                        <MwInputText
                                            label={`اسم العلامة (${lang?.langName})`}
                                            id={`brandName${lang?.langCode}`} 
                                            value={tagName[lang?.langCode]}
                                            invalid={!!errors[`tagName.${lang?.langCode}`] }
                                            invalidMsg = {errors[`tagName.${lang?.langCode}`]}
                                            onChange={(e)=>{
                                                setTagName({...tagName, [lang?.langCode]:e.target.value});
                                            }}
                                        />
                                        </div>
                                        <div id='tagDesc' className='my-2'>
                                            <MwTextArea
                                                id={`tagDesc${lang?.langCode}`}
                                                value={tagDesc[lang?.langCode]}
                                                placeholder={`وصف العلامة (${lang.langName}) `}
                                                rows={4}
                                                onChange={(e ) => setTagDesc({...tagDesc,[lang?.langCode]:e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                            )) }
            </div>
            <div id='OptionsBock' className='flex flex-col justify-between pb-2 px-1'>
                <div className='flex justify-between items-center py-4  border-b mb-2'>
                    <label className='text-xs text-slate-400'>مفعل / غير مفعل</label>
                    <MwSwitch custKey='isActive' setIsActive= {setIsActive} isActive={isActive} onChangeSwitch={() => setIsActive(!isActive)} />
                </div>
            </div>
        </form>
    )
}

export default TagForm
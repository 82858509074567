import React from 'react'
import {TbCategory2} from 'react-icons/tb'
import {SiBrandfolder} from 'react-icons/si'
import {BsFillTagsFill} from 'react-icons/bs'
import {LuLayoutDashboard} from 'react-icons/lu'

const ProductsNavbar = ({tabActive,setTabActiive}) => {
    return (
        <>
            <div className='w-full flex mt-3 gap-3  border-b'>
                <div onClick={()=>setTabActiive('Products')} className={`flex-1 flex flex-col gap-1 items-center justify-center rounded-t-lg text-slate-500  p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive === 'Products' ? ' bg-indigo-100 text-indigo-800 font-bold' : 'bg-slate-50'}`}>
                    <LuLayoutDashboard  size={28}/>
                    <hr />
                    <span className='text-sm '>المنتجات والخدمات</span>
                </div>
                <div onClick={()=>setTabActiive('Categories')} className={`flex-1 flex flex-col gap-1 items-center justify-center rounded-t-lg text-slate-500  p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive === 'Categories' ? 'bg-indigo-100 text-indigo-800 font-bold' : 'bg-slate-50'}`}>
                    <TbCategory2  size={28}/>
                    <hr />
                    <span className='text-sm  '>التصنيفات</span>
                </div>
                <div onClick={()=>setTabActiive('Brands')} className={`flex-1 flex flex-col gap-1 items-center justify-center rounded-t-lg text-slate-500  p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive === 'Brands' ? 'bg-indigo-100 text-indigo-800 font-bold' : 'bg-slate-50'}`}>
                    <SiBrandfolder  size={28}/>
                    <hr />
                    <span className='text-sm '>الماركات</span>
                </div>
                <div onClick={()=>setTabActiive('Tags')} className={`flex-1 flex flex-col gap-1 items-center justify-center rounded-t-lg text-slate-500  py-3 p-3 hover:bg-indigo-50 hover:font-bold cursor-default ${tabActive==='Tags' ? 'bg-indigo-100 text-indigo-800 font-bold' :  'bg-slate-50'}`}>
                    <BsFillTagsFill size={28}/>
                    <hr />
                    <span className='text-sm '>العلامات (Tags) </span>
                </div>
            </div>
        </>
    )
}

export default ProductsNavbar
import React from 'react'

const MeunSideSetting = ({activeMenu,setActiveMenu}) => {

    const handleClick = (newValue) => {
        setActiveMenu(newValue)
    }

    return (
        <div className='w-48 rounded text-xs'>
            <ul className=''>
                <li 
                    onClick={() => handleClick('GeneralSettings')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'GeneralSettings' && 'bg-slate-100 font-medium'}`}>
                        الاعدادات العامة
                </li>
                <li 
                    onClick={() => handleClick('MyCompany')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'MyCompany' && 'bg-slate-100 font-medium'}`}>
                        اعدادات الشركة
                </li>
                <li 
                    onClick={() => handleClick('MyBranches')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'MyBranches' && 'bg-slate-100 font-medium'}`}>
                        اعدادات الفروع
                </li>
                <li 
                    onClick={() => handleClick('InvoiceSettings')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'InvoiceSettings' && 'bg-slate-100 font-medium'}`}>
                        اعدادات الفاتوره
                </li>
                <li 
                    onClick={() => {} } 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'َQuoteSettings' && 'bg-slate-100 font-medium'}`}>
                        اعدادات عروض الاسعار
                </li>
                <li 
                    onClick={() => handleClick('PaymentMethod')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'PaymentMethod' && 'bg-slate-100 font-medium'}`}>
                        اعدادات طرق الدفع
                </li>
                <li 
                    onClick={() =>{} } 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'ProductSettings' && 'bg-slate-100 font-medium'}`}>
                        اعدادات المنتجات والخدمات
                </li>
                <li 
                    onClick={() =>{} } 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'CustomerSettings' && 'bg-slate-100 font-medium'}`}>
                        اعدادات العملاء
                </li>
                <li 
                    onClick={() =>{} } 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'SalesmanSettings' && 'bg-slate-100 font-medium'}`}>
                        اعدادات مناديب المبيعات
                </li>
                <li 
                    onClick={() =>{} } 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'SalesmanSettings' && 'bg-slate-100 font-medium'}`}>
                        اعدادات التخفيضات
                </li>
                <li 
                    onClick={() => handleClick('EmailSetting')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'EmailSetting' && 'bg-slate-100 font-medium'}`}>
                        اعدادات البريد الالكتروني
                </li>
                <li 
                    onClick={() => handleClick('SMSSetting')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'SMSSetting' && 'bg-slate-100 font-medium'}`}>
                        اعدادات الرسائل النصية
                </li>
                <li 
                    onClick={() => handleClick('SMSSetting')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'SMSSetting' && 'bg-slate-100 font-medium'}`}>
                        اعدادات التنبيهات
                </li>
                <li 
                    onClick={() => handleClick('ReportSetting')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'ReportSetting' && 'bg-slate-100 font-medium'}`}>
                        اعدادات التقارير
                </li>
                <li 
                    onClick={() => handleClick('ReportSetting')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'ReportSetting' && 'bg-slate-100 font-medium'}`}>
                        اعدادات المستخدمين
                </li>
                <li 
                    onClick={() => handleClick('LanguagesSetting')} 
                    className={`cursor-default p-2 border-b hover:bg-slate-100 hover:font-medium ${activeMenu === 'LanguagesSetting' && 'bg-slate-100 font-medium'}`}>
                        اعدادات اللغة
                </li>
            </ul>
        </div>
    )
}

export default MeunSideSetting
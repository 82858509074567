import { createSlice } from "@reduxjs/toolkit";


const langSlice = createSlice({
    name: 'lang',
    initialState:{
        label:'Arabic',
        value:'ar'
    },
    reducers:{
        setLang: (state,action) => {
            state.label = action.payload.label;
            state.value = action.payload.value;
        }
    }
})

export const {setLang} = langSlice.actions;
export default langSlice.reducer
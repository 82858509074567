import React from 'react'
import FormLangBar from '../../FormLangBar';
import {  MwInputText,MwTextArea,MwSwitch } from '../../ui';

const GroupForm = ({
    currentLangList,
    formLang,
    setFormLang,
    errors,
    groupName,
    setGroupName,
    groupDesc,
    setGroupDesc,
    isActive,
    setIsActive
}) => {
    return (
        <form className='p-3 my-5'>
                <div id='MultiLangBlock' className='flex flex-col'>
                    <FormLangBar currentLangList = {currentLangList} formLang = {formLang} setFormLang = {setFormLang} />
                        { currentLangList.map((lang) => (
                            <div key={lang.langCode} className=''>
                                <div className={` ${ formLang === lang?.langCode ? 'block' : 'hidden' } `}>
                                    <div id='categoryName' className=' '>
                                    <MwInputText
                                        label={`اسم المجموعة (${lang?.langName})`}
                                        id={`groupName${lang?.langCode}`} 
                                        value={groupName[lang?.langCode]}
                                        invalid={!!errors[`groupName.${lang?.langCode}`] }
                                        invalidMsg = {errors[`groupName.${lang?.langCode}`]}
                                        onChange={(e)=>{
                                            setGroupName({...groupName, [lang?.langCode]:e.target.value});
                                        }}
                                    />
                                    </div>
                                    <div id='groupDesc' className='my-2'>
                                        <MwTextArea
                                            id={`groupDesc${lang?.langCode}`}
                                            value={groupDesc[lang?.langCode]}
                                            placeholder={`وصف المجموعة (${lang.langName}) `}
                                            rows={4}
                                            onChange={(e ) => setGroupDesc({...groupDesc,[lang?.langCode]:e.target.value})}
                                        />
                                    </div>
                                    <div className='flex justify-between items-center py-4  border-b mb-2'>
                                        <label className='text-xs text-slate-400'>مفعل / غير مفعل</label>
                                        <MwSwitch custKey='isActive' isActive={isActive} setIsActive={setIsActive} onChangeSwitch={() => setIsActive(!isActive)} />
                                    </div>
                                </div>
                                
                            </div>
                        )) }
                </div>
            </form>
    )
}

export default GroupForm
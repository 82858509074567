import React from 'react'
import {  useTranslation } from 'react-i18next';

const InvoiceBody = ({
    customerName,
    salesmanName,
    invProducts,
    isDelivery,
    SETTING_INV_CURRENCY
}) => {
    const [t] = useTranslation('global')
    const invProductsList = invProducts?.map((prod) => (
        <div key={prod.id} className='flex justify-between border-b p-1 '>
            <div className='flex-1 px-2'>{prod.product_name}</div>
            <div className='w-16 text-center '>{prod.qty}</div>
            {!isDelivery && <div className='w-20 text-center'>{prod.price} {SETTING_INV_CURRENCY}</div>}
            {!isDelivery && <div className='w-24 text-center'>{prod.total_price} {SETTING_INV_CURRENCY}</div>}
        </div>
    ));

    return (
        <>
            <div className='text-xs py-3'>
                <div className='flex justify-between text-xs px-1 pb-3 pt-1'>
                    {customerName && <div>{t('invoice.Customer')}: {customerName}</div>}
                    {salesmanName && <div>{t('invoice.Salesman')}: {salesmanName}</div>}
                </div>
                <div  className='flex justify-between items-center p-3   text-xs border-t border-r border-l rounded-t'>
                    <div className='flex-1'>{t('invoice.ProductName')}</div>
                    <div className='w-16 text-center'>{t('invoice.quantity')}</div>
                    {!isDelivery && <div className='w-20 text-center'>{t('invoice.price')}</div>}
                    {!isDelivery && <div className='w-24 text-center'>{t('invoice.TotalPrice')}</div>}
                </div>
                <div className='border '>{invProductsList}</div>
            </div>
        </>
    )
}

export default InvoiceBody
import {useEffect, useState} from 'react'
import { MwButton } from '../../ui';
import { useCurrent, useFetch, usePut } from '../../../hooks';
import {langObjType,objLangTextHandler,LangArrayToObjKeyValue} from '../../../utils/global'
import CardHeadeWithActions from '../../ui/CardHeadeWithActions';
import GroupForm from './GroupForm';

const EditGroup = ({id,setMode}) => {
    const {data:dataGroup,loading:loadingGroup} = useFetch(`${process.env.REACT_APP_VENDORS_API_BASE_URL}/groups/show/${id}`) 
    const {data:putResult,putData} = usePut();
    const {currentLangList,currentTenantId,currentCompanyId,currentBranchId,currentLangId} = useCurrent();
    const [formLang,setFormLang] = useState(currentLangId);
    const [errors,setErrors] = useState({});

    // Form state
    const [groupName,setGroupName] = useState(langObjType());
    const [groupDesc,setGroupDesc] = useState(langObjType());
    const [isActive,setIsActive] = useState(true);

    const formValidate = () => {
        let errorObj = {};
        setErrors('');
        if(!groupName[currentLangId]){
            errorObj = { ...errorObj, [`groupName.${currentLangId}`] : 'يجب ادخال اسم الجموعة'};
            setFormLang(currentLangId)
        }

        if(Object.keys(errorObj).length !== 0 ){
            setErrors(errorObj)
            return true
        }

        return false;
    }
    const submitHandler = () => {
        if(formValidate()) return ;

        const _data = {
            groupName: objLangTextHandler(groupName),
            groupDesc: objLangTextHandler(groupDesc),
            isActive,
            tenantId:currentTenantId,
            companyId:currentCompanyId,
            branchId:currentBranchId
        }

        putData(`${process.env.REACT_APP_VENDORS_API_BASE_URL}/groups/update/${id}`,_data);
    }

    useEffect(()=>{
        if(putResult){
            setMode('List')
        }
    },[putResult]);

    useEffect(()=>{
        if(dataGroup){
            console.log(dataGroup);
            setGroupName(LangArrayToObjKeyValue(dataGroup.data.groupName));
            setGroupDesc(LangArrayToObjKeyValue(dataGroup.data.groupDesc));
            setIsActive(dataGroup.data.isActive);
        }
    },[dataGroup]);
    
    return (
        <>
            <CardHeadeWithActions title={`تعديل مجموعة `}>
                <MwButton inGroup={true} type='cancelBtn' onClick={() => setMode('List')}  >
                    ادارة مجموعات العملاء
                </MwButton>
                <MwButton type="saveBtn" onClick={submitHandler}  inGroup={true} >حفظ</MwButton>
            </CardHeadeWithActions>
            {
                dataGroup && !loadingGroup && <GroupForm
                                currentLangList = {currentLangList}
                                formLang = {formLang}
                                setFormLang = {setFormLang}
                                errors = {errors}
                                groupName = {groupName}
                                setGroupName =  {setGroupName}
                                groupDesc = {groupDesc}
                                setGroupDesc = {setGroupDesc}
                                isActive = {isActive}
                                setIsActive = {setIsActive}
                />
            }
        </>
    );

}

export default EditGroup
import { createSlice } from "@reduxjs/toolkit";
import { tenant } from "../utils/global";

const initialState = {
    name:tenant()?.companies[0].name[0].text || 'Select a company' ,
    value:tenant()?.companies[0]._id,
}

const currentCompanySlice = createSlice({
    name: 'currentCompany',
    initialState,
    reducers:{
        setCurrentCompany: (state,action) => {
            state.name = action.payload.name;
            state.value = action.payload.value;
        }
    },
});

export const {setCurrentCompany} = currentCompanySlice.actions;
export default currentCompanySlice.reducer

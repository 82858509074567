import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setisLogging } from '../store/authSlice';
import { useEffect, useState } from 'react';
import {useLogs} from '../hooks';

const withGuard = (Component) => {
    
    const Wrapper = (props) =>{
        console.log('withGuard: => Starting');
        const dispatch = useDispatch();
        let token = localStorage.getItem('token') ;
        const user = JSON.parse(localStorage.getItem('user')) ;  
        const[isLogin,setIsLogin] = useState(false);
        const {resultLogsPost,loadingLogsPost,errorLogsPost,postDataHandler} = useLogs()
        const [isTokenExists,setIsTokenExists] = useState(true);

        if(!token){
            setIsTokenExists(false);
            const pathArray = window.location.pathname.split('/');
            // if(pathArray.length > 2 && pathArray[pathArray.length-2] === 'token'){
            if(pathArray.includes('token')){
                token = pathArray[pathArray.length-1]
                localStorage.setItem('token',token) ;
            }
        }

        const setLogsHandler =  () => {
            const _data = {
                moduleName: "SALES",
                resourceName: "SALES",
                eventName:"LOGIN",
                logContentEn: `Login user`,
                logContentAr: `تسجيل دخول`,
            }
            postDataHandler(_data);
        }

        const getUser = async() =>{
            if(user) {
                dispatch(setisLogging({user: user,isLogging: true}));
                setIsLogin(true)
            }
            try{
                const user = await axios.get(`${process.env.REACT_APP_Auth_API_BASE_URL}/users/current`,{
                    headers:{'Authorization': 'Bearer ' + token}
                });
                // console.log('withGuard: => User',user);
                if(user.data){
                    localStorage.setItem('user', JSON.stringify(user.data.data.user));
                    dispatch(setisLogging({user: user.data.data.user,isLogging: true}));
                    setIsLogin(true)
                    
                }else{
                    window.location.replace(`${process.env.REACT_APP_Auth_WEB_BASE_URL}`)
                }
            }catch(error){
                console.log('withGuard: => Catch Error',error);
                window.location.replace(`${process.env.REACT_APP_Auth_WEB_BASE_URL}`)
            }
            
        }
        
        useEffect(()=>{
            getUser();
        },[]);  

        useEffect(()=>{
            if(isLogin && !isTokenExists){
                setLogsHandler();
            }
        },[isLogin]); 

        useEffect(()=>{
            if(resultLogsPost && !isTokenExists){
                setIsTokenExists(true);
            }
        },[resultLogsPost]);

        if(isLogin && isTokenExists){

            return <Component {...props} /> 
        }


    }
    return Wrapper;
}

export default withGuard
import React from 'react'
import PurchasesReports from '../components/reports/PurchasesReports'
import {HiOutlineDocumentReport} from 'react-icons/hi'

const Reports = () => {
    return (
        <div className='flex flex-col  '>
            <div className='flex-1 flex gap-1 items-center  text-slate-500'>
                <HiOutlineDocumentReport size={18}/>
                <span>التقارير</span>
            </div>
            <PurchasesReports/>
        </div>
    )
}

export default Reports
import {useEffect, useState} from 'react'
import {CardAmount, MwInputText,BasicSelector, MwSpinner, MwButton, MwSpinnerButton } from '../ui';
import { useFetch,usePost,useCurrent, useInvoice } from '../../hooks';
import {CgAddR} from 'react-icons/cg'
import {RiDeleteBinLine} from 'react-icons/ri'
import {AiOutlineSave} from 'react-icons/ai'

const PayInvoice = ({netAmount,invId,invNo,onHide,onSubmitPay,type='in'}) => {
    const [openList, setOpenList] = useState(false)
    const {currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId} = useCurrent();
    const {data:dataPaymentMethod,loadingPaymentMethod,error} = useFetch(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/payment-methods/${currentTenantId}/${currentCompanyId}/${currentBranchId}`)
    const {data:resultConfirmPayment,loading:loadingConfirmPayment,postData} = usePost()
    const [paymentMethod,setpaymentMethod] = useState([])
    const [selectedPaymentMethod,setSelectedPaymentMethod] = useState()
    const [payAmount,setPayAmount] = useState(parseFloat(netAmount))
    const [remainingAmount,setRemainingAmount] = useState(parseFloat(netAmount));
    const [listPayAmount,setListPayAmount] = useState([]);
    const [totalPay,setTotalPay] = useState(0);
    const {formatter} = useInvoice();

    const onChangePaymentMethod = (value) => {
        console.log(value);
        setSelectedPaymentMethod(value)
    }

    const listHandler = () => {
        let listPaymentMethods = [];
        dataPaymentMethod.map((paymentMethod) => {
            const value = paymentMethod.id;
            if(paymentMethod.description?.length > 0){
                const label = paymentMethod.description.filter(des => des.lang === currentLangId)[0]?.name || paymentMethod.description[0]?.name
                listPaymentMethods = [...listPaymentMethods,{label:label,value:value}]
            }
        });
        return listPaymentMethods
    }

    const removeHandler = (itemIndex) => {
        const filter = listPayAmount.filter((item,index) => index !== itemIndex)
        setListPayAmount(filter)
    }
    const addHandler = () => {
        console.log(parseFloat(payAmount),parseFloat(remainingAmount));
        if( payAmount > remainingAmount ) return;
        

        const objPayAmounnt = {
            paymentMethodId: selectedPaymentMethod.value ,
            paymentMethodNName: selectedPaymentMethod.label ,
            amount : payAmount
        }
        // if(totalPay === 0){
        //     setTotalPay(netAmount)
        // }
        console.log(totalPay,netAmount);
        if(totalPay <= netAmount && payAmount <= remainingAmount ){
            setListPayAmount([...listPayAmount,objPayAmounnt])
        }
    }

    const confirmPaymentHanndler = () => {
        // console.log('confirmPaymentHanndler');
        let _data = [];
        listPayAmount.map(list => {
            const _obj = {
                "inv_id" : invId,
                "inv_no" : invNo,
                "transaction_no" : `trans-${invId}-${invNo}`,
                "amount" : list.amount,
                "type": type,
                "payment_method_id" : list.paymentMethodId,
                "notes" : "",
                "tenant_id" : currentTenantId,
                "company_id" : currentCompanyId,
                "branch_id" : currentBranchId,
                "created_by" : userId
            }
            _data.push(_obj)
        });
        postData(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/payment-transactions`,_data);
    }

    useEffect(()=>{
            const total = parseFloat(listPayAmount.reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.amount),0));
            const remain = parseFloat(parseFloat(netAmount) - total) ;
                setTotalPay(total)
                setRemainingAmount( remain)
                setPayAmount(remain);
    },[listPayAmount]);

    useEffect(() => {
        if(dataPaymentMethod){
            setpaymentMethod(listHandler())
        }
    },[dataPaymentMethod])

    useEffect(() => {
        if(resultConfirmPayment){
            onSubmitPay();
            onHide();
        }
    },[resultConfirmPayment]);


    const ListPayAmount = listPayAmount.map((payAmount,index) => (
        <div key={index} className='flex justify-between items-center px-4 text-xs border-slate-300 border-b p-2'>
            <div className='w-8 text-center'>{index+1}</div>
            <div className='flex-1 '>{payAmount.paymentMethodNName}</div>
            <div className='font-bold w-24  text-center'>{formatter.format(payAmount.amount)}</div>
            <div className='flex justify-center w-16 '>
                <RiDeleteBinLine 
                    onClick={()=>removeHandler(index)} 
                    title='Delete Payment' 
                    className='text-red-800 hover:text-red-500' 
                    />
            </div>
        </div>
    ));
    const cancelHandle = () => {
        onHide();
    }
    return (
        <>
            { !loadingPaymentMethod && dataPaymentMethod ? 
                <div className="flex flex-col justify-between  ">
                    <div className='flex p-3 justify-end '>
                        { onHide && <MwButton inGroup={true}  type='cancelBtn' onClick={cancelHandle}>الغاء</MwButton> }
                        { 
                            !loadingConfirmPayment ?
                                <MwButton 
                                    inGroup={true}  
                                    type='saveBtn' 
                                    disabled={ (!!totalPay && totalPay > 0 )? false : true} 
                                    onClick={confirmPaymentHanndler}>
                                    {` أعتمد الدفع  ${ totalPay == 0 ? '' : '('+totalPay+')' }`}
                                <AiOutlineSave size={18}/>
                                </MwButton> :
                                <MwSpinnerButton/>
                        }
                    </div>
                    <div className='flex justify-between items-center px-3 mx-3 py-7 my-3  bg-slate-200 rounded-lg '>
                        <CardAmount label={`المبلغ المطلوب`} amountValue={ formatter.format( netAmount?.toFixed(2)) }  />
                        <CardAmount label={`المبلغ المدفوع`} amountValue={formatter.format(totalPay || 0) }  />
                        <CardAmount label={`المبلغ المتبقي`} amountValue={formatter.format(remainingAmount?.toFixed(2) || 0 ) }  />
                    </div>
                    <div className='flex justify-between items-center p-3 bg-slate-200 rounded-lg m-3 ' >
                        <div className=' flex ' >
                            <MwInputText value={ payAmount  } inputType='number' onChange={(e) => setPayAmount(e.target?.value)} placeholder='Enter amount' />
                        </div>
                        <div className='flex-1 '>
                            <BasicSelector 
                                size='lg' 
                                listItem={paymentMethod}
                                onSelected={onChangePaymentMethod}
                                openList={openList}
                                setOpenList={setOpenList}
                                customStyle='bg-white'
                                >
                                {selectedPaymentMethod?.label || 'أختر طريقة الدفع'}
                            </BasicSelector>
                        </div>
                        <MwButton inGroup={true} type='saveBtn' disabled={ (selectedPaymentMethod && !!payAmount && payAmount > 0 )? false : true} size='sm' onClick={addHandler} ><CgAddR size={18}/>أضف</MwButton>
                    </div>
                    {ListPayAmount.length > 0 && <div className='bg-slate-200 rounded-lg m-3 py-3'>{ListPayAmount}</div> }
                </div> : 
            <MwSpinner/> }
        </>
    )
}

export default PayInvoice
import React from 'react'
import { CiBatteryEmpty } from "react-icons/ci";

const NoDataFound = ({children,msg}) => {
    return (
        <div className='h-full w-full flex flex-col items-center justify-center rounded-xl bg-slate-100 text-slate-400 font-bold text-center p-7  '>
            <div className='flex justify-between gap-1 items-center'>
                <CiBatteryEmpty size={36} />
                <div className='pt-1'>{msg}</div>
            </div>
            {
                children && 
                <div className='my-2'>
                    {children}
                </div>
            }
        </div>
    )
}

export default NoDataFound
import { useInvoice,useCurrent } from '../../hooks';
import {LangArrayToObjKeyValue} from '../../utils/global'
import {AiOutlineEdit} from 'react-icons/ai'
import {RiDeleteBin6Line} from 'react-icons/ri'

const ListProductsItems = ({item,editHandeler,deleteHandeler,index}) => {
    const { currentLangId} = useCurrent();
    const {SETTING_INVOICE,formatter} = useInvoice();
    const INV_CURRENCY = SETTING_INVOICE?.INV_CURRENCY
    const SETTING_INV_CURRENCY = INV_CURRENCY  || 'SAR'

    return (
            <div className='flex gap-3 justify-between items-center border-t py-2 px-2 hover:bg-indigo-50 hover:font-bold'>
                <div className='px-2'>{index+1}</div>
                <div className='flex-1'>{LangArrayToObjKeyValue(item.productName)?.[currentLangId] || item.productName[0]?.text}</div>
                <div className='w-24 text-center'>{formatter.format(item.price)} </div>
                <div className={` w-44 text-center ${item.isShowOnWebsite ? ' bg-green-200 ' : 'bg-slate-200' } px-2 pb-1 rounded-lg `}>{item.isShowOnWebsite ? 'يظهر علي الموقع الالكتروني' : 'لا يظهر علي الموقع الالكتروني'}</div>
                <div className={` w-16 text-center px-2 pb-1 rounded-lg   ${item.isActive ? ' bg-green-200 ' : 'bg-slate-200' }`}>{item.isActive ? 'مفعل' : 'غير مفعل'}</div>
                <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>editHandeler(item._id)}><AiOutlineEdit size={18}/></div>
                <div className='w-8 text-center text-indigo-400 cursor-default hover:text-indigo-800  ' onClick={()=>deleteHandeler(item._id)}><RiDeleteBin6Line size={18} /></div>
            </div>
    )
}

export default ListProductsItems